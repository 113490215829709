import * as React from "react";

import { Link, useHistory } from "react-router-dom";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ErrorMessages } from "../../Common/error";
import { LinearProgress } from "@material-ui/core";
import { ManageOcrModelAPI } from "../../Common/api";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import { TextLimits } from "../../Common/limits";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { compareUpdatedTime } from "../../Common/sortUtils";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body_header: {
      flexGrow: 1,
      zIndex: 1,
      backgroundColor: "rgba(0,0,0,0)",
    },
    router_link: {
      margin: "10px",
    },
    toolbar: {
      minHeight: 48,
    },
    title: {
      flexGrow: 1,
    },
    table_head: {
      backgroundColor: "#EEEEEE",
    },
    table_body: {
      fontSize: "0.7em",
    },
    table_container: {
      minWidth: "100%",
      maxHeight: "calc(100vh - 150px)",
    },
    table_paper: {
      width: "100%",
    },
    checkbox: {
      width: 30,
      height: 0,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

export default function ModelList() {
  const style = useStyles();

  const [checkedIDs, setCheckedIDs] = React.useState([]);

  const [tempDict, setTempDict] = React.useState();

  const [backDropping, setBackDropping] = React.useState(false);

  const [reloadListTrigger, reloadList] = React.useState(false);

  const addCheckedID = (id) => {
    const _checkedIDs = checkedIDs.concat([id]);
    setCheckedIDs(_checkedIDs);
  };

  const delCheckedID = (id) => {
    const _checkedIDs = checkedIDs.filter((_id) => _id !== id);
    setCheckedIDs(_checkedIDs);
  };

  const handleCheckboxChange = (event, id) => {
    event.target.checked ? addCheckedID(id) : delCheckedID(id);
  };

  // TODO 未選択とか複数選択チェックとか
  const getFirstCheckedID = () => {
    return checkedIDs[0];
  };

  return (
    <React.Fragment>
      <Backdrop className={style.backdrop} open={backDropping}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <BodyHeader
        getFirstCheckedID={getFirstCheckedID}
        checkedIDs={checkedIDs}
        tempDict={tempDict}
        reloadList={reloadList}
        setBackDropping={setBackDropping}
      />
      <ModelListTable
        handleChange={handleCheckboxChange}
        setTempDict={setTempDict}
        reloadListTrigger={reloadListTrigger}
        setBackDropping={setBackDropping}
      />
    </React.Fragment>
  );
}

function BodyHeader(props) {
  const style = useStyles();
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const history = useHistory();
  console.log(props);
  return (
    <AppBar
      className={style.body_header}
      position="static"
      color="default"
      elevation={0}
    >
      <Toolbar className={style.toolbar}>
        <Typography variant="subtitle1" className={style.title}>
          帳票定義 - モデル一覧
        </Typography>
        <Link className={style.router_link} to="/model/upload">
          新規登録
        </Link>
        <Button
          onClick={() => {
            if (props.getFirstCheckedID() === undefined) {
              alert("選択されていません");
              return;
            }
            history.push("/model/edit?model_id=" + props.getFirstCheckedID());
          }}
        >
          編集
        </Button>
        {/* <Link className={style.router_link} to="/model/delete"> */}
        <Button
          onClick={() => {
            props.setBackDropping(true);
            props.reloadList((current_value: boolean) => !current_value);
          }}
          style={{ backgroundColor: "transparent" }}
        >
          再読込
        </Button>
        {/* </Link> */}
        {/* <Link className={style.router_link} to="/model/delete"> */}
        <Button
          onClick={() => {
            if (props.checkedIDs.length === 0) {
            } else if (window.confirm("選択したモデルを削除しますか？")) {
              props.setBackDropping(true);
              deleteModels(
                props.checkedIDs,
                props.tempDict,
                props.reloadList,
                getAccessTokenSilently,
                getIdTokenClaims
              );
            }
          }}
          style={{ backgroundColor: "transparent" }}
        >
          削除
        </Button>
        {/* </Link> */}
      </Toolbar>
    </AppBar>
  );
}

function SearchBox() {
  return (
    <TextField
      id="outlined-basic"
      label="Search"
      variant="outlined"
      size="small"
      fullWidth
      inputProps={{
        maxLength: TextLimits.MAX_TEXT_LENGTH,
      }}
    />
  );
}

function ModelListTable(props) {
  const style = useStyles();
  const [rows, setRows] = React.useState(null);
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  React.useEffect(() => {
    const getListOfOcrModels = new ManageOcrModelAPI("get_list_of_models");
    const getListOfOcrModelsParam = { param: {} };
    getListOfOcrModels.setParam(getListOfOcrModelsParam);

    getAccessTokenSilently()
      .then(() => getIdTokenClaims())
      .then((idToken) => {
        getListOfOcrModels
          .execPostRequest(idToken.__raw)
          .then((response) => response.json())
          .then(
            (res) => {
              console.log("get_list_of_modelsRes", res);
              const sortedRows = res.result.sort(compareUpdatedTime);
              setRows(sortedRows);
              let tempDict = {};
              for (const row of res.result) {
                tempDict[row.model_id] = row;
              }
              props.setTempDict(tempDict);
              console.log("then");
              console.log(res.result);
              props.setBackDropping(false);
            },
            (error) => {
              console.log(error);
            }
          );
      });
    // eslint-disable-next-line
  }, [props.reloadListTrigger]);

  return rows === null ? (
    <React.Fragment>
      <LinearProgress />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <SearchBox />
      <Paper className={style.table_paper}>
        <TableContainer className={style.table_container}>
          <Table size="small" aria-label="a dense table" stickyHeader>
            <TableHead className={style.table_head}>
              <TableRow>
                <TableCell>
                  <Checkbox
                    className={style.checkbox}
                    size="small"
                    disableRipple
                  />
                  NAME
                </TableCell>
                <TableCell>DESCRIPTION</TableCell>
                <TableCell>UPDATE</TableCell>
                <TableCell>CREATE</TableCell>
                <TableCell>ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.model_id} id={row.model_id}>
                  <TableCell
                    className={style.table_body}
                    component="th"
                    scope="row"
                  >
                    <Checkbox
                      className={style.checkbox}
                      size="small"
                      disableRipple
                      onChange={(event) => {
                        props.handleChange(event, row.model_id);
                      }}
                    />
                    <Link to={"/model/edit?model_id=" + row.model_id}>
                      {row.name}
                    </Link>
                  </TableCell>
                  <TableCell className={style.table_body}>
                    {row.description}
                  </TableCell>
                  <TableCell className={style.table_body}>
                    {row.updated_at}
                  </TableCell>
                  <TableCell className={style.table_body}>
                    {row.created_at}
                  </TableCell>
                  <TableCell className={style.table_body}>
                    {row.model_id}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
}

const deleteModels = (
  model_IDs,
  tempDict,
  reloadList,
  getAccessTokenSilently,
  getIdTokenClaims
) => {
  getAccessTokenSilently()
    .then(() => getIdTokenClaims())
    .then((idToken) => {
      let promises = [];
      model_IDs.map((model_id) => {
        const modelDeleter = new ManageOcrModelAPI("delete_model");

        const modelDeleteParam = {
          param: {
            model_id: model_id,
          },
          binary_data: {},
        };

        modelDeleter.setParam(modelDeleteParam);

        promises.push(
          modelDeleter
            .execPostRequest(idToken.__raw)
            .then((response) => response.json())
        );
        return [];
      });

      Promise.all(promises).then(
        (results) => {
          console.log("delete");
          console.log(results);
          reloadList((current_value: boolean) => !current_value);
        },
        (error) => {
          console.log("ERROR", error);
          alert(ErrorMessages.pleaseTryAgain);
          reloadList((current_value: boolean) => !current_value);
        }
      );
    });
};

import * as React from "react";

import { Breadcrumbs, Container } from "@material-ui/core";
import {
  BrowserRouter,
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
//import firebaseClient from "./Common/firebase";
//import { FirebaseCustomTokenAPI } from "./Common/api";
//import auth0Client from "./Common/auth0";
import { getPermissions, setPermissions } from "./Common/permissions";

import AppBar from "@material-ui/core/AppBar";
import Backdrop from "@material-ui/core/Backdrop";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
//import * as firebase from "firebase-admin";
import CssBaseline from "@material-ui/core/CssBaseline";
import DictionaryEdit from "./Pages/Dictionary/dictionary-edit";
import DictionaryList from "./Pages/Dictionary/dictionary-list";
import DictionaryUpload from "./Pages/Dictionary/dictionary-upload";
import ModelList from "./Pages/ModelManager/model-list";
import ModelUpload from "./Pages/ModelManager/model-upload";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import OcrEdit from "./Pages/OCR/ocr-edit";
import OcrList from "./Pages/OCR/ocr-list";
import OcrUpload from "./Pages/OCR/ocr-upload";
import OcrUploadFromTemplate from "./Pages/OCR/ocr-upload-from-template";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TemplateEdit from "./Pages/Template/template-edit";
import TemplateList from "./Pages/Template/template-list";
import TemplateUpload from "./Pages/Template/template-upload";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import arithIcon from "./Images/arith.png";
import arrowShort from "./Images/arrow-short.png";
import compLogoImage from "./Images/logo.png";
import dicMenuIcon from "./Images/dic.png";
// import infoMenuIcon from "./Images/info.png";
import logoutIcon from "./Images/out.png";
import ocrMenuIcon from "./Images/header_ocr.png";
import pastResultsIcon from "./Images/header_past_results.png";
import step1off from "./Images/top1_off.png";
import step1on from "./Images/top1_on.png";
import step2off from "./Images/top2_off.png";
import step2on from "./Images/top2_on.png";
import { useAuth0 } from "@auth0/auth0-react";
import yomitoriMenuIcon from "./Images/header_yomitori.png";

//import TemplateMain from "./Pages/Template/template-main";
//import OcrMain from "./Pages/OCR/ocr-main";
//import DictionaryMain from "./Pages/Dictionary/dictionary-main";
//import ModelMain from "./Pages/ModelManager/model-main";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primaryHeader: {
      backgroundColor: "white",
      height: 77,
      minWidth: 1200,
    },
    menuTabHeader: {
      backgroundColor: "#07247f",
      transition: "0.5s",
      height: 62,
      minWidth: 1200,
    },
    menuTabHeaderInOcr: {
      backgroundColor: "#663399",
      transition: "0.5s",
      height: 62,
      minWidth: 1200,
    },
    locationIndicateHeader: {
      backgroundColor: "#EFEFEF",
      color: "black",
      height: 50,
      minWidth: 1200,
    },
    toolbar: {
      width: "100%",
      padding: 0,
      paddingLeft: 50,
      minHeight: 50,
      minWidth: 1200,
      margin: "auto",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    footer: {
      height: 50,
      position: "relative",
      backgroundColor: "lightgray",
      minWidth: 1200,
    },
    primaryHeaderMenuImg: {
      width: "auto",
      height: 22,
      marginRight: 10,
    },
    primaryHeaderMenuImgNoDesc: {
      width: 22,
      height: 22,
    },
    compLogoImg: {
      height: 30,
      width: "auto",
    },
    compLogoLink: {
      marginRight: "auto",
    },
    primaryHeaderMenuButton: {
      borderRadius: 0,
      height: 77,
      marginLeft: 1.5,
      marginRight: 1.5,
      color: "#07247f",
      backgroundColor: "#EFEFEF",
      "&:hover": {
        backgroundColor: "#lightgray",
      },
    },
    footerCopyRight: {
      marginLeft: "auto",
      color: "black",
    },
    menuTabHeaderMenuContainer: {
      height: 62,
      "& div": {
        height: 62,
      },
    },
    menuTabHeaderMenu: {
      minWidth: 121,
      marginRight: 30,
      padding: "6px 0px",
      "& span": {
        display: "contents",
      },
    },
    menuTabHeaderMenuImg: {
      height: 18,
      width: "auto",
      marginRight: 5,
    },
    mainBody: {
      minHeight: "calc(100vh - 240px)",
      width: 1200,
      margin: "auto",
    },
    breadCrumb: {
      fontSize: 14,
    },
    arith_comment_container: {
      display: "flex",
      width: 450,
      padding: 0,
    },
    arith_icon: {
      width: 45.75,
      height: 54.75,
    },
    arith_comment: {
      height: 40,
      margin: "auto",
      marginLeft: 10,
      //lineHeight: "40px",
      padding: "0 24px",
      backgroundColor: "#EFEFEF",
      borderRadius: "0px 25px 25px 25px",
      boxShadow: "3px 3px lightgrey",
      fontSize: "smaller",
      paddingTop: "5px",
    },
    arith_comment_step: {
      color: "blue",
      fontWeight: "bold",
    },
    PanelContainer: {
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
    },
    boxTemplate: {
      marginBottom: 50,
      //backgroundColor: "#EFEFEF",
      borderColor: "rgba(102,51,153,0)",
    },
    panel: {
      margin: 0,
      padding: 20,
      paddingBottom: 30,
      width: 500,
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      margin: "auto",
    },
    arrowShort: {
      width: 97,
      height: 97.5,
      margin: "auto",
    },
    stepImg: {
      width: 450,
      height: "auto",
      marginRight: 20,
    },
  })
);

export default function App() {
  //const [currentUser, setCurrentUser] = React.useState(null);
  //firebaseClient.setAuthStateListener((user) => {
  //  setCurrentUser(user);
  //  if (user) firebaseClient.getIdToken();
  //});

  const style = useStyles({});
  const {
    //user,
    isLoading,
    error,
    isAuthenticated,
    //getAccessTokenSilently,
    //getIdTokenClaims,
    loginWithRedirect,
    logout,
  } = useAuth0();

  //React.useEffect(() => {
  //  const auth = async () => {
  //    try {
  //      await getAccessTokenSilently();
  //      const claims = await getIdTokenClaims();
  //      const id_token = claims.__raw;
  //      auth0Client.idToken = id_token;
  //      //const firebaseCustomTokenAPI = new FirebaseCustomTokenAPI(id_token);
  //      //firebaseCustomTokenAPI
  //      //  .execGetRequest()
  //      //  .then((response) => response.json())
  //      //  .then(
  //      //    (res) => {
  //      //      firebaseClient.setToken(res);
  //      //      firebaseClient.updateProfile(claims);
  //      //    },
  //      //    (error) => {
  //      //      console.log("firebase authentication failed");
  //      //      console.log(error);
  //      //    }
  //      //  );
  //    } catch (e) {
  //      console.log(e.message);
  //    }
  //  };
  //  auth();
  //  // eslint-disable-next-line
  //}, []);

  if (error) {
    console.log("Auth0 authentication failed");
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return (
      <Backdrop className={style.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  return isAuthenticated ? (
    <React.Fragment>
      <CssBaseline />
      <MainRouter logout={logout} />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Backdrop className={style.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}

const doneSetPermissions = React.createContext(false);

function MainRouter(props) {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const style = useStyles();
  const [_doneSetPermissions, setDonePermissions] = React.useState(false);

  React.useEffect(() => {
    getAccessTokenSilently()
      .then(() => getIdTokenClaims())
      .then((idToken) => {
        setPermissions(idToken.__raw);
        setDonePermissions(true);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <doneSetPermissions.Provider value={_doneSetPermissions}>
      <BrowserRouter>
        <MainHeader logout={props.logout} />
        <div className={style.mainBody}>
          <Switch>
            <Route exact path="/" component={Steps}></Route>
            <Route path="/ocr/list" component={OcrList}></Route>
            <Route path="/ocr/upload" component={OcrUpload}></Route>
            <Route
              path="/ocr/uploadfromtemplate"
              component={OcrUploadFromTemplate}
            ></Route>
            <Route path="/ocr/edit" component={OcrEdit}></Route>

            <Route path="/template/list" component={TemplateList}></Route>
            <Route path="/template/upload" component={TemplateUpload}></Route>
            <Route path="/template/edit" component={TemplateEdit}></Route>

            <Route path="/dictionary/list" component={DictionaryList}></Route>
            <Route
              path="/dictionary/upload"
              component={DictionaryUpload}
            ></Route>
            <Route path="/dictionary/edit" component={DictionaryEdit}></Route>

            <Route path="/model/list" component={ModelList}></Route>
            <Route path="/model/upload" component={ModelUpload}></Route>

            <Route path="/logout" component={Logout}></Route>

            <Route component={() => <Redirect to="/" />} />
          </Switch>
        </div>
        <MainFooter />
      </BrowserRouter>
    </doneSetPermissions.Provider>
  );
}

function Logout() {
  const { logout } = useAuth0();
  logout({ returnTo: window.location.origin });
  return <></>;
}

function MainHeader(props) {
  const history = useHistory();
  React.useContext(doneSetPermissions);
  const style = useStyles();
  const [tabValue, setTabValue] = React.useState(-1);
  const tabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
  const location = useLocation();
  const [pathname, setPathname] = React.useState("");
  const routingMapping = {
    "/": { breadcrumb: "トップページ" },
    "/ocr/list": {
      breadcrumb: (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {getPermissions().execute.ocr ? (
            <Link color="inherit" className={style.breadCrumb} to="/ocr/upload">
              OCRをする
            </Link>
          ) : (
            <Typography className={style.breadCrumb} color="textPrimary">
              OCRをする
            </Typography>
          )}
          <Typography className={style.breadCrumb} color="textPrimary">
            一覧
          </Typography>
        </Breadcrumbs>
      ),
    },
    "/ocr/upload": {
      breadcrumb: (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {getPermissions().execute.ocr ? (
            <Link color="inherit" className={style.breadCrumb} to="/ocr/upload">
              OCRをする
            </Link>
          ) : (
            <Typography className={style.breadCrumb} color="textPrimary">
              OCRをする
            </Typography>
          )}
          <Typography className={style.breadCrumb} color="textPrimary">
            アップロード
          </Typography>
        </Breadcrumbs>
      ),
    },
    "/ocr/uploadfromtemplate": {
      breadcrumb: (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {getPermissions().execute.ocr ? (
            <Link color="inherit" className={style.breadCrumb} to="/ocr/upload">
              OCRをする
            </Link>
          ) : (
            <Typography className={style.breadCrumb} color="textPrimary">
              OCRをする
            </Typography>
          )}
          <Typography className={style.breadCrumb} color="textPrimary">
            アップロード
          </Typography>
        </Breadcrumbs>
      ),
    },
    "/ocr/edit": {
      breadcrumb: (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {getPermissions().execute.ocr ? (
            <Link color="inherit" className={style.breadCrumb} to="/ocr/upload">
              OCRをする
            </Link>
          ) : (
            <Typography className={style.breadCrumb} color="textPrimary">
              OCRをする
            </Typography>
          )}
          <Typography className={style.breadCrumb} color="textPrimary">
            確認・編集
          </Typography>
        </Breadcrumbs>
      ),
    },
    "/template/list": {
      breadcrumb: (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {getPermissions().list.template ? (
            <Link
              color="inherit"
              className={style.breadCrumb}
              to="/template/list"
            >
              読取箇所の設定
            </Link>
          ) : (
            <Typography className={style.breadCrumb} color="textPrimary">
              読取箇所の設定
            </Typography>
          )}
          <Typography className={style.breadCrumb} color="textPrimary">
            一覧
          </Typography>
        </Breadcrumbs>
      ),
    },
    "/template/upload": {
      breadcrumb: (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {getPermissions().list.template ? (
            <Link
              color="inherit"
              className={style.breadCrumb}
              to="/template/list"
            >
              読取箇所の設定
            </Link>
          ) : (
            <Typography className={style.breadCrumb} color="textPrimary">
              読取箇所の設定
            </Typography>
          )}
          <Typography className={style.breadCrumb} color="textPrimary">
            読取箇所：テンプレート新規登録
          </Typography>
        </Breadcrumbs>
      ),
    },
    "/template/edit": {
      breadcrumb: (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {getPermissions().list.template ? (
            <Link
              color="inherit"
              className={style.breadCrumb}
              to="/template/list"
            >
              読取箇所の設定
            </Link>
          ) : (
            <Typography className={style.breadCrumb} color="textPrimary">
              読取箇所の設定
            </Typography>
          )}
          <Typography className={style.breadCrumb} color="textPrimary">
            確認・編集
          </Typography>
        </Breadcrumbs>
      ),
    },
    "/dictionary/list": {
      breadcrumb: (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {getPermissions().list.dictionary ? (
            <Link
              color="inherit"
              className={style.breadCrumb}
              to="/dictionary/list"
            >
              辞書を作成
            </Link>
          ) : (
            <Typography className={style.breadCrumb} color="textPrimary">
              辞書を作成
            </Typography>
          )}
          <Typography className={style.breadCrumb} color="textPrimary">
            一覧
          </Typography>
        </Breadcrumbs>
      ),
    },
    "/dictionary/upload": {
      breadcrumb: (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {getPermissions().list.dictionary ? (
            <Link
              color="inherit"
              className={style.breadCrumb}
              to="/dictionary/list"
            >
              辞書を作成
            </Link>
          ) : (
            <Typography className={style.breadCrumb} color="textPrimary">
              辞書を作成
            </Typography>
          )}
          <Typography className={style.breadCrumb} color="textPrimary">
            アップロード
          </Typography>
        </Breadcrumbs>
      ),
    },
    "/dictionary/edit": {
      breadcrumb: (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {getPermissions().list.dictionary ? (
            <Link
              color="inherit"
              className={style.breadCrumb}
              to="/dictionary/list"
            >
              辞書を作成
            </Link>
          ) : (
            <Typography className={style.breadCrumb} color="textPrimary">
              辞書を作成
            </Typography>
          )}
          <Typography className={style.breadCrumb} color="textPrimary">
            確認・編集
          </Typography>
        </Breadcrumbs>
      ),
    },
    "/model/list": {
      breadcrumb: (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link color="inherit" className={style.breadCrumb} to="/model/list">
            モデル
          </Link>
          <Typography className={style.breadCrumb} color="textPrimary">
            一覧
          </Typography>
        </Breadcrumbs>
      ),
    },
    "/model/upload": {
      breadcrumb: (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link color="inherit" className={style.breadCrumb} to="/model/list">
            モデル
          </Link>
          <Typography className={style.breadCrumb} color="textPrimary">
            アップロード
          </Typography>
        </Breadcrumbs>
      ),
    },
  };

  React.useEffect(() => {
    setPathname(location.pathname);

    if (location.pathname.startsWith("/template")) {
      setTabValue(0);
    } else if (location.pathname.startsWith("/ocr/upload")) {
      setTabValue(1);
    } else if (location.pathname.startsWith("/ocr")) {
      setTabValue(2);
    } else {
      setTabValue(-1);
    }
  }, [location]);

  return !doneSetPermissions ? (
    <React.Fragment>
      <Backdrop className={style.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <AppBar className={style.primaryHeader} position="static">
        <Toolbar className={style.toolbar}>
          <Link color={"white"} to="/" className={style.compLogoLink}>
            <img
              alt="comp-logo"
              src={compLogoImage}
              className={style.compLogoImg}
            />
          </Link>
          {getPermissions().list.dictionary ? (
            <Link to="/dictionary/list" style={{ textDecoration: "none" }}>
              <Button
                style={{ boxShadow: "none" }}
                variant="contained"
                className={style.primaryHeaderMenuButton}
              >
                <img
                  alt="dic-menu"
                  src={dicMenuIcon}
                  className={style.primaryHeaderMenuImg}
                />
                辞書を作成
              </Button>
            </Link>
          ) : (
            <p></p>
          )}
          {/* <Button
            style={{ boxShadow: "none" }}
            variant="contained"
            className={style.primaryHeaderMenuButton}
          >
            <img
              alt="info"
              src={infoMenuIcon}
              className={style.primaryHeaderMenuImgNoDesc}
            />
          </Button> */}
          <Button
            style={{ boxShadow: "none" }}
            variant="contained"
            className={style.primaryHeaderMenuButton}
            onClick={() => {
              history.push("/logout");
            }}
          >
            <img
              alt="logout"
              src={logoutIcon}
              className={style.primaryHeaderMenuImg}
            />
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <AppBar
        className={
          pathname.startsWith("/ocr")
            ? style.menuTabHeaderInOcr
            : style.menuTabHeader
        }
        position="static"
      >
        <Toolbar className={style.toolbar}>
          <Tabs
            className={style.menuTabHeaderMenuContainer}
            value={tabValue === -1 ? false : tabValue}
            onChange={tabChange}
            TabIndicatorProps={{
              style: {
                background: "linear-gradient(to right, #FFA500, #FFFF00)",
              },
            }}
          >
            <Tab
              className={style.menuTabHeaderMenu}
              disabled={!getPermissions().list.template}
              label={
                <React.Fragment>
                  <img
                    alt="yomitori-menu-icon"
                    src={yomitoriMenuIcon}
                    className={style.menuTabHeaderMenuImg}
                  />
                  <span>読取箇所の設定</span>
                </React.Fragment>
              }
              component={Link}
              to="/template/list"
            />
            <Tab
              className={style.menuTabHeaderMenu}
              disabled={!getPermissions().execute.ocr}
              label={
                <React.Fragment>
                  <img
                    alt="ocr-menu-icon"
                    src={ocrMenuIcon}
                    className={style.menuTabHeaderMenuImg}
                  />
                  <span>OCRをする</span>
                </React.Fragment>
              }
              component={Link}
              to="/ocr/upload"
            />
            <Tab
              className={style.menuTabHeaderMenu}
              disabled={!getPermissions().list.ocr_result}
              label={
                <React.Fragment>
                  <img
                    alt="past-results-icon"
                    src={pastResultsIcon}
                    className={style.menuTabHeaderMenuImg}
                  />
                  <span>過去の結果を見る</span>
                </React.Fragment>
              }
              component={Link}
              to="/ocr/list"
            />
          </Tabs>
        </Toolbar>
      </AppBar>
      <AppBar
        className={style.locationIndicateHeader}
        style={{ boxShadow: "none" }}
        position="static"
      >
        <Toolbar className={style.toolbar}>
          {routingMapping[pathname] ? routingMapping[pathname].breadcrumb : ""}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

//function MainMenu() {
//  return (
//    <div>
//      <Steps />
//      <Link to="/template/list">帳票定義</Link>
//      <p></p>
//      <Link to="/ocr/list">OCR</Link>
//      <p></p>
//      <Link to="/dictionary/list">辞書</Link>
//      <p></p>
//      <Link to="/model/list">モデル管理</Link>
//    </div>
//  );
//}

function MainFooter(props) {
  const style = useStyles();

  return (
    <AppBar className={style.footer}>
      <Toolbar className={style.toolbar}>
        {/* <Button>利用規約</Button>
        <Button>プライバシーポリシー</Button>
        <Button>会社情報</Button>
        <Button>お問い合わせ</Button> */}
        <Typography className={style.footerCopyRight}>
          Copyright ©{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://arithmer.co.jp"
          >
            Arithmer
          </a>{" "}
          Inc. All Rights Reserved.
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

function ArithComment(props) {
  const style = useStyles();
  return (
    <Container className={style.arith_comment_container}>
      <img alt={"ai-arith"} src={arithIcon} className={style.arith_icon} />
      <span className={style.arith_comment}>
        <span className={style.arith_comment_step}>
          {props.step === 1 ? "STEP1" : "STEP2"}
        </span>
        {props.step === 1
          ? " まずはOCRで読み取るための「読取箇所の設定」を設定します"
          : " 読取箇所の設定が完了したら、次にOCRをおこなってください。"}
      </span>
    </Container>
  );
}

function Steps(props) {
  const style = useStyles();
  return (
    <>
      <Container className={style.PanelContainer}>
        <Box className={style.boxTemplate} border={5}>
          <Container className={style.panel}>
            <ArithComment step={1} />
            {getPermissions().list.template ? (
              <Link to="/template/list">
                <img
                  className={style.stepImg}
                  alt={"step1"}
                  src={step1off}
                  onMouseOver={(e) => (e.currentTarget.src = step1on)}
                  onMouseOut={(e) => (e.currentTarget.src = step1off)}
                />
              </Link>
            ) : (
              <img
                className={style.stepImg}
                alt={"step1"}
                src={step1off}
                style={{ filter: "grayscale(100%)" }}
              />
            )}
          </Container>
        </Box>
        <img className={style.arrowShort} alt={"arrow"} src={arrowShort} />
        <Box className={style.boxTemplate} border={5}>
          <Container className={style.panel}>
            <ArithComment step={2} />
            {getPermissions().execute.ocr ? (
              <Link to="/ocr/upload">
                <img
                  className={style.stepImg}
                  alt={"step2"}
                  src={step2off}
                  onMouseOver={(e) => (e.currentTarget.src = step2on)}
                  onMouseOut={(e) => (e.currentTarget.src = step2off)}
                />
              </Link>
            ) : (
              <img
                className={style.stepImg}
                alt={"step2"}
                src={step2off}
                style={{ filter: "grayscale(100%)" }}
              />
            )}
          </Container>
        </Box>
      </Container>
    </>
  );
}

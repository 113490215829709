import * as React from "react";

import { InputAdornment, LinearProgress, Tooltip } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Error from "@material-ui/icons/Error";
import { ErrorMessages } from "../../Common/error";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { OcrResultAPI } from "../../Common/api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import { TextLimits } from "../../Common/limits";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { compareUpdatedTime } from "../../Common/sortUtils";
import garbage from "../../Images/garbage.png";
import { getPermissions } from "../../Common/permissions";
// icons
import glass from "../../Images/glass.png";
import json from "../../Images/json.png";
import ocrIcon from "../../Images/header_ocr.png";
import reload from "../../Images/reload.png";
import tsv from "../../Images/tsv.png";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body_header: {
      flexGrow: 1,
      zIndex: 1,
      backgroundColor: "rgba(0,0,0,0)",
    },
    router_link: {
      margin: "10px",
    },
    toolbar: {
      padding: 0,
      minHeight: 48,
      marginTop: 10,
      marginBottom: 10,
    },
    title: {
      flexGrow: 1,
      color: "#663399",
    },
    searchBoxBar: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "40px",
    },
    searchBox: {
      width: "478px",
      marginRight: "150px",
    },
    glassImage: {
      width: "24px",
      height: "23px",
    },
    reloadDeleteButton: {
      paddingLeft: "10px",
      paddingRight: "10px",
      minWidth: 0,
    },
    downloadImage: {
      width: "27px",
      height: "23px",
    },
    reloadImage: {
      width: "23px",
      height: "23px",
    },
    garbageImage: {
      width: "20",
      height: "23px",
    },
    ocrImage: {
      width: "26px",
      height: "20px",
      marginRight: "15px",
    },
    table_body: {
      textAlign: "center",
      borderWidth: 0,
      borderRightWidth: "1px",
      borderBottomWidth: "1px",
      borderColor: "#D0D0D0",
      borderStyle: "solid",
      paddingTop: "20px",
      paddingBottom: "20px",
    },
    table: {
      borderWidth: 0,
      borderLeftWidth: "1px",
      borderTopWidth: "1px",
      borderColor: "#D0D0D0",
      borderStyle: "solid",
      marginBottom: "30px",
    },
    tableHeader: {
      backgroundColor: "#C4EDFF",
      paddingTop: "20px",
      paddingBottom: "20px",
      borderWidth: 0,
      borderRightWidth: "1px",
      borderBottomWidth: "1px",
      borderColor: "#D0D0D0",
      borderStyle: "solid",
      textAlign: "center",
    },
    textContainer: {
      display: "-webkit-box",
      whiteSpace: "normal",
      wordWrap: "break-word",
      WebkitLineClamp: 5,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    },
    checkbox: {
      padding: 0,
      borderRadius: 0,
      backgroundColor: "white",
    },
    ocrButton: {
      backgroundColor: "#43B9F7",
      "&:hover": {
        backgroundColor: "#0093E3",
      },
    },
    thumbNail: {
      borderWidth: 1,
      borderColor: "#d0d0d0",
      borderStyle: "solid",
      position: "relative",
      zIndex: 0,
      "&:hover": {
        transform: "scale(3)",
        transition: "0.2s",
        position: "relative",
        zIndex: 100,
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

enum FileTypes {
  TSV,
  JSON,
}

export default function OcrList() {
  const style = useStyles();

  const [checkedIDs, setCheckedIDs] = React.useState([]);
  const [checkBoxStatus, setCheckBoxStatus] = React.useState({});
  const [binderStatus, setBinderStatus] = React.useState({});
  const [allChecked, setAllChecked] = React.useState(false);

  const [backDropping, setBackDropping] = React.useState(false);

  const [reloadListTrigger, reloadList] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const handleSearchBoxChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleCheckboxChange = (event, id) => {
    if (id === "checkAll") {
      setAllChecked(event.target.checked);
      for (const key of Object.keys(checkBoxStatus)) {
        checkBoxStatus[key] = event.target.checked;
        setCheckBoxStatus(checkBoxStatus);
      }
    } else {
      checkBoxStatus[id] = event.target.checked;
      setCheckBoxStatus(checkBoxStatus);
      const allChecked = Object.values(checkBoxStatus).every((elm) => elm);
      setAllChecked(allChecked);
    }
    const _checkedIDs = [];
    for (const key of Object.keys(checkBoxStatus)) {
      if (checkBoxStatus[key]) _checkedIDs.push(key);
    }
    setCheckedIDs(_checkedIDs);
  };

  return (
    <React.Fragment>
      <Backdrop className={style.backdrop} open={backDropping}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <BodyHeader />
      <SearchBox
        binderStatus={binderStatus}
        handleSearchBoxChange={handleSearchBoxChange}
        checkedIDs={checkedIDs}
        reloadList={reloadList}
        setBackDropping={setBackDropping}
      />
      <OcrListTable
        searchText={searchText}
        allChecked={allChecked}
        setCheckedIDs={setCheckedIDs}
        setAllChecked={setAllChecked}
        checkBoxStatus={checkBoxStatus}
        setCheckBoxStatus={setCheckBoxStatus}
        setBinderStatus={setBinderStatus}
        handleChange={handleCheckboxChange}
        reloadListTrigger={reloadListTrigger}
        setBackDropping={setBackDropping}
      />
    </React.Fragment>
  );
}

function BodyHeader() {
  const style = useStyles();
  return (
    <AppBar
      className={style.body_header}
      position="static"
      color="default"
      elevation={0}
    >
      <Toolbar className={style.toolbar}>
        <Typography variant="h6" className={style.title}>
          OCR - OCR結果一覧
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

function SearchBox(props) {
  const style = useStyles();
  const history = useHistory();
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  return (
    <div className={style.searchBoxBar}>
      <TextField
        id="outlined-basic"
        label="検索する"
        size="small"
        fullWidth
        autoComplete="off"
        onChange={props.handleSearchBoxChange}
        className={style.searchBox}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <img src={glass} className={style.glassImage} alt="search" />
            </InputAdornment>
          ),
        }}
        inputProps={{
          maxLength: TextLimits.MAX_TEXT_LENGTH,
        }}
      />
      <Button
        className={style.ocrButton}
        variant="contained"
        color="secondary"
        onClick={() => history.push("/ocr/upload")}
        disabled={!getPermissions().execute.ocr}
      >
        <img
          alt="ocr"
          src={ocrIcon}
          className={style.ocrImage}
          style={!getPermissions().execute.ocr ? { opacity: 0.5 } : {}}
        />
        OCRをする
      </Button>
      <Button
        className={style.reloadDeleteButton}
        title="TSV形式でダウンロード"
        variant="contained"
        disabled={!getPermissions().read.ocr_result}
        onClick={() => {
          if (props.checkedIDs.length > 0) {
            props.setBackDropping(true);
            downloadResult(
              props.binderStatus,
              props.checkedIDs,
              FileTypes.TSV,
              getAccessTokenSilently,
              getIdTokenClaims,
              props.setBackDropping
            );
          }
        }}
      >
        <img
          src={tsv}
          className={style.downloadImage}
          alt="reload"
          style={!getPermissions().read.ocr_result ? { opacity: 0.2 } : {}}
        />
      </Button>
      <Button
        className={style.reloadDeleteButton}
        title="JSON形式でダウンロード"
        variant="contained"
        disabled={!getPermissions().read.ocr_result}
        onClick={() => {
          if (props.checkedIDs.length > 0) {
            props.setBackDropping(true);
            downloadResult(
              props.binderStatus,
              props.checkedIDs,
              FileTypes.JSON,
              getAccessTokenSilently,
              getIdTokenClaims,
              props.setBackDropping
            );
          }
        }}
      >
        <img
          src={json}
          className={style.downloadImage}
          alt="reload"
          style={!getPermissions().read.ocr_result ? { opacity: 0.2 } : {}}
        />
      </Button>
      <Button
        className={style.reloadDeleteButton}
        title="再読込"
        variant="contained"
        disabled={!getPermissions().list.ocr_result}
        onClick={() => {
          props.setBackDropping(true);
          props.reloadList((current_value: boolean) => !current_value);
        }}
      >
        <img
          src={reload}
          className={style.reloadImage}
          alt="reload"
          style={!getPermissions().list.ocr_result ? { opacity: 0.2 } : {}}
        />
      </Button>
      <Button
        className={style.reloadDeleteButton}
        title="削除する"
        variant="contained"
        disabled={!getPermissions().delete.ocr_result}
        onClick={() => {
          if (props.checkedIDs.length === 0) {
          } else if (window.confirm("選択したOCR結果を削除しますか？")) {
            props.setBackDropping(true);
            deleteOcrResultBinder(
              props.checkedIDs,
              props.reloadList,
              getAccessTokenSilently,
              getIdTokenClaims
            );
          }
        }}
      >
        <img
          src={garbage}
          className={style.garbageImage}
          alt="delete"
          style={!getPermissions().delete.ocr_result ? { opacity: 0.2 } : {}}
        />
      </Button>
    </div>
  );
}

function OcrListTable(props) {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const style = useStyles();
  const history = useHistory();
  const [rawRows, setRawRows] = React.useState(null);
  const [rows, setRows] = React.useState(null);
  const [thumbnails, setThumbnails] = React.useState(null);

  const checkboxCol = { maxWidth: "48px", minWidth: "48px", padding: 0 };
  const nameCol = { maxWidth: "215px", minWidth: "215px" };
  const descriptionCol = { maxWidth: "215px", minWidth: "215px" };
  const updateDateCol = { maxWidth: "110px", minWidth: "110px" };
  const createDateCol = { maxWidth: "110px", minWidth: "110px" };
  const idCol = { maxWidth: "95px", minWidth: "95px" };
  const thumbnailCol = { maxWidth: "85px", minWidth: "85px", padding: "5px" };
  const editButtonCol = { maxWidth: "110px", minWidth: "110px" };

  const showStatus = (statusCode: number, errMessage: string = "") => {
    let icon, title;
    if (statusCode === 0) {
      icon = <CheckCircle color="primary" />;
      title = "完了";
    } else if (statusCode === 1) {
      icon = <HourglassEmptyIcon color="secondary" />;
      title = "待機中";
    } else {
      icon = <Error color="secondary" />;
      title = errMessage;
    }
    return (
      <Tooltip title={title} aria-label={title}>
        {icon}
      </Tooltip>
    );
  };

  const reloadListTrigger = props.reloadListTrigger;
  const searchText = props.searchText;
  const setCheckBoxStatus = props.setCheckBoxStatus;
  const setBinderStatus = props.setBinderStatus;
  const setBackDropping = props.setBackDropping;
  const setCheckedIDs = props.setCheckedIDs;
  const setAllChecked = props.setAllChecked;
  React.useEffect(() => {
    const ocrBinderListGetter = new OcrResultAPI(
      "get_list_of_ocr_result_binder"
    );
    ocrBinderListGetter.setParam({ param: { gets_thumbnail: true } });
    getAccessTokenSilently()
      .then(() => getIdTokenClaims())
      .then((idToken) => {
        ocrBinderListGetter
          .execPostRequest(idToken.__raw)
          .then((response) => response.json())
          .then(
            (res) => {
              console.log("list", res);
              let sortedRows = res.ocr_result_list.sort(
                compareUpdatedTime
              ) as Array<any>;
              setThumbnails(res.binary_data);
              setRawRows(sortedRows);
              setRows(sortedRows);
              const initialCheckBoxStatus = {};
              const bindersStatus = {};
              for (const row of sortedRows) {
                initialCheckBoxStatus[row.ocr_result_binder_id] = false;
                bindersStatus[row.ocr_result_binder_id] = row.status.code;
              }
              console.log("binderStatus", bindersStatus);
              setCheckBoxStatus(initialCheckBoxStatus);
              setBinderStatus(bindersStatus);
              setCheckedIDs([]);
              setAllChecked(false);
              console.log("then");
              console.log(res);
              console.log(res.ocr_result_list);
              setBackDropping(false);
            },
            (error) => {
              console.log(error);
            }
          );
      });
  }, [
    reloadListTrigger,
    setCheckBoxStatus,
    setBinderStatus,
    setBackDropping,
    getAccessTokenSilently,
    getIdTokenClaims,
    setAllChecked,
    setCheckedIDs,
  ]);

  React.useEffect(() => {
    if (rawRows && searchText !== "") {
      const searchWords = searchText.split(/\s+/);
      const hitRowIDs = [];

      // name, description, template_id, ocr_result_binder_id
      // のいずれかにすべてのwordが含まれるかチェック
      for (const row of rawRows) {
        const hitWords = [];
        for (const word of searchWords) {
          const lowerWord = word.toLowerCase();
          if (
            row.name.toLowerCase().includes(lowerWord) ||
            (row.description &&
              row.description.toLowerCase().includes(lowerWord)) ||
            row.template_id.toLowerCase().includes(lowerWord) ||
            row.ocr_result_binder_id.toLowerCase().includes(lowerWord)
          ) {
            hitWords.push(word);
          } else {
            break;
          }
        }
        if (searchWords.every((word) => hitWords.includes(word))) {
          hitRowIDs.push(row.ocr_result_binder_id);
        }
      }
      const sortedRows = rawRows.filter((row) =>
        hitRowIDs.includes(row.ocr_result_binder_id)
      );
      setRows(sortedRows);
      const initialCheckBoxStatus = {};
      for (const row of sortedRows) {
        initialCheckBoxStatus[row.ocr_result_binder_id] = false;
      }
      setCheckBoxStatus(initialCheckBoxStatus);
      setCheckedIDs([]);
      setAllChecked(false);
    } else {
      if (rawRows) {
        setRows(rawRows);
        const initialCheckBoxStatus = {};
        for (const row of rawRows) {
          initialCheckBoxStatus[row.ocr_result_binder_id] = false;
        }
        setCheckBoxStatus(initialCheckBoxStatus);
        setCheckedIDs([]);
        setAllChecked(false);
      }
    }
  }, [
    reloadListTrigger,
    rawRows,
    searchText,
    setCheckBoxStatus,
    setAllChecked,
    setCheckedIDs,
  ]);

  return rows === null ? (
    <React.Fragment>
      <LinearProgress />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Table
        className={style.table}
        size="small"
        aria-label="a dense table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell className={style.tableHeader} style={checkboxCol}>
              <Checkbox
                className={style.checkbox}
                size="small"
                disableRipple
                checked={props.allChecked}
                onChange={(event) => {
                  props.handleChange(event, "checkAll");
                }}
              />
            </TableCell>
            <TableCell className={style.tableHeader}>状態</TableCell>
            <TableCell className={style.tableHeader}>結果名</TableCell>
            <TableCell className={style.tableHeader}>説明文</TableCell>
            <TableCell className={style.tableHeader}>更新日時</TableCell>
            <TableCell className={style.tableHeader}>作成日時</TableCell>
            <TableCell className={style.tableHeader}>結果ID</TableCell>
            <TableCell className={style.tableHeader}>読取箇所設定ID</TableCell>
            <TableCell className={style.tableHeader}>画像</TableCell>
            <TableCell className={style.tableHeader}>編集する</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={row.ocr_result_binder_id}
              id={row.ocr_result_binder_id}
              style={
                index % 2
                  ? { backgroundColor: "#f2f2f2" }
                  : { backgroundColor: "white" }
              }
            >
              <TableCell className={style.table_body} style={checkboxCol}>
                <Checkbox
                  className={style.checkbox}
                  size="small"
                  disableRipple
                  checked={
                    props.checkBoxStatus[row.ocr_result_binder_id] === true
                  }
                  onChange={(event) => {
                    props.handleChange(event, row.ocr_result_binder_id);
                  }}
                />
              </TableCell>
              <TableCell className={style.table_body} style={checkboxCol}>
                {showStatus(row.status.code, row.status.err_reason)}
              </TableCell>
              <TableCell className={style.table_body} style={nameCol}>
                {getPermissions().read.ocr_result && row.status.code === 0 ? (
                  <Link
                    to={"/ocr/edit?ocr_binder_id=" + row.ocr_result_binder_id}
                  >
                    <div className={style.textContainer} title={row.name}>
                      {row.name}
                    </div>
                  </Link>
                ) : (
                  <div className={style.textContainer} title={row.name}>
                    {row.name}
                  </div>
                )}
              </TableCell>
              <TableCell className={style.table_body} style={descriptionCol}>
                <div className={style.textContainer} title={row.description}>
                  {row.description}
                </div>
              </TableCell>
              <TableCell className={style.table_body} style={updateDateCol}>
                <div className={style.textContainer}>
                  {new Date(row.updated_at).toLocaleString()}
                </div>
              </TableCell>
              <TableCell className={style.table_body} style={createDateCol}>
                <div className={style.textContainer}>
                  {new Date(row.created_at).toLocaleString()}
                </div>
              </TableCell>
              <TableCell className={style.table_body} style={idCol}>
                <div className={style.textContainer}>
                  {row.ocr_result_binder_id}
                </div>
              </TableCell>
              <TableCell className={style.table_body} style={idCol}>
                <div className={style.textContainer}>{row.template_id}</div>
              </TableCell>
              <TableCell className={style.table_body} style={thumbnailCol}>
                <img
                  src={
                    "data:image/png;base64," +
                    thumbnails[row.ocr_result_binder_id]
                  }
                  width="100%"
                  className={style.thumbNail}
                  alt="thumbnail"
                />
              </TableCell>
              <TableCell className={style.table_body} style={editButtonCol}>
                <Button
                  variant="contained"
                  disabled={
                    !getPermissions().read.ocr_result || row.status.code !== 0
                  }
                  onClick={() => {
                    history.push(
                      "/ocr/edit?ocr_binder_id=" + row.ocr_result_binder_id
                    );
                  }}
                >
                  編集
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

const deleteOcrResultBinder = (
  ocr_result_binder_ids,
  reloadList,
  getAccessTokenSilently,
  getIdTokenClaims
) => {
  getAccessTokenSilently()
    .then(() => getIdTokenClaims())
    .then((idToken) => {
      let promises = [];
      ocr_result_binder_ids.map((ocr_result_binder_id) => {
        const ocrResultAPI = new OcrResultAPI("delete_ocr_result_binder");
        const ocrResultAPI_param = {
          param: {
            ocr_result_binder_id: ocr_result_binder_id,
          },
          binary_data: {},
        };
        ocrResultAPI.setParam(ocrResultAPI_param);
        promises.push(
          ocrResultAPI
            .execPostRequest(idToken.__raw)
            .then((response) => response.json())
        );
        return [];
      });

      Promise.all(promises).then(
        (results) => {
          console.log("delete");
          console.log(results);
          reloadList((current_value: boolean) => !current_value);
        },
        (error) => {
          console.log("ERROR", error);
          alert(ErrorMessages.pleaseTryAgain);
          reloadList((current_value: boolean) => !current_value);
        }
      );
    });
};

const downloadResult = (
  binderStatus: object,
  checkedIDs: Array<string>,
  fileType: FileTypes,
  getAccessTokenSilently,
  getIdTokenClaims,
  setBackDropping
) => {
  checkedIDs.forEach((id, i) => {
    console.log(binderStatus);
    if (binderStatus[id] !== 0) {
      alert(`ID:${id}は完了していないためダウンロードできません。`);
      if (checkedIDs.length - 1 === i) {
        setBackDropping(false);
      }
      return;
    }
    const getOcrBinder = new OcrResultAPI("get_ocr_result_binder");
    getAccessTokenSilently()
      .then(() => getIdTokenClaims())
      .then((idToken) => {
        const getOcrBinderParam = {
          param: {
            ocr_result_binder_id: id,
            gets_page_items: true,
            omits_page_images: true,
          },
        };
        console.log("param", getOcrBinderParam);
        getOcrBinder.setParam(getOcrBinderParam);
        getOcrBinder
          .execPostRequest(idToken.__raw)
          .then((response) => response.json())
          .then((binderRes) => {
            // 保存しない要素を削除
            [
              "binary_data",
              "group_id",
              "user_id",
              "gcs_item_path",
              "gcs_item_path_thumbnail",
              "result_pages",
            ].forEach((property) => delete binderRes[property]);
            for (const page of binderRes.pages_items_list) {
              for (const item of page) {
                ["description", "rectangle"].forEach(
                  (property) => delete item[property]
                );
              }
            }
            const updatedAt = new Date(binderRes.updated_at)
              .toLocaleString()
              .replace(/\/|:|\s/g, "_");
            console.log(updatedAt);
            const binderName = binderRes.name + "-" + updatedAt;
            if (fileType === FileTypes.JSON) {
              const jsonContent = JSON.stringify(binderRes, null, 2);
              saveToFile(`${binderName}.json`, jsonContent, fileType);
              setBackDropping(false);
            } else if (fileType === FileTypes.TSV) {
              // headerを作成
              const itemNames = [];
              for (const item of binderRes.pages_items_list[0].sort(
                (a, b) => a.no - b.no
              )) {
                itemNames.push(item.name);
              }
              const rows = [
                ["ocr_result_binder_id", "name", "page_count", "page"].concat(
                  itemNames
                ),
              ];
              const ocrResultBinderID = binderRes.ocr_result_binder_id;
              const pageCount = binderRes.page_count;
              binderRes.pages_items_list.forEach((page, idx) => {
                const resultsPerPage = [];
                const sortedPage = page.sort((a, b) => a.no - b.no);
                console.log(sortedPage);
                for (const item of sortedPage) {
                  resultsPerPage.push(item.result.ocr_result);
                }
                rows.push(
                  [ocrResultBinderID, binderName, pageCount, idx + 1].concat(
                    resultsPerPage
                  )
                );
              });
              const csvContent = // BOM付き
                "\ufeff" + rows.map((e) => e.join("\t")).join("\n");

              let tempContent = [];
              for (let i = 0; i < csvContent.length; i++) {
                tempContent.push(csvContent.charCodeAt(i));
              }
              const utf16leCsvContent = new Uint16Array(tempContent);
              saveToFile(`${binderName}.tsv`, utf16leCsvContent, fileType);
              setBackDropping(false);
            }
          })
          .catch((e) => {
            alert(
              `ID:${id}の結果取得に失敗しました。\n` +
                ErrorMessages.pleaseTryAgain
            );
            console.log(e);
            setBackDropping(false);
          });
      })
      .catch();
  });
};

const saveToFile = (
  filename,
  content: string | Uint16Array,
  fileType: FileTypes
) => {
  const header =
    fileType === FileTypes.TSV
      ? "data:text/tsv;charset=utf-16"
      : fileType === FileTypes.JSON
      ? "data:application/json;charset=utf-8"
      : "";
  const blob = new Blob([content], { type: header });

  const link = document.createElement("a");
  link.setAttribute("href", window.URL.createObjectURL(blob));
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

import * as React from "react";

import {
  AppBar,
  Container,
  List,
  ListItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import CheckCircle from "@material-ui/icons/CheckCircle";
import Error from "@material-ui/icons/Error";
import { Messages } from "../../Common/limits";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filenameListContainerHeader: {
      backgroundColor: "#663399",
      height: 50,
      padding: 0,
    },
    filenameListContainerToolbar: {
      width: "100%",
      padding: 0,
      paddingLeft: 50,
      minHeight: 50,
      margin: "auto",
    },
    filenameListDescription: {
      width: "100%",
    },
    filenameListContainerContainer: {
      width: "100%",
      padding: 0,
      display: "flex",
      justifyContent: "center",
      marginTop: 10,
      marginBottom: 10,
    },
    filenameListContainer: {
      width: "100%",
      padding: 0,
      justifyContent: "center",
    },
    filenameListTypoGraphy: {
      display: "flex",
      marginLeft: 8,
      marginRight: 8,
    },
    filenameListIcon: {
      marginRight: 5,
    },
    filenameList: {
      backgroundColor: "#EFEFEF",
      overflow: "auto",
      maxHeight: 100,
      margin: 8,
      color: "#3f51b5",
    },
    invalidFilenameList: {
      backgroundColor: "#EFEFEF",
      overflow: "auto",
      maxHeight: 100,
      margin: 8,
      color: "#f50057",
    },
  })
);

interface SelectedFilesListIF {
  validFileNames: Array<string>;
  invalidSizeFileNames: Array<string>;
  invalidSizeReasons: Array<string>;
  invalidResolutionFileNames: Array<string>;
  invalidResolutionReasons: Array<string>;
}
export default function SelectedFilesList(props: SelectedFilesListIF) {
  const style = useStyles();
  return (
    <>
      <AppBar
        style={{ boxShadow: "none" }}
        position="static"
        className={style.filenameListContainerHeader}
      >
        <Toolbar className={style.filenameListContainerToolbar}>
          <p>選択されたファイル</p>
        </Toolbar>
      </AppBar>
      <p className={style.filenameListDescription}>
        非対応のファイルは除外され、対応範囲内のファイルのみOCR実行されます。
        <br />※{Messages.INVALID_FILESIZE_DESCRIPTION}、
        {Messages.INVALID_RESOLUTION_DESCRIPTION}
      </p>
      <Container className={style.filenameListContainerContainer}>
        {props.validFileNames.length !== 0 ? (
          <Container className={style.filenameListContainer}>
            <Typography
              className={style.filenameListTypoGraphy}
              color="primary"
            >
              <CheckCircle className={style.filenameListIcon} color="primary" />
              正常ファイル
            </Typography>
            <List className={style.filenameList}>
              {props.validFileNames.map((filename) => (
                <ListItem key={`item-${filename}`}>{filename}</ListItem>
              ))}
            </List>
          </Container>
        ) : null}
        {props.invalidSizeFileNames.length !== 0 ? (
          <Container className={style.filenameListContainer}>
            <Typography
              className={style.filenameListTypoGraphy}
              color="secondary"
            >
              <Error className={style.filenameListIcon} color="secondary" />
              ファイルサイズ異常により除外
            </Typography>
            <List className={style.invalidFilenameList}>
              {props.invalidSizeFileNames.map((filename, i) => (
                <ListItem
                  key={`item-${filename}`}
                  title={props.invalidSizeReasons[i]}
                >
                  {filename}
                </ListItem>
              ))}
            </List>
          </Container>
        ) : null}
        {props.invalidResolutionFileNames.length !== 0 ? (
          <Container className={style.filenameListContainer}>
            <Typography
              className={style.filenameListTypoGraphy}
              color="secondary"
            >
              <Error className={style.filenameListIcon} color="secondary" />
              ファイル画素数異常により除外
            </Typography>
            <List className={style.invalidFilenameList}>
              {props.invalidResolutionFileNames.map((filename, i) => (
                <ListItem
                  key={`item-${filename}`}
                  title={props.invalidResolutionReasons[i]}
                >
                  {filename}
                </ListItem>
              ))}
            </List>
          </Container>
        ) : null}
      </Container>
    </>
  );
}

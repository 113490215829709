import * as React from "react";

import { Button, Container } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";
import { TextLimits } from "../../Common/limits";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { getPermissions } from "../../Common/permissions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main_header: {
      flexGrow: 1,
      zIndex: 999,
    },
    router_link: {
      margin: "10px",
    },
    body_container: {
      maxWidth: "none",
      maxHeight: "none",
    },
    body_header: {
      flexGrow: 1,
      zIndex: 1,
      backgroundColor: "rgba(0,0,0,0)",
    },
    toolbar: {
      minHeight: 48,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    table_head: {
      backgroundColor: "#EEEEEE",
    },
    table_body: {
      fontSize: "0.7em",
    },
    table_container: {
      maxHeight: 400,
      minWidth: 650,
    },
    table_paper: {
      width: "100%",
    },
    checkbox: {
      width: 30,
      height: 0,
    },
    button_container: {
      margin: "10px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

export default function DictionaryUpload() {
  return (
    <React.Fragment>
      <BodyHeader />
      <Buttons />
    </React.Fragment>
  );
}

function BodyHeader() {
  const style = useStyles();
  return (
    <AppBar
      className={style.body_header}
      position="static"
      color="default"
      elevation={0}
    >
      <Toolbar className={style.toolbar}>
        <Typography variant="subtitle1" className={style.title}>
          辞書新規登録
        </Typography>
        <Link className={style.router_link} to="/dictionary/list">
          辞書一覧へ戻る
        </Link>
      </Toolbar>
    </AppBar>
  );
}

function Buttons(props) {
  const history = useHistory();
  const style = useStyles();
  const [dictionaryName, setDictionaryName] = React.useState("");

  return (
    <Container className={style.button_container}>
      <TextField
        id="outlined-basic"
        label="登録辞書名"
        variant="outlined"
        size="small"
        fullWidth
        disabled={!getPermissions().create.dictionary}
        onChange={(event) => {
          setDictionaryName(event.target.value);
        }}
        inputProps={{
          maxLength: TextLimits.MAX_TEXT_LENGTH,
        }}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={
          !getPermissions().create.dictionary ||
          !dictionaryName ||
          props.disabled
        }
        onClick={() => {
          history.push("/dictionary/edit?newDictionary=" + dictionaryName);
        }}
      >
        作成
      </Button>
      <Link className={style.router_link} to="/dictionary/list">
        キャンセル
      </Link>
    </Container>
  );
}

export const compareArea = (a, b) => {
  const areaA = a.rectangle.w * a.rectangle.h;
  const areaB = b.rectangle.w * b.rectangle.h;
  let comparison = 0;
  if (areaA < areaB) {
    comparison = 1;
  } else if (areaA > areaB) {
    comparison = -1;
  }
  return comparison;
};

export const compareUpdatedTime = (a, b) => {
  let comparison = 0;
  if (a.updated_at < b.updated_at) {
    comparison = 1;
  } else if (a.updated_at > b.updated_at) {
    comparison = -1;
  }
  return comparison;
};

export const compareListName = (a, b) => {
  let comparison = 0;
  if (a.list_name < b.list_name) {
    comparison = -1;
  } else if (a.list_name > b.list_name) {
    comparison = 1;
  }
  return comparison;
};

export const compareName = (a, b) => {
  let comparison = 0;
  if (a.name < b.name) {
    comparison = -1;
  } else if (a.name > b.name) {
    comparison = 1;
  }
  return comparison;
};

import * as React from "react";

import {
  AppBar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  LinearProgress,
  Paper,
  Theme,
  Toolbar,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import {
  FilenameAndStandardized,
  TemplateAPI,
  img2img,
  pdf2img,
  requestOCR,
  tiff2img,
} from "../../Common/api";
import {
  checkFileSize,
  checkFileTypes,
  checkResolution,
} from "../../Common/limits";

import { ErrorMessages } from "../../Common/error";
import FileInputComponent from "react-file-input-previews-base64";
import SelectedFilesList from "./ocr-common";
import arithIcon from "../../Images/arith.png";
import arrowShort from "../../Images/arrow-short.png";
import { getPermissions } from "../../Common/permissions";
import ocrIcon from "../../Images/header_ocr.png";
import uploadIcon from "../../Images/upload.png";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    body_header: {
      flexGrow: 1,
      zIndex: 1,
      backgroundColor: "rgba(0,0,0,0)",
    },
    toolbar: {
      padding: 0,
      minHeight: 48,
      marginTop: 10,
      marginBottom: 10,
    },
    title: {
      flexGrow: 1,
      color: "#663399",
    },
    PanelContainer: {
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
    },
    boxTemplate: {
      marginBottom: 50,
      backgroundColor: "#EFEFEF",
      borderColor: "#E7E7E7",
    },
    boxOcr: {
      marginBottom: 50,
      borderColor: "rgba(102,51,153,0.3)",
      background: "rgba(102,51,153,0.1)",
    },
    boxOcrSelected: {
      marginBottom: 50,
      borderColor: "rgba(102,51,153,1.0)",
      background: "rgba(102,51,153,0.1)",
    },
    panel: {
      margin: 0,
      padding: 20,
      paddingBottom: 30,
      width: 500,
    },
    panelTitle: {
      color: "#303030",
    },
    panelP: {
      color: "#303030",
      marginTop: 5,
      marginBottom: 5,
    },
    imagePaper: {
      marginTop: 20,
      height: 350,
      width: 300,
      display: "flex",
      margin: "auto",
      backgroundColor: "unset",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      margin: "auto",
    },
    arith_comment_container: {
      display: "flex",
      width: 450,
      padding: 0,
      marginTop: 5,
    },
    arith_icon: {
      width: 45.75,
      height: 54.75,
    },
    fileButtonContainer: {},
    arrowShort: {
      width: 97,
      height: 97.5,
      margin: "auto",
    },
    arith_comment: {
      height: 40,
      margin: "auto",
      marginLeft: 10,
      lineHeight: "40px",
      padding: "0 24px",
      backgroundColor: "#EFEFEF",
      borderRadius: "0px 25px 25px 25px",
      boxShadow: "3px 3px lightgrey",
      fontSize: "smaller",
    },
    fileSelectButton: {
      fontSize: "initial",
      display: "flex",
      width: "100%",
      height: 70,
      padding: 10,
      backgroundColor: "#43B9F7",
      "&:hover": {
        backgroundColor: "#0093E3",
      },
    },
    uploadButtonImg: {
      width: 25,
      height: "auto",
      marginRight: 20,
    },
    ocrButtonContainer: {
      display: "flex",
      marginBottom: 50,
    },
    ocrButton: {
      fontSize: "initial",
      margin: "auto",
      width: 500,
      height: 60,
      backgroundColor: "#43B9F7",
      "&:hover": {
        backgroundColor: "#0093E3",
      },
    },
    ocrButtonImg: {
      width: 40,
      height: "auto",
      marginRight: 20,
    },
  })
);

export default function OcrUploadFromTemplate(props) {
  const [template, setTemplate] = React.useState(null);
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  React.useEffect(() => {
    let search = new URLSearchParams(props.location.search);
    const templateID = search.get("template_id");
    const templateGetter = new TemplateAPI("get_template");
    const templateGetParam = { param: { template_id: templateID } };
    templateGetter.setParam(templateGetParam);
    getAccessTokenSilently()
      .then(() => getIdTokenClaims())
      .then((idToken) => {
        templateGetter
          .execPostRequest(idToken.__raw)
          .then((response) => response.json())
          .then(setTemplate);
      });
  }, [getAccessTokenSilently, getIdTokenClaims, props.location.search]);

  return (
    <>
      <BodyHeader />
      {template ? <Panels template={template} /> : <LinearProgress />}
    </>
  );
}

function BodyHeader() {
  const style = useStyles();
  return (
    <AppBar
      className={style.body_header}
      position="static"
      color="default"
      elevation={0}
    >
      <Toolbar className={style.toolbar}>
        <Typography variant="h6" className={style.title}>
          OCR：新規OCR実行
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

function Panels(props) {
  const [selected, setSelected] = React.useState(null);
  const [backDropping, setBackDropping] = React.useState(false);
  const style = useStyles();
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [arrayOfStandardized, setArrayOfStandardized] = React.useState<
    Array<FilenameAndStandardized>
  >([]);
  const [ready, setReady] = React.useState(false);
  const [invalidSizeFileNames, setInvalidSizeFileNames] = React.useState([]);
  const [invalidSizeReasons, setInvalidSizeReasons] = React.useState([]);
  const [
    invalidResolutionFileNames,
    setInvalidResolutionFileNames,
  ] = React.useState([]);
  const [
    invalidResolutionReasons,
    setInvalidResolutionReasons,
  ] = React.useState([]);
  const [validFileNames, setValidFileNames] = React.useState([]);

  // 画像選択されたときに類似画像検索までするEffect
  React.useEffect(() => {
    setReady(false);
    setInvalidSizeFileNames([]);
    setInvalidResolutionFileNames([]);
    setValidFileNames([]);
    if (selected === null) {
      return;
    }
    // 選択されたファイルを一定のフォーマットのbase64に変換
    const convertFromFiles = async () => {
      // checkFiletTypes
      let checkedFiles = checkFileTypes(selected);
      if (checkedFiles.ngFileNames.length > 0) {
        alert(
          "非対応ファイルを選択しないでください。\n" +
            checkedFiles.ngFileNames.join("\n")
        );
      }
      // fileSizeCheck
      checkedFiles = checkFileSize(checkedFiles.ok);
      // すべてのファイルを画像変換
      const _arrayOfStandardizedRes = await Promise.all(
        checkedFiles.ok.map(async (file) => {
          const fileBase64 = file["base64"];
          let [type, base64] = fileBase64.split(",");
          const isPdf = type === "data:application/pdf;base64";
          const isTiff = type === "data:image/tiff;base64";
          // ファイルの種類によってstandardize処理を選択
          const convertFromFileToImage = isPdf
            ? pdf2img
            : isTiff
            ? tiff2img
            : img2img;
          // token取得
          await getAccessTokenSilently();
          const idToken = await getIdTokenClaims();
          // call
          const res = await convertFromFileToImage(base64).execPostRequest(
            idToken.__raw
          );
          const standardizeRes: object = await res.json();
          const filename: string = file["name"];
          console.log("standardizeRes", standardizeRes);
          return new FilenameAndStandardized(filename, standardizeRes);
        })
      );
      // 画像サイズチェック
      const checkedArrayOfStandardizedRes = await checkResolution(
        _arrayOfStandardizedRes
      );
      setInvalidSizeFileNames(checkedFiles.ngFileNames);
      setInvalidSizeReasons(checkedFiles.ngFileReasons);
      setInvalidResolutionFileNames(checkedArrayOfStandardizedRes.ngFileNames);
      setInvalidResolutionReasons(checkedArrayOfStandardizedRes.ngFileReasons);
      setValidFileNames(checkedArrayOfStandardizedRes.okFileNames);
      if (checkedArrayOfStandardizedRes.ok.length === 0) {
        alert("非対応のファイルが選択されました。");
        setBackDropping(false);
        return;
      }
      setArrayOfStandardized(checkedArrayOfStandardizedRes.ok);
      setReady(true);
      setBackDropping(false);
    };
    setBackDropping(true);
    try {
      convertFromFiles();
    } catch {
      alert(ErrorMessages.pleaseTryAgain);
      setBackDropping(false);
    }
  }, [selected, setReady, getAccessTokenSilently, getIdTokenClaims]);

  return (
    <>
      <Backdrop className={style.backdrop} open={backDropping}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container className={style.PanelContainer}>
        <Box className={style.boxTemplate} border={5}>
          <Container className={style.panel}>
            <Typography className={style.panelTitle} variant="h6">
              選択したテンプレート
            </Typography>
            <p className={style.panelP}>
              テンプレート名：{props.template.name}
            </p>
            <p className={style.panelP}>
              テンプレートID：{props.template.template_id}
            </p>
            <Paper className={style.imagePaper} elevation={0}>
              <img
                className={style.image}
                alt={"template"}
                src={"data:image;base64," + props.template.binary_data.img}
              />
            </Paper>
          </Container>
        </Box>
        <img className={style.arrowShort} alt={"arrow"} src={arrowShort} />
        <Box
          className={selected ? style.boxOcrSelected : style.boxOcr}
          border={5}
        >
          <Container className={style.panel}>
            <Typography className={style.panelTitle} variant="h6">
              OCRするファイルを選択する
            </Typography>

            <Container className={style.arith_comment_container}>
              <img
                alt={"ai-arith"}
                src={arithIcon}
                className={style.arith_icon}
              />
              <p className={style.arith_comment}>
                OCRで使用するファイルを選択して下さい。
              </p>
            </Container>
            <SelectFileBox setSelected={setSelected} selected={selected} />
          </Container>
        </Box>
      </Container>
      {selected ? (
        <SelectedFilesList
          invalidResolutionFileNames={invalidResolutionFileNames}
          invalidResolutionReasons={invalidResolutionReasons}
          invalidSizeFileNames={invalidSizeFileNames}
          invalidSizeReasons={invalidSizeReasons}
          validFileNames={validFileNames}
        />
      ) : null}
      {ready && selected ? (
        <Container className={style.ocrButtonContainer}>
          <Button
            color="primary"
            variant="contained"
            className={style.ocrButton}
            style={{ cursor: "hand" }}
            disabled={!getPermissions().execute.ocr}
            onClick={() => {
              setBackDropping(true);
              requestOCR(
                props.template.template_id,
                arrayOfStandardized,
                getAccessTokenSilently,
                getIdTokenClaims
              );
            }}
          >
            <img alt="ocr-icon" src={ocrIcon} className={style.ocrButtonImg} />
            こちらのテンプレートでOCRする
          </Button>
        </Container>
      ) : null}
    </>
  );
}

function SelectFileBox(props) {
  const style = useStyles();
  console.log("props", props);
  return (
    <FileInputComponent
      labelText={""}
      //parentStyle={} //スタイル
      imagePreview={false} //ファイルのプレビュー
      multiple={true} //複数ファイル選択
      callbackFunction={props.setSelected}
      accept="image/png,image/jpeg,image/tiff,application/pdf" //許可するファイルのtype
      buttonComponent={
        props.selected ? (
          <Paper
            className={style.imagePaper}
            style={{ cursor: "pointer" }}
            elevation={0}
          >
            <img
              className={style.image}
              alt={"ocr src"}
              src={props.selected[0].base64}
            />
          </Paper>
        ) : (
          <Button
            color="primary"
            variant="contained"
            className={style.fileSelectButton}
            style={{ cursor: "hand" }}
          >
            <img
              alt="upload-icon"
              src={uploadIcon}
              className={style.uploadButtonImg}
            />
            ファイルを選択
          </Button>
        )
      }
    />
  );
}

import * as React from "react";

import { Checkbox, FormControlLabel } from "@material-ui/core";
import {
  CorrectByListAPI,
  FormatAIOcrAPI,
  ManageOcrModelAPI,
  TemplateAPI,
} from "../../Common/api";
import {
  Image as KonvaImage,
  Layer,
  Rect,
  Stage,
  Transformer,
} from "react-konva";
import { Prompt, useHistory } from "react-router-dom";
import { TemplateLimits, TextLimits } from "../../Common/limits";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  compareArea,
  compareListName,
  compareName,
} from "../../Common/sortUtils";
import {
  createItemForOCRAPI,
  createItemForTemplateAPI,
} from "../../Common/paramConversion";

import AppBar from "@material-ui/core/AppBar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import { ErrorMessages } from "../../Common/error";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import addRectIcon from "../../Images/addRect.png";
import clsx from "clsx";
import deleteSelectedIcon from "../../Images/deleteSelected.png";
import deleteUnselectedIcon from "../../Images/deleteUnselected.png";
import { getPermissions } from "../../Common/permissions";
import ocrIcon from "../../Images/header_ocr.png";
import redoIcon from "../../Images/redo.png";
import saveIcon from "../../Images/save.png";
import undoIcon from "../../Images/undo.png";
import { useAuth0 } from "@auth0/auth0-react";
import useImage from "use-image";
import zoomIn from "../../Images/zoomIn.png";
import zoomInit from "../../Images/zoomInit.png";
import zoomOut from "../../Images/zoomOut.png";

const paperImageWidth = 750;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body_div: {
      outline: "none",
      margin: "auto",
    },
    stickyHeader: {
      padding: 0,
      paddingBottom: "10px",
      zIndex: 1,
      position: "sticky",
      top: 10,
    },
    body_header: {
      flexGrow: 1,
      zIndex: 1,
      backgroundColor: "rgba(0,0,0,0)",
    },
    toolbar: {
      padding: 0,
      minHeight: 48,
      marginTop: 10,
      marginBottom: 10,
    },
    title: {
      flexGrow: 1,
      color: "#002081",
    },
    paper_container: {
      padding: 0,
      marginTop: "10px",
      marginBottom: "10px",

      display: "flex",
    },
    image_paper: {
      padding: 0,
      width: paperImageWidth,
    },
    image_container: {
      maxHeight: "100%",
      maxWidth: "100%",
    },
    card_container: {
      marginLeft: "10px",
      paddingRight: 0,
      width: "450px",
      maxHeight: "calc(100vh - 126px)",
      overflow: "auto",
      position: "sticky",
    },
    card_root: {
      maxWidth: "100%",
      margin: "1px",
      border: "solid 1px gray",
    },
    selected_card: {
      maxWidth: "100%",
      margin: "1px",
      border: "solid 3px indianred",
    },
    card_header: {
      padding: 0,
    },
    cardHeaderAction: {
      margin: 0,
      width: "90%",
    },
    cardHeaderContent: {
      textAlign: "center",
    },
    input: {
      flex: 1,
    },
    card_expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    card_expandOpen: {
      transform: "rotate(180deg)",
    },
    button_container: {
      marginTop: "10px",
      marginBottom: "10px",
      display: "flex",
      padding: 0,
      justifyContent: "space-between",
      "& > *": { paddingLeft: "20px", paddingRight: "20px" },
    },
    input_base_root: {
      padding: "3px",
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    result_chip: {
      width: "80%",
      height: "100%",
      marginTop: "0px",
      marginBottom: "5px",
      marginLeft: "70px",
    },
    typography: { whiteSpace: "normal" },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    inputLabel: {
      marginRight: "10px",
    },
    inner_expand: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    fillParent: {
      width: "100%",
    },
    fill80: {
      width: "80%",
    },
    innerExpandPadding: {
      padding: "4px",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    },
    text_name: {
      width: "30%",
      marginRight: "5%",
    },
    text_description: {
      width: "65%",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    checkbox: {
      width: 30,
      height: 0,
    },
    ocrItemButton: {
      padding: "4px 8px",
      borderRadius: "100px",
      backgroundColor: "#43B9F7",
      "&:hover": {
        backgroundColor: "#0093E3",
      },
    },
    ocrAllButton: {
      backgroundColor: "#43B9F7",
      "&:hover": {
        backgroundColor: "#0093E3",
      },
    },
    saveButton: {
      backgroundColor: "#36D0AA",
      "&:hover": {
        backgroundColor: "#00AF78",
      },
    },
    undoRedoImage: {
      width: "22px",
      height: "22px",
    },
    zoom: {
      width: "25.5px",
    },
    ocrImage: {
      width: "26px",
      height: "20px",
      marginRight: "15px",
    },
    saveImage: {
      width: "22px",
      height: "23px",
      marginRight: "15px",
    },
    otherImages: {
      width: "22px",
      height: "22px",
      marginRight: "15px",
    },
    undoRedoButton: {
      padding: 7,
      minWidth: 0,
    },
  })
);

class Ids {
  static itemCardId(id: string): string {
    return "ItemCardId_" + id;
  }
  static rectId(id: string): string {
    return "RectId_" + id;
  }
  static modelSelectorId(id: string): string {
    return "ModelSelectorId_" + id;
  }
  static correctListSelectorId(id: string): string {
    return "CorrectListSelectorId_" + id;
  }
  static inputId(id: string): string {
    return "InputId_" + id;
  }
  static getCommonIdFromItemCartId = (id: string): string => {
    return id.substr(12);
  };
  static getCommonIdFromRectId = (id: string): string => {
    return id.substr(7);
  };
  static getCommonIdFromModelSelectorId = (id: string): string => {
    return id.substr(16);
  };
  static getCommonIdInputId = (id: string): string => {
    return id.substr(8);
  };
}

interface OcrItem {
  item_id: string | null;
  no: number;
  name: string;
  rectangle: {
    x: number;
    y: number;
    w: number;
    h: number;
    modified_x: number;
    modified_y: number;
  };
  adjust_list: string;
  pre_proccess: string;
  post_proccess: string;
  model_id: string;
}

interface TemplateData {
  name: string;
  description: string;
  image: string;
  items: OcrItem[];
}
function randomId() {
  return Math.random().toString(32).substring(2);
}

export default function TemplateEdit(props) {
  const style = useStyles({});
  const history = useHistory();
  const [reloadItemsTrigger, reloadItems] = React.useState(false);
  const [backDropping, setBackDropping] = React.useState(false);
  const [pageLoading, setPageLoading] = React.useState(true);
  const [overview, setOverview] = React.useState({ name: "", desc: "" });
  const [imageScale, setImageScale] = React.useState(null);
  const [templateFeatureID, setTemplateFeatureID] = React.useState("");
  const [modelList, setModelList] = React.useState([]);
  const [correctListOfLists, setCorrectListOfLists] = React.useState({});
  const [items, setItems] = React.useState({});
  const [itemsHistory, setItemsHistory] = React.useState([]);
  const [stepNumber, setStepNumber] = React.useState(0);
  const [selectedItemId, setSelectedItemId] = React.useState([]);
  const [deleteItems, setDeleteItems] = React.useState([]);
  const [deleteItemsDetail, setDeleteItemsDetail] = React.useState({});
  const [image, setImage] = React.useState("");
  const [isAltered, setAltered] = React.useState(false);
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [buttonsHeight, setButtonsHeight] = React.useState(0);
  const [stageScale, setStageScale] = React.useState(1);
  const [resetImage, setResetImage] = React.useState(true);
  const [stageRef, setStageRef] = React.useState(null);

  const initHistory = (items) => {
    items = JSON.parse(JSON.stringify(items));
    setItemsHistory([{ items: items, deleteItems: deleteItems }]);
    setStepNumber(0);
  };

  const saveHistory = (items, deleteItems) => {
    items = JSON.parse(JSON.stringify(items));
    const _itemsHistory = itemsHistory.slice(0, stepNumber + 1);
    setItemsHistory(
      _itemsHistory.concat([{ items: items, deleteItems: deleteItems }])
    );
    setStepNumber(stepNumber + 1);
    console.log(
      "saveHistory",
      _itemsHistory.concat([{ items: items, deleteItems: deleteItems }])
    );
  };

  const loadHistory = (stepNumber) => {
    stepNumber = Math.max(0, stepNumber);
    stepNumber = Math.min(itemsHistory.length - 1, stepNumber);
    const step = JSON.parse(JSON.stringify(itemsHistory[stepNumber]));
    // rectangle以外は最新の情報にする。削除されていた場合は削除時の情報にする。
    for (const key in step.items) {
      if (key in items) {
        items[key].rectangle = step.items[key].rectangle;
        step.items[key] = items[key];
      } else if (key in deleteItemsDetail) {
        step.items[key] = deleteItemsDetail[key];
        delete deleteItemsDetail[key];
      }
    }
    setDeleteItemsDetail(deleteItemsDetail);
    setItems(step.items);
    setDeleteItems(step.deleteItems);
    setStepNumber(stepNumber);
  };

  let search = new URLSearchParams(props.location.search);

  const template_id = search.get("template_id");

  React.useEffect(() => {
    //const _data = { process: "get_template", param: { template_id: template_id }}

    const templateAPI = new TemplateAPI("get_template");
    const templateAPI_param = { param: { template_id: template_id } };
    const getListOfOcrModels = new ManageOcrModelAPI("get_list_of_models");
    const getListOfOcrModelsParam = { param: {} };
    const correctDictGetter = new CorrectByListAPI("get_list_of_lists");
    const correctDictGetParam = { param: {} };

    //api.addParam("template_id", template_id);
    templateAPI.setParam(templateAPI_param);
    getListOfOcrModels.setParam(getListOfOcrModelsParam);

    correctDictGetter.setParam(correctDictGetParam);
    getAccessTokenSilently()
      .then(() => getIdTokenClaims())
      .then((idToken) => {
        Promise.all([
          templateAPI
            .execPostRequest(idToken.__raw)
            .then((response) => response.json()),
          getListOfOcrModels
            .execPostRequest(idToken.__raw)
            .then((response) => response.json()),
          correctDictGetter
            .execPostRequest(idToken.__raw)
            .then((response) => response.json()),
        ]).then(
          ([templateRes, getListOfOcrModelsRes, correctDictGetRes]) => {
            setTemplateFeatureID(templateRes.feature_value_id);
            //setTemplate(res);
            console.log("templateRes", templateRes);
            console.log("getListOfOcrModelsRes", getListOfOcrModelsRes);
            console.log("correctDictGetRes", correctDictGetRes);
            const _modelList = getListOfOcrModelsRes.result.sort(compareName);
            setModelList(_modelList);
            setCorrectListOfLists(
              correctDictGetRes.result.list_of_lists.sort(compareListName)
            );

            let binary_data = templateRes.binary_data;
            let img = binary_data.img;
            delete templateRes.binary_data;
            setOverview({
              name: templateRes.name,
              desc: templateRes.description,
            });
            setImage("data:image;base64," + img);
            // 後に描画した方が手前に来るので大きい方から並べ替え
            const resItems = templateRes.items.sort(compareArea);
            for (let v of resItems) {
              const model_id =
                v.model_id && typeof v.model_id === "string"
                  ? v.model_id
                  : _modelList[0].model_id;
              addItem(
                v.item_id,
                v.no,
                v.name,
                model_id,
                v.rectangle.x, // / imageScale,
                v.rectangle.y, // / imageScale,
                v.rectangle.w, // / imageScale,
                v.rectangle.h, // / imageScale
                "", //ocr result
                v.white_list,
                v.multiple_lines,
                v.list_id,
                v.corrects_address,
                false,
                false
              );
            }
            initHistory(items);
            setPageLoading(false);
            setBackDropping(false);
          },
          (error) => {
            console.log("ERROR", error);
            alert(ErrorMessages.errorOccuered);
            history.push("/template/list");
          }
        );
      });

    // eslint-disable-next-line
  }, [reloadItemsTrigger]);

  const setName = (value: string) => {
    let _overview = overview;
    _overview.name = value;
    setOverview(_overview);
    //template.name = value;
  };

  const setDesc = (value: string) => {
    let _overview = overview;
    _overview.desc = value;
    setOverview(_overview);
    //template.description = value;
  };

  const setResult = (item_id: string, rslt: string) => {
    console.log("setResult");
    console.log(item_id);
    console.log(rslt);
    items[item_id].ocr_rslt = rslt;
  };

  const addItem = (
    id = "",
    no = undefined,
    name = "",
    model_id = modelList[0].model_id,
    x = 0,
    y = 0,
    w = 300,
    h = 300,
    rslt = null,
    white_list = "",
    multiple_lines = false,
    correct_list_id = "",
    corrects_address = false,
    alter = true,
    selectAfterAdd = true
  ) => {
    console.log("addItem");
    if (Object.keys(items).length >= TemplateLimits.MAX_ITEM_NUM) {
      alert("項目数が上限に達しています。他の項目を削除して下さい。");
      return;
    }
    const _new = typeof id === "string" ? false : true;
    const _id = _new ? randomId() : id;
    const _items = items;
    const _no =
      no === undefined
        ? Object.keys(items).length > 0
          ? Math.max.apply(
              Math,
              Object.entries(items).map(([, item]) => {
                return item["no"];
              })
            ) + 1
          : 0
        : no;
    _items[_id] = {
      new: _new,
      item_id: _id,
      no: _no,
      name: _new ? "" : name,
      model_id: model_id,
      rectangle: {
        initialX: x, // * imageScale,
        initialY: y, // * imageScale,
        x: null,
        y: null,
        width: w, //setImgLoading * imageScale,
        height: h, // * imageScale,
        scaleX: 1,
        scaleY: 1,
      },
      ocr_rslt: rslt,
      white_list: white_list,
      multiple_lines: multiple_lines,
      list_id: correct_list_id,
      corrects_address: corrects_address,
    };
    setItems(_items);
    if (alter) {
      setAltered(true);
    }
    if (selectAfterAdd) {
      changeSelectedId(_id);
    }
    if (_new) {
      saveHistory(_items, deleteItems);
    }
  };

  const changeSelectedId = (_id) => {
    if (_id === undefined) {
      setSelectedItemId([]);
    } else {
      setSelectedItemId([_id]);
    }
  };

  const addSelectedItemId = (_id) => {
    setSelectedItemId(selectedItemId.concat([_id]));
  };

  const removeSelectedItemId = (_id) => {
    const newIds = selectedItemId.filter((n) => n !== _id);
    setSelectedItemId(newIds);
  };

  const alterRect = (e: any) => {
    console.log("alterRect", e.target.attrs);
    const alterRectId = Ids.getCommonIdFromRectId(e.target.attrs.id);
    if (items[alterRectId]) {
      const _items = items;
      _items[alterRectId].rectangle.scaleX = e.target.attrs.scaleX;
      _items[alterRectId].rectangle.scaleY = e.target.attrs.scaleY;
      _items[alterRectId].rectangle.x = e.target.attrs.x;
      _items[alterRectId].rectangle.y = e.target.attrs.y;
      _items[alterRectId].rectangle.rotation = e.target.attrs.rotation;
      setItems(_items);
      setAltered(true);
      saveHistory(_items, deleteItems);
    }
  };

  const alterTextInput = (commonId: string, value: string) => {
    console.log("alterTextInput");
    const _items = items;
    for (let _id of selectedItemId) {
      _items[_id].name = value;
    }
    // 新しいオブジェクトをセットすることで再レンダリングが走る
    const newItems = Object.assign({}, _items);
    setItems(newItems);
    setAltered(true);
  };

  const alterModel = (commonId: string, value: string) => {
    console.log("alterModel");
    const _items = items;
    for (let _id of selectedItemId) {
      _items[_id].model_id = value;
    }
    // 新しいオブジェクトをセットすることで再レンダリングが走る
    const newItems = Object.assign({}, _items);
    setItems(newItems);
    setAltered(true);
  };
  const alterCorrectList = (commonId: string, value: string) => {
    console.log("alterCorrectList");
    const _items = items;
    for (let _id of selectedItemId) {
      _items[_id].list_id = value;
    }
    // 新しいオブジェクトをセットすることで再レンダリングが走る
    const newItems = Object.assign({}, _items);
    setItems(newItems);
    setAltered(true);
  };

  const alterMultipleLines = (commonId: string, value: boolean) => {
    const _items = items;
    for (let _id of selectedItemId) {
      _items[_id].multiple_lines = value;
    }
    // 新しいオブジェクトをセットすることで再レンダリングが走る
    const newItems = Object.assign({}, _items);
    setItems(newItems);
    setAltered(true);
  };

  const alterCorrectsAddress = (commonId: string, value: boolean) => {
    const _items = items;
    for (let _id of selectedItemId) {
      _items[_id].corrects_address = value;
    }
    // 新しいオブジェクトをセットすることで再レンダリングが走る
    const newItems = Object.assign({}, _items);
    setItems(newItems);
    setAltered(true);
  };

  const alterWhiteList = (commonId: string, value: string) => {
    console.log("alterWhiteList");
    const _items = items;
    for (let _id of selectedItemId) {
      _items[_id].white_list = value;
    }
    // 新しいオブジェクトをセットすることで再レンダリングが走る
    const newItems = Object.assign({}, _items);
    setItems(newItems);
    setAltered(true);
  };

  const deleteItem = () => {
    console.log(selectedItemId);
    if (selectedItemId.length === 0) {
      alert("選択されていません");
      return;
    }
    let _ids = [];
    for (let _id of selectedItemId) {
      if (!items[_id].new) {
        _ids.push(items[_id].item_id);
      }
      deleteItemsDetail[_id] = items[_id];
      delete items[_id];
    }
    // NOTE: deleteで直接ステート変更できている。けどsetItemsする。
    // ステートを直接変更するのは良くない。
    setDeleteItemsDetail(deleteItemsDetail);
    setItems(items);
    setSelectedItemId([]);
    setDeleteItems(deleteItems.concat(_ids));
    saveHistory(items, deleteItems.concat(_ids));
    setAltered(true);
  };

  const deleteUnselectedItem = () => {
    console.log(selectedItemId);
    let _ids = [];
    Object.keys(items).map((_id) => {
      if (selectedItemId.includes(_id) === false) {
        if (!items[_id].new) {
          _ids.push(items[_id].item_id);
        }
        deleteItemsDetail[_id] = items[_id];
        delete items[_id];
      }
      return null;
    });
    setDeleteItemsDetail(deleteItemsDetail);
    setItems(items);
    setSelectedItemId([]);
    setDeleteItems(deleteItems.concat(_ids));
    saveHistory(items, deleteItems.concat(_ids));
    setAltered(true);
  };

  const selectItem = (id: string) => {
    changeSelectedId(id);
  };

  const register = () => {
    console.log("~~~~~~~~~~~~~~~~ register start ~~~~~~~~~~~~~~~~");
    setBackDropping(true);
    getAccessTokenSilently()
      .then(() => getIdTokenClaims())
      .then((idToken) => {
        const promises = [];
        const newItems = [];
        const updatedItemIDs = [];
        const updatedItems = [];

        // 破壊的コード（itemsが変更される）でnoを振り直し
        let numberedItems = Object.entries(items).sort(
          ([, a], [, b]) => a["no"] - b["no"]
        );
        numberedItems.forEach(([, item], i) => {
          item["no"] = i;
        });

        console.log("template_name: " + overview.name);
        console.log("template_description: " + overview.desc);
        //console.log(imageScale);
        for (let id in items) {
          console.log("item_id: " + items[id].item_id);
          console.log("no: " + items[id].no);
          console.log(
            "x: " +
              String(
                items[id].rectangle.x
                  ? items[id].rectangle.x / imageScale
                  : items[id].rectangle.initialX
              )
          );
          console.log(
            "y: " +
              String(
                items[id].rectangle.y
                  ? items[id].rectangle.y / imageScale
                  : items[id].rectangle.initialY
              )
          );
          console.log("w: " + items[id].rectangle.width);
          console.log("h: " + items[id].rectangle.height);
          console.log("name: " + items[id].name);
          console.log("model_id: " + items[id].model_id);
          console.log("white_list: " + items[id].white_list);

          if (items[id].new) {
            console.log("add_items");
            newItems.push(createItemForTemplateAPI(items[id], imageScale));
            /////////////////////atode
            delete items[id];
            ////////////////////////////////////////////
          } else {
            console.log("update_items");
            updatedItemIDs.push(items[id].item_id);
            updatedItems.push(createItemForTemplateAPI(items[id], imageScale));
          }
        }
        const templateItemsAdder = new TemplateAPI("add_items");
        const templateItemsAddParam = {
          param: {
            template_id: template_id,
            items: newItems,
          },
        };
        templateItemsAdder.setParam(templateItemsAddParam);

        promises.push(
          templateItemsAdder
            .execPostRequest(idToken.__raw)
            .then((response) => response.json())
        );

        const templateItemsUpdater = new TemplateAPI("update_items");
        const templateItemsUpdateParam = {
          param: {
            template_id: template_id,
            item_ids: updatedItemIDs,
            items: updatedItems,
          },
        };
        templateItemsUpdater.setParam(templateItemsUpdateParam);
        promises.push(
          templateItemsUpdater
            .execPostRequest(idToken.__raw)
            .then((response) => response.json())
        );

        const TemplateItemsDeleter = new TemplateAPI("delete_items");
        const TemplateItemsDeleteParam = {
          param: {
            template_id: template_id,
            item_ids: deleteItems,
          },
        };
        TemplateItemsDeleter.setParam(TemplateItemsDeleteParam);
        promises.push(
          TemplateItemsDeleter.execPostRequest(idToken.__raw).then((response) =>
            response.json()
          )
        );

        const templateAPI = new TemplateAPI("update_template");
        const templateAPI_param = {
          param: {
            template_id: template_id,
            name: overview.name,
            description: overview.desc,
          },
        };
        templateAPI.setParam(templateAPI_param);
        promises.push(
          templateAPI
            .execPostRequest(idToken.__raw)
            .then((response) => response.json())
        );

        Promise.all(promises).then(
          (results) => {
            console.log("~~~~~~~~~~~~~~~~ register end ~~~~~~~~~~~~~~~~");
            console.log(results);
            setDeleteItems([]);
            setItems({});
            setAltered(false);
            reloadItems((current_value: boolean) => !current_value);
          },
          (error) => {
            console.log("ERROR", error);
            alert(ErrorMessages.pleaseTryAgain);
            loadHistory(stepNumber);
            setBackDropping(false);
          }
        );
      });
  };

  const handleKeyDown = (e: any) => {
    if (e.target.tagName !== "INPUT" && e.target.tagName !== "TEXTAREA") {
      //Del
      if (e.which === 46) {
        deleteItem();
      }
      //Space
      if (e.which === 32) {
        //TODO initialize image position
      }
    }
  };

  const ocrTrial = (itemID: string) => {
    setBackDropping(true);
    const base64 = image.split(",")[1];

    let ocrItems = {};
    if (itemID !== undefined) {
      // 一つだけOCR
      // format_oprが要求するkeyは項目名だが対応のためitem_idのほうが良い
      ocrItems[itemID] = createItemForOCRAPI(items[itemID], imageScale);
    } else {
      // 全部OCR

      Object.keys(items).map((_id) => {
        ocrItems[_id] = createItemForOCRAPI(items[_id], imageScale);
        return null;
      });
    }
    console.log(ocrItems);

    // call OCR api
    const formatAIOcrAPI = new FormatAIOcrAPI("format_aiocr");
    const formatAIOcrAPI_param = {
      param: {
        template_id: templateFeatureID,
        ocr_items: ocrItems,
      },
      binary_data: { image: base64 },
    };
    console.log("formatAIOCRParam", formatAIOcrAPI_param);
    formatAIOcrAPI.setParam(formatAIOcrAPI_param);

    getAccessTokenSilently()
      .then(() => getIdTokenClaims())
      .then((idToken) => {
        formatAIOcrAPI
          .execPostRequest(idToken.__raw)
          .then((response) => response.json())
          .then(
            (res) => {
              console.log("formatAiOCR then");
              console.log(res);

              Object.keys(res.result.results).map((id, i) => {
                setResult(id, res.result.results[id]);
                return [];
              });
              setBackDropping(false);
            },
            (error) => {
              console.log("ERROR", error);
              alert(ErrorMessages.pleaseTryAgain);
              setBackDropping(false);
            }
          );
      });
  };

  return pageLoading ? (
    <div className={style.body_div} onKeyDown={handleKeyDown} tabIndex={1}>
      <BodyHeader />
      <LinearProgress />
    </div>
  ) : (
    <div className={style.body_div} onKeyDown={handleKeyDown} tabIndex={1}>
      <Prompt
        when={isAltered}
        message="変更を保存していません。変更を破棄して遷移しますか？"
      />
      <Backdrop className={style.backdrop} open={backDropping}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <BodyHeader />
      <TemplateInfoBox
        template_name={overview.name}
        template_description={overview.desc}
        funcSetName={setName}
        funcSetDesc={setDesc}
      />
      <Container className={style.stickyHeader}>
        <Buttons
          stageRef={stageRef}
          imageScale={imageScale}
          resetImage={resetImage}
          setResetImage={setResetImage}
          setButtonsHeight={setButtonsHeight}
          addItem={addItem}
          funcDeleteItem={deleteItem}
          funcDeleteUnselectedItem={deleteUnselectedItem}
          funcRegister={register}
          funcOcrTrial={ocrTrial}
          funcLoadHistory={loadHistory}
          stepNumber={stepNumber}
          stageScale={stageScale}
          setStageScale={setStageScale}
        />
      </Container>
      <Container className={style.paper_container}>
        <ImagePaper
          setStageRef={setStageRef}
          resetImage={resetImage}
          items={items}
          stageScale={stageScale}
          setStageScale={setStageScale}
          addItem={addItem}
          //items={template.items}
          selectedItemId={selectedItemId}
          addSelectedItemId={addSelectedItemId}
          removeSelectedItemId={removeSelectedItemId}
          funcSelectItem={selectItem}
          funcDeleteItem={deleteItem}
          funcAlterRect={alterRect}
          funcSetImageScale={setImageScale}
          imageData={image}
          imageScale={imageScale}
        />
        <ListContainer
          buttonsHeight={buttonsHeight}
          items={items}
          modelList={modelList}
          correctListOfLists={correctListOfLists}
          //items={template.items}
          selectedItemId={selectedItemId}
          addSelectedItemId={addSelectedItemId}
          removeSelectedItemId={removeSelectedItemId}
          funcSelectItem={selectItem}
          funcDeleteItem={deleteItem}
          funcAlterTextInput={alterTextInput}
          funcAlterModel={alterModel}
          funcAlterCorrectList={alterCorrectList}
          funcAlterMultipleLines={alterMultipleLines}
          funcAlterCorrectsAddress={alterCorrectsAddress}
          funcAlterWhiteList={alterWhiteList}
          funcOcrTrial={ocrTrial}
        />
      </Container>
    </div>
  );
}

function BodyHeader() {
  const style = useStyles();
  return (
    <AppBar
      className={style.body_header}
      position="static"
      color="default"
      elevation={0}
    >
      <Toolbar className={style.toolbar}>
        <Typography variant="h6" className={style.title}>
          読取箇所：テンプレート確認・編集
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

function TemplateInfoBox(props) {
  const style = useStyles();

  const setName = (e: any) => {
    props.funcSetName(e.target.value);
  };
  const setDesc = (e: any) => {
    props.funcSetDesc(e.target.value);
  };
  return (
    <React.Fragment>
      <TextField
        className={style.text_name}
        id="nameOfThis"
        label="テンプレート名"
        variant="standard"
        size="small"
        disabled={!getPermissions().edit.template}
        //fullWidth
        defaultValue={props.template_name}
        onChange={setName}
        inputProps={{
          maxLength: TextLimits.MAX_TEXT_LENGTH,
        }}
      />
      <TextField
        className={style.text_description}
        id="descOfThis"
        label="テンプレート説明"
        variant="standard"
        size="small"
        disabled={!getPermissions().edit.template}
        //fullWidth
        defaultValue={props.template_description}
        onChange={setDesc}
        inputProps={{
          maxLength: TextLimits.MAX_TEXT_LENGTH,
        }}
      />
    </React.Fragment>
  );
}
function Buttons(props) {
  const style = useStyles();
  const ref = React.useRef(null);
  React.useEffect(() => {
    props.setButtonsHeight(ref.current.clientHeight);
  }, [props]);
  return (
    <Container ref={ref} className={style.button_container}>
      <Button
        variant="contained"
        disabled={!getPermissions().edit.template}
        onClick={() => {
          const stage = props.stageRef.current.getStage();
          console.log("stage", stage);
          console.log("props", props.imageScale);
          console.log("props", props.stageScale);
          props.addItem(
            null,
            undefined,
            undefined,
            undefined,
            -stage.attrs.x / props.imageScale / props.stageScale,
            -stage.attrs.y / props.imageScale / props.stageScale
          );
        }}
      >
        <img
          alt="add"
          src={addRectIcon}
          className={style.otherImages}
          style={!getPermissions().edit.template ? { opacity: 0.2 } : {}}
        />
        読取枠追加
      </Button>
      <Button
        variant="contained"
        disabled={!getPermissions().edit.template}
        onClick={props.funcDeleteItem}
      >
        <img
          alt="deleteSelected"
          src={deleteSelectedIcon}
          className={style.otherImages}
          style={!getPermissions().edit.template ? { opacity: 0.2 } : {}}
        />
        選択読取枠を削除
      </Button>
      <Button
        variant="contained"
        disabled={!getPermissions().edit.template}
        onClick={props.funcDeleteUnselectedItem}
      >
        <img
          alt="deleteUnselected"
          src={deleteUnselectedIcon}
          className={style.otherImages}
          style={!getPermissions().edit.template ? { opacity: 0.2 } : {}}
        />
        選択していない部分を削除
      </Button>
      <Button
        className={style.undoRedoButton}
        title="もとに戻す"
        variant="contained"
        disabled={!getPermissions().edit.template}
        onClick={() => props.funcLoadHistory(props.stepNumber - 1)}
      >
        <img
          alt="undo"
          src={undoIcon}
          className={style.undoRedoImage}
          style={!getPermissions().edit.template ? { opacity: 0.2 } : {}}
        />
      </Button>
      <Button
        className={style.undoRedoButton}
        title="やりなおし"
        variant="contained"
        disabled={!getPermissions().edit.template}
        onClick={() => props.funcLoadHistory(props.stepNumber + 1)}
      >
        <img
          alt="redo"
          src={redoIcon}
          className={style.undoRedoImage}
          style={!getPermissions().edit.template ? { opacity: 0.2 } : {}}
        />
      </Button>
      <Button
        className={style.undoRedoButton}
        title="拡大"
        variant="contained"
        onClick={() => props.setStageScale(Math.min(props.stageScale + 1, 3))}
      >
        <img alt="undo" src={zoomIn} className={style.zoom} />
      </Button>
      <Button
        className={style.undoRedoButton}
        title="縮小"
        variant="contained"
        onClick={() => {
          // 画像内の左上の点が縮小後も同じ座標になるようにする
          const stage = props.stageRef.current.getStage();
          const currentScale = stage.getScale();
          const newScale = Math.max(props.stageScale - 1, 1);
          const currentPosition = stage.getPosition();
          const scaleRate = newScale / currentScale.x;
          const newPosition = {
            x: currentPosition.x * scaleRate,
            y: currentPosition.y * scaleRate,
          };
          // 縮小した画像の範囲外が見える場合は見えない座標に移動する
          if (-newPosition.x + stage.getWidth() > stage.getWidth() * newScale) {
            newPosition.x = -stage.getWidth() * newScale + stage.getWidth();
          }
          if (
            -newPosition.y + stage.getHeight() >
            stage.getHeight() * newScale
          ) {
            newPosition.y = -stage.getHeight() * newScale + stage.getHeight();
          }
          props.setStageScale(newScale);
          stage.setPosition(newPosition);
        }}
      >
        <img alt="undo" src={zoomOut} className={style.zoom} />
      </Button>
      <Button
        className={style.undoRedoButton}
        title="初期サイズに戻す"
        variant="contained"
        onClick={() => props.setResetImage(!props.resetImage)}
      >
        <img alt="undo" src={zoomInit} className={style.zoom} />
      </Button>
      <Button
        className={style.saveButton}
        variant="contained"
        color="primary"
        disabled={!getPermissions().edit.template}
        onClick={props.funcRegister}
      >
        <img
          alt="save"
          src={saveIcon}
          className={style.saveImage}
          style={!getPermissions().edit.template ? { opacity: 0.5 } : {}}
        />
        保存する
      </Button>
      <Button
        variant="contained"
        className={style.ocrAllButton}
        color="secondary"
        disabled={!getPermissions().execute.ocr}
        title="この設定で帳票全体をOCRしてみる"
        onClick={() => props.funcOcrTrial()}
      >
        <img
          alt="ocr"
          src={ocrIcon}
          className={style.ocrImage}
          style={!getPermissions().execute.ocr ? { opacity: 0.5 } : {}}
        />
        テストOCR
      </Button>
    </Container>
  );
}

function TemplateImage(props) {
  const [image] = useImage(props.imageData);
  React.useEffect(() => {
    if (image) {
      let _scaleX = props.parentWidth / image.naturalWidth;
      let _scaleY = props.parentHeight / image.naturalHeight;
      let _scale = _scaleX < _scaleY ? _scaleX : _scaleY;
      props.funcSetImageScale(_scale);
    }
    // eslint-disable-next-line
  }, [image]);
  return (
    <KonvaImage
      image={image}
      scale={{ x: props.imageScale, y: props.imageScale }}
    />
  );
}

function ItemCard(props) {
  const style = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const card_style = props.isSelected ? style.selected_card : style.card_root;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleCardMouseDown = (e: any) => {
    if (e.ctrlKey) {
      if (props.selectedItemId.includes(props.commonId)) {
        props.funcRemoveSelectedItemId(props.commonId);
      } else {
        props.funcAddSelectedItemId(props.commonId);
      }
    } else if (
      props.selectedItemId.length >= 2 &&
      props.selectedItemId.includes(props.commonId)
    ) {
    } else {
      //props.funcSelectItem(Ids.getCommonIdFromRectId(props.commonId));
      props.funcSelectItem(props.commonId);
    }
  };
  const ocrItemTrialClick = () => {
    console.log("ocrItem", props.commonId);
    props.funcOcrTrial(props.commonId);
  };
  const handleInputTextChange = (e: any) => {
    console.log("change");
    props.funcAlterTextInput(
      Ids.getCommonIdInputId(e.target.id),
      e.target.value
    );
  };

  return (
    <Card
      className={card_style}
      onMouseDown={handleCardMouseDown}
      onFocus={handleCardMouseDown}
      id={Ids.itemCardId(props.commonId)}
    >
      <CardHeader
        className={style.card_header}
        classes={{
          action: style.cardHeaderAction,
          content: style.cardHeaderContent,
        }}
        title={props.no}
        titleTypographyProps={{ variant: "subtitle1" }}
        disablespacing="true"
        action={
          <Container className={style.input_base_root}>
            <Divider className={style.divider} orientation="vertical" />
            <InputBase
              className={style.input}
              placeholder="Item Name"
              disabled={!getPermissions().edit.template}
              id={Ids.inputId(props.commonId)}
              onChange={handleInputTextChange}
              defaultValue={props.item.name}
              value={props.item.name}
              inputProps={{
                maxLength: TextLimits.MAX_TEXT_LENGTH,
              }}
            />
            <Divider className={style.divider} orientation="vertical" />
            <IconButton
              className={clsx(style.card_expand, {
                [style.card_expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
            <Button
              className={style.ocrItemButton}
              variant="contained"
              disabled={!getPermissions().execute.ocr}
              color="secondary"
              onClick={ocrItemTrialClick}
              title="この設定で項目をOCRしてみる"
            >
              テストOCR
            </Button>
          </Container>
        }
      ></CardHeader>
      {props.item.ocr_rslt ? (
        <Chip
          className={style.result_chip}
          label={
            <Typography variant="body2" className={style.typography}>
              {props.item.ocr_rslt.ocr_result}
            </Typography>
          }
          variant="outlined"
          size="small"
          color="primary"
        />
      ) : null}
      {props.item.ocr_rslt && props.item.ocr_rslt.status.code !== 0 ? (
        <Chip
          className={style.result_chip}
          label={
            <Typography variant="body2" className={style.typography}>
              {props.item.ocr_rslt.status.err_reason}
            </Typography>
          }
          variant="outlined"
          size="small"
          color="secondary"
        />
      ) : null}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <InnerExpandContents
            commonId={props.commonId}
            funcAlterModel={props.funcAlterModel}
            funcAlterCorrectList={props.funcAlterCorrectList}
            funcAlterMultipleLines={props.funcAlterMultipleLines}
            funcAlterCorrectsAddress={props.funcAlterCorrectsAddress}
            funcAlterWhiteList={props.funcAlterWhiteList}
            item={props.item}
            modelList={props.modelList}
            correctListOfLists={props.correctListOfLists}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
}

function ImagePaper(props) {
  const style = useStyles();
  const [contextMenuPos, setContextMenuPos] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({
    mouseX: null,
    mouseY: null,
  });

  const targetRef: React.MutableRefObject<HTMLInputElement> = React.useRef();
  const stageRef: React.MutableRefObject<Stage> = React.useRef();
  const setStageRef = props.setStageRef;
  React.useEffect(() => {
    setStageRef(stageRef);
  }, [setStageRef]);
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    const imageForCheck = new Image();
    imageForCheck.onload = () => {
      console.log(
        "imageSize",
        imageForCheck.height + "x" + imageForCheck.width
      );
      setDimensions({
        width: paperImageWidth,
        height: (imageForCheck.height * paperImageWidth) / imageForCheck.width,
      });
    };
    imageForCheck.src = props.imageData;
  }, [props.imageData]);

  const handleStageMouseDown = (e: any) => {
    if (e.target === e.target.getStage()) {
      props.funcSelectItem();
      return;
    }
    const clickedOnTransformer =
      e.target.getParent().className === "Transformer";
    if (clickedOnTransformer) {
      return;
    }

    const _id = e.target.attrs.id;
    if (_id) {
      //if with ctrlKey or right click
      if (
        e.evt.ctrlKey ||
        (e.evt.button !== 0 &&
          props.selectedItemId.includes(Ids.getCommonIdFromRectId(_id)))
      ) {
        if (props.selectedItemId.includes(Ids.getCommonIdFromRectId(_id))) {
          //if left click
          if (e.evt.button === 0) {
            props.removeSelectedItemId(Ids.getCommonIdFromRectId(_id));
          }
        } else {
          props.addSelectedItemId(Ids.getCommonIdFromRectId(_id));
        }
      } else {
        props.funcSelectItem(Ids.getCommonIdFromRectId(_id));
      }
    } else {
      props.funcSelectItem();
    }
  };

  // ズームあとで入れなおすかも
  // eslint-disable-next-line
  const handleMouseWheel = (e: any) => {
    let newScale = props.stageScale - e.evt.deltaY * 0.01;
    if (newScale < 1) {
      newScale = 1;
    } else if (newScale > 2) {
      newScale = 2;
    }
    props.setStageScale(newScale);
  };

  const [render, setRender] = React.useState(false);
  const handleKeyDown = (e: any) => {
    e.preventDefault();
    // Space => 初期位置、初期拡大率
    if (e.which === 32) {
      const stage = stageRef.current.getStage();
      props.setStageScale(1);
      stage.setPosition({ x: 0, y: 0 });
      setRender(!render);
    }
    // edit権限あるユーザのみ
    if (getPermissions().edit.template) {
      // A =>  Add
      if (e.which === 65) {
        const stage = stageRef.current.getStage();
        console.log(stage);
        props.addItem(
          null,
          undefined,
          undefined,
          undefined,
          -stage.attrs.x / props.imageScale / props.stageScale,
          -stage.attrs.y / props.imageScale / props.stageScale
        );
      }
      // D => Delete
      if (e.which === 68) {
        props.funcDeleteItem();
      }
    }
  };

  const handleContextMenuOpen = (e: any) => {
    if (e.target.attrs.id) {
      e.evt.preventDefault();
      setContextMenuPos({
        mouseX: e.evt.clientX - 2,
        mouseY: e.evt.clientY - 4,
      });
    }
  };

  const handleDeleteClickInContextMenu = () => {
    props.funcDeleteItem();
    handleContextMenuClose();
  };

  const handleContextMenuClose = () => {
    setContextMenuPos({
      mouseX: null,
      mouseY: null,
    });
  };

  const setStageScale = props.setStageScale;
  React.useEffect(() => {
    const stage = stageRef.current.getStage();
    setStageScale(1);
    stage.setPosition({ x: 0, y: 0 });
    setRender((render) => !render);
  }, [props.resetImage, setStageScale]);

  return (
    <Container ref={targetRef} className={style.image_paper}>
      <Paper elevation={24} tabIndex={0} onKeyDown={handleKeyDown}>
        <Stage
          width={dimensions.width}
          height={dimensions.height}
          onMouseDown={handleStageMouseDown}
          scale={{ x: props.stageScale, y: props.stageScale }}
          x={0}
          y={0}
          // onWheel={handleMouseWheel}
          onContextMenu={(e) => e.evt.preventDefault()}
          draggable={true}
          ref={stageRef}
          dragBoundFunc={(pos) => {
            // 帳票画像範囲外にはドラッグできないようにする
            if (pos.x > 0) {
              pos.x = 0;
            }
            if (pos.y > 0) {
              pos.y = 0;
            }
            if (
              pos.x - dimensions.width <
              -dimensions.width * props.stageScale
            ) {
              pos.x = dimensions.width - dimensions.width * props.stageScale;
            }
            if (
              pos.y - dimensions.height <
              -dimensions.height * props.stageScale
            ) {
              pos.y = dimensions.height - dimensions.height * props.stageScale;
            }
            return pos;
          }}
        >
          <Layer>
            <TemplateImage
              parentWidth={dimensions.width}
              parentHeight={dimensions.height}
              funcSetImageScale={props.funcSetImageScale}
              imageData={props.imageData}
              imageScale={props.imageScale}
            />
          </Layer>
          <Layer>
            {props.imageScale
              ? Object.keys(props.items).map((id, i) => {
                  return (
                    <RectHandler
                      key={id}
                      commonId={id}
                      rectangle={props.items[id].rectangle}
                      isSelected={props.selectedItemId.includes(id)}
                      funcAlterRect={props.funcAlterRect}
                      imageScale={props.imageScale}
                      handleContextMenuOpen={handleContextMenuOpen}
                      stageRef={stageRef}
                      stageScale={props.stageScale}
                    />
                  );
                })
              : null}
          </Layer>
        </Stage>
        <Menu
          keepMounted
          open={contextMenuPos.mouseY !== null}
          onClose={handleContextMenuClose}
          onContextMenu={(e) => {
            e.preventDefault();
            handleContextMenuClose();
          }}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenuPos.mouseY !== null && contextMenuPos.mouseX !== null
              ? { top: contextMenuPos.mouseY, left: contextMenuPos.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={handleDeleteClickInContextMenu}>Delete</MenuItem>
        </Menu>
      </Paper>
    </Container>
  );
}

function ListContainer(props) {
  const style = useStyles();
  return (
    <Paper
      className={style.card_container}
      style={{
        top: props.buttonsHeight + 30,
      }}
      elevation={24}
    >
      {Object.entries(props.items)
        .sort(([, a], [, b]) => a["no"] - b["no"])
        .map(([id, item], i) => {
          return (
            <ItemCard
              key={id}
              id={Ids.itemCardId(id)}
              commonId={id}
              no={i + 1}
              item={item}
              modelList={props.modelList}
              correctListOfLists={props.correctListOfLists}
              isSelected={props.selectedItemId.includes(id)}
              funcSelectItem={props.funcSelectItem}
              funcDeleteItem={props.funcDeleteItem}
              funcAlterTextInput={props.funcAlterTextInput}
              funcAlterModel={props.funcAlterModel}
              funcAlterCorrectList={props.funcAlterCorrectList}
              funcAlterMultipleLines={props.funcAlterMultipleLines}
              funcAlterCorrectsAddress={props.funcAlterCorrectsAddress}
              funcAlterWhiteList={props.funcAlterWhiteList}
              funcOcrTrial={props.funcOcrTrial}
              selectedItemId={props.selectedItemId}
              funcAddSelectedItemId={props.addSelectedItemId}
              funcRemoveSelectedItemId={props.removeSelectedItemId}
            />
          );
        })}
      {/* {Object.keys(props.items).map((id, i) => {
      return (
        <ItemCard
          key={id}
          id={Ids.itemCardId(id)}
          commonId={id}
          no={i + 1}
          item={props.items[id]}
          modelList={props.modelList}
          correctListOfLists={props.correctListOfLists}
          isSelected={props.selectedItemId.includes(id)}
          funcSelectItem={props.funcSelectItem}
          funcDeleteItem={props.funcDeleteItem}
          funcAlterTextInput={props.funcAlterTextInput}
          funcAlterModel={props.funcAlterModel}
          funcAlterCorrectList={props.funcAlterCorrectList}
          funcAlterMultipleLines={props.funcAlterMultipleLines}
          funcAlterCorrectsAddress={props.funcAlterCorrectsAddress}
          funcAlterWhiteList={props.funcAlterWhiteList}
          funcOcrTrial={props.funcOcrTrial}
          selectedItemId={props.selectedItemId}
          funcAddSelectedItemId={props.addSelectedItemId}
          funcRemoveSelectedItemId={props.removeSelectedItemId}
        />
      );
    })} */}
    </Paper>
  );
}

function InnerExpandContents(props) {
  const style = useStyles();

  const handleChangeModel = (e: any) => {
    console.log("model change");
    props.funcAlterModel(props.commonId, e.target.value);
  };
  const handleChangCorrectList = (e: any) => {
    console.log("correct list change");
    props.funcAlterCorrectList(props.commonId, e.target.value);
  };
  const handleChangedMultipleLines = (e: any) => {
    props.funcAlterMultipleLines(props.commonId, e.target.checked);
  };
  const handleChangedCorrectsAddress = (e: any) => {
    props.funcAlterCorrectsAddress(props.commonId, e.target.checked);
  };
  const handleChangeWhiteList = (e: any) => {
    console.log("allow list change");
    props.funcAlterWhiteList(props.commonId, e.target.value);
  };
  console.log("props.modelList", props.modelList);
  console.log("props.correctListOfLists", props.correctListOfLists);

  return (
    <List className={style.inner_expand} disablePadding>
      <ListItem className={style.innerExpandPadding}>
        <InputLabel className={style.inputLabel}>Model</InputLabel>
        <Select
          id={Ids.modelSelectorId(props.commonId)}
          value={props.item.model_id}
          disabled={!getPermissions().edit.template}
          onChange={handleChangeModel}
        >
          {props.modelList.map((model) => (
            <MenuItem value={model.model_id}>{model.name}</MenuItem>
          ))}
        </Select>
      </ListItem>
      <ListItem className={style.innerExpandPadding}>
        <InputLabel className={style.inputLabel}>辞書選択</InputLabel>
        <Select
          disabled={
            !getPermissions().edit.template || props.item.corrects_address
          }
          native
          id={Ids.correctListSelectorId(props.commonId)}
          value={props.item.list_id}
          onChange={handleChangCorrectList}
        >
          <option value="">なし</option>
          {props.correctListOfLists.map((list) => (
            <option value={list.list_id}>{list.list_name}</option>
          ))}
        </Select>
      </ListItem>
      <ListItem className={style.innerExpandPadding}>
        <FormControlLabel
          control={
            <Checkbox
              className={style.checkbox}
              disabled={!getPermissions().edit.template}
              onChange={handleChangedMultipleLines}
              checked={props.item.multiple_lines}
            />
          }
          label="複数行？"
        />
      </ListItem>
      <ListItem className={style.innerExpandPadding}>
        <FormControlLabel
          control={
            <Checkbox
              className={style.checkbox}
              disabled={!getPermissions().edit.template}
              onChange={handleChangedCorrectsAddress}
              checked={props.item.corrects_address}
            />
          }
          label="住所補正？"
        />
      </ListItem>
      <ListItem className={style.innerExpandPadding}>
        <TextField
          className={style.fill80}
          id="standard-multiline-static"
          label="Allowed characters"
          disabled={!getPermissions().edit.template}
          multiline
          value={props.item.white_list}
          onChange={handleChangeWhiteList}
          rows={1}
          inputProps={{
            maxLength: TextLimits.MAX_ALLOWED_CHARACTER_LENGTH,
          }}
        />
      </ListItem>
    </List>
  );
}

function RectHandler(props) {
  //const style = useStyles();
  //const [isSelected, setisSelected] = React.useState(true);
  const shapeRef = React.useRef();
  const trRef: any = React.useRef();
  React.useEffect(() => {
    if (props.isSelected && trRef && trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [props.isSelected]);

  const notFocused = "#76FFD0";
  const inFocused = "#FF0000";

  const onClick = (e: any) => {
    const rectID: string = e.target.attrs.id;
    console.log(rectID);

    //setisSelected(true);

    const inputArea = document.getElementById(
      Ids.inputId(Ids.getCommonIdFromRectId(rectID))
    );
    if (inputArea !== null) {
      inputArea.scrollIntoView({ block: "nearest" });
    }
  };
  const handleAlterRect = (e: any) => {
    console.log("handleAlterRect");
    props.funcAlterRect(e);
  };

  return (
    <React.Fragment>
      <Rect
        id={Ids.rectId(props.commonId)}
        ref={shapeRef}
        x={props.rectangle.x ?? props.rectangle.initialX * props.imageScale}
        y={props.rectangle.y ?? props.rectangle.initialY * props.imageScale}
        scaleX={props.rectangle.scaleX}
        scaleY={props.rectangle.scaleY}
        width={props.rectangle.width * props.imageScale}
        height={props.rectangle.height * props.imageScale}
        rotation={props.rectangle.rotation}
        fill={props.isSelected ? inFocused : notFocused}
        opacity={0.3}
        //visible={this.props.visible}
        draggable={getPermissions().edit.template}
        dragBoundFunc={(pos) => {
          const stageWidth =
            props.stageRef.current.attrs.width * props.stageScale;
          const stageHeight =
            props.stageRef.current.attrs.height * props.stageScale;
          const itemWidth =
            props.rectangle.width *
            props.imageScale *
            props.rectangle.scaleX *
            props.stageScale;
          const itemHeight =
            props.rectangle.height *
            props.imageScale *
            props.rectangle.scaleY *
            props.stageScale;
          const stageX = props.stageRef.current.attrs.x;
          const stageY = props.stageRef.current.attrs.y;
          // FIXME: この辺クラスにして単純な範囲比較チェックにしたい

          // 画面外に出そうとした時
          let x: number = pos.x;
          let y: number = pos.y;
          if (x - stageX < 1) {
            x = stageX + 1;
          } else if (x - stageX + itemWidth > stageWidth) {
            x = stageWidth + stageX - itemWidth;
          }
          if (y - stageY < 1) {
            y = stageY + 1;
          } else if (y - stageY + itemHeight > stageHeight) {
            y = stageHeight + stageY - itemHeight;
          }

          return { x: x, y: y };
        }}
        onClick={onClick}
        //onTransform={handleTransform}
        //onTransformStart={handleTransform}
        onTransformEnd={handleAlterRect}
        onDragEnd={handleAlterRect}
        stroke="#000000"
        strokeWidth={1}
        strokeScaleEnabled={false}
        //onDblClick={this.onDblClick}
        //onContextMenu={this.contextMenu}
        //onMouseMove={this.onMouseMove}
        //onMouseEnter={this.onMouseEnter}
        //onMouseLeave={this.onMouseLeave}
        onContextMenu={(e) => {
          e.evt.preventDefault();
          props.handleContextMenuOpen(e);
        }}
      ></Rect>
      {props.isSelected && getPermissions().edit.template && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          borderEnabled={true}
          anchorSize={7}
          keepRatio={false}
          ignoreStroke
          boundBoxFunc={(oldBoundBox, newBoundBox) => {
            const stageWidth =
              props.stageRef.current.attrs.width * props.stageScale;
            const stageHeight =
              props.stageRef.current.attrs.height * props.stageScale;
            const stageX = props.stageRef.current.attrs.x;
            const stageY = props.stageRef.current.attrs.y;
            const itemWidth = newBoundBox.width;
            const itemHeight = newBoundBox.height;
            // FIXME: この辺クラスにして単純な範囲比較チェックにしたい

            // 画像外へ引き延ばそうとした時
            let x: number = newBoundBox.x;
            let y: number = newBoundBox.y;
            // はみ出している場合、エッジまでは引き伸ばす
            // 原点側をDragするときは座標が変わるので対応
            if (x - stageX < 0) {
              newBoundBox.x = stageX;
              newBoundBox.width = oldBoundBox.width;
              // 勢いよく引き伸ばすと座標が飛ぶので対応
              if (x - stageX < 0) {
                if (oldBoundBox.x - stageX > 0) {
                  console.log(oldBoundBox.x, stageX);
                  newBoundBox.width += oldBoundBox.x - stageX;
                }
              }
            }
            if (y - stageY < 0) {
              newBoundBox.y = stageY;
              newBoundBox.height = oldBoundBox.height;
              // 勢いよく引き伸ばすと座標が飛ぶので対応
              if (y - stageY < 0) {
                if (oldBoundBox.y - stageY > 0) {
                  console.log(oldBoundBox.y, stageY);
                  newBoundBox.height += oldBoundBox.y - stageY;
                }
              }
            }
            // 原点と反対側をDragするときは座標は変わらないのではみ出す場合は幅のみ対応
            if (x - stageX + itemWidth > stageWidth) {
              newBoundBox.width = stageWidth + stageX - x;
            }
            if (y - stageY + itemHeight > stageHeight) {
              newBoundBox.height = stageHeight + stageY - y;
            }

            // 裏返そうとした時（見た目裏返ったように見えるが反対側が伸びる）
            if (newBoundBox.width < 10 || newBoundBox.height < 10) {
              return oldBoundBox;
            }
            return newBoundBox;
          }}
        />
      )}
    </React.Fragment>
  );
}

import * as React from "react";

import {
  AdjustImageAPI,
  FilenameAndStandardized,
  FindRectAPI,
  ManageOcrModelAPI,
  SimilarImageSearchAPI,
  TemplateAPI,
  TextDetectAPI,
  img2img,
  pdf2img,
  tiff2img,
} from "../../Common/api";
import { Backdrop, Button, Container, TextField } from "@material-ui/core";
import {
  Messages,
  TextLimits,
  checkFileSize,
  checkFileTypes,
  checkResolution,
} from "../../Common/limits";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import { CircularProgress } from "@material-ui/core";
import { ErrorMessages } from "../../Common/error";
import FileInputComponent from "react-file-input-previews-base64";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { TemplateLimits } from "../../Common/limits";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import arithIcon from "../../Images/arith.png";
import { compareName } from "../../Common/sortUtils";
import { getPermissions } from "../../Common/permissions";
import { makeThumbnail } from "../../Common/imageUtils";
import newtemplate from "../../Images/newtemplate.png";
import uploadIcon from "../../Images/upload.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    router_link: {
      margin: "10px",
    },
    body_header: {
      flexGrow: 1,
      zIndex: 1,
      backgroundColor: "rgba(0,0,0,0)",
    },
    toolbar: {
      padding: 0,
      minHeight: 48,
      marginTop: 10,
      marginBottom: 10,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      color: "#002081",
    },
    table_head: {
      backgroundColor: "#EEEEEE",
    },
    table_body: {
      fontSize: "0.7em",
    },
    table_container: {
      maxHeight: 400,
      minWidth: 650,
    },
    table_paper: {
      width: "100%",
    },
    checkbox: {
      width: 30,
      height: 0,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    button_container: {
      margin: "10px",
    },
    arith_comment_container: {
      display: "flex",
      width: 550,
      padding: 0,
    },
    arith_icon: {
      width: 45.75,
      height: 54.75,
    },
    arith_comment: {
      height: 40,
      margin: "auto",
      marginLeft: 10,
      lineHeight: "40px",
      padding: "0 24px",
      backgroundColor: "#EFEFEF",
      borderRadius: "0px 25px 25px 25px",
      boxShadow: "3px 3px lightgrey",
      fontSize: "smaller",
    },
    fileSelectButton: {
      margin: "auto",
      display: "flex",
      width: "35%",
      padding: 10,
      backgroundColor: "#43B9F7",
      "&:hover": {
        backgroundColor: "#0093E3",
      },
    },
    newTemplateImage: {
      width: "34px",
      height: "31px",
      marginRight: "15px",
    },
    uploadButtonImg: {
      width: 20,
      height: "auto",
      marginRight: 10,
    },
    fileInputComponent: {
      backgroundColor: "black",
    },
    similarTmpContainerHeader: {
      backgroundColor: "#07247f",
      height: 50,
      padding: 0,
    },
    similarTmpContainerToolbar: {
      width: "100%",
      padding: 0,
      paddingLeft: 50,
      minHeight: 50,
      margin: "auto",
    },
    similarTmpDescContainer: {
      width: "100%",
      padding: 0,
      display: "flex",
    },
    selectedImgContainer: {
      width: 385,
      height: 385,
      padding: 0,
      marginTop: 15,
      marginBottom: 15,
      background: "#DEF1FF",
      display: "flex",
    },
    selectedImg: {
      maxWidth: "100%",
      maxHeight: "100%",
      margin: "auto",
    },
    similarImgDesc: {
      width: "100%",
    },
    similarImgNotFound: {
      width: "100%",
      color: "red",
    },
    similarImgTitles: {
      width: 280,
      fontSize: 12,
    },
    searchTemplateButton: {
      margin: "auto",
      marginTop: 25,
      marginBottom: 25,
      display: "flex",
      width: "30%",
      padding: 10,
    },
    similarImgPaper: {
      height: 350,
      width: 280,
      display: "flex",
      background: "#EFEFEF",
      "&:hover": {
        background: "#DEF1FF",
      },
    },
    similarImg: {
      maxWidth: "100%",
      maxHeight: "100%",
      margin: "auto",
    },
    similarImgEditButton: {
      display: "flex",
      margin: "15px auto 0px",
      width: "75%",
    },
  })
);

export default function OcrUpload() {
  const style = useStyles();
  const [selected, setSelected] = React.useState<Array<object>>(null);
  const [ready, setReady] = React.useState(false);
  const [backDropping, setBackDropping] = React.useState(false);
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [arrayOfStandardized, setArrayOfStandardized] = React.useState<
    Array<FilenameAndStandardized>
  >([]);
  const [similarTemplates, setSimilarTemplates] = React.useState(null);
  const [similarSearchRes, setSimilarSearchRes] = React.useState(null);

  // 画像選択されたときに類似画像検索までするEffect
  React.useEffect(() => {
    setReady(false);
    if (selected === null) {
      return;
    }
    // 選択されたファイルを一定のフォーマットのbase64に変換し、1つ目のファイルの1枚目と類似の画像検索まで実施する
    const convertFromFileToImageAndSearchSimilarImages = async () => {
      // fileTypeCheck
      let checkedFiles = checkFileTypes(selected);
      if (checkedFiles.ngFileNames.length > 0) {
        alert(
          "非対応ファイルを選択しないでください。\n" +
            checkedFiles.ngFileNames.join("\n")
        );
      }
      // fileSizeCheck
      checkedFiles = checkFileSize(checkedFiles.ok);
      // すべてのファイルを画像変換
      const _arrayOfStandardizedRes = await Promise.all(
        checkedFiles.ok.map(async (file) => {
          const fileBase64 = file["base64"];
          let [type, base64] = fileBase64.split(",");
          const isPdf = type === "data:application/pdf;base64";
          const isTiff = type === "data:image/tiff;base64";
          // ファイルの種類によってstandardize処理を選択
          const convertFromFileToImage = isPdf
            ? pdf2img
            : isTiff
            ? tiff2img
            : img2img;
          // token取得
          await getAccessTokenSilently();
          const idToken = await getIdTokenClaims();
          // call
          const res = await convertFromFileToImage(base64).execPostRequest(
            idToken.__raw
          );
          const standardizeRes: object = await res.json();
          const filename: string = file["name"];
          console.log("standardizeRes", standardizeRes);
          return new FilenameAndStandardized(filename, standardizeRes);
        })
      );
      // 画像サイズチェック
      const checkedArrayOfStandardizedRes = await checkResolution(
        _arrayOfStandardizedRes
      );

      const reason =
        checkedFiles.ngFileNames.length !== 0
          ? "ファイルサイズが対応範囲外です。\n\n" +
            Messages.INVALID_FILESIZE_DESCRIPTION +
            `\n選択されたファイル：${checkedFiles.ngFileReasons[0]}`
          : checkedArrayOfStandardizedRes.ngFileNames.length !== 0
          ? "画素数が対応範囲外です。\n\n" +
            Messages.INVALID_RESOLUTION_DESCRIPTION +
            `\n選択されたファイル：${checkedArrayOfStandardizedRes.ngFileReasons[0]}`
          : "";
      if (checkedArrayOfStandardizedRes.ok.length === 0) {
        alert("非対応のファイルが選択されました。\n" + reason);
        setBackDropping(false);
        return;
      }
      setArrayOfStandardized(checkedArrayOfStandardizedRes.ok);
      // 1ページ目の画像で類似画像検索
      const firstRes: object =
        checkedArrayOfStandardizedRes.ok[0].standardizedRes;
      const firstResBinaryData: Object = firstRes["binary_data"];
      // バイナリのキーが0, 1, 2... or imgなので数字でソートして一番目が1ページ目
      const imageKeys = Object.keys(firstResBinaryData).sort((a, b) => {
        if (Number(a) > Number(b)) return 1;
        else return -1;
      });
      const firstPageBase64 = firstResBinaryData[imageKeys[0]];
      const similarSearchRes = await similarImageSearch(firstPageBase64, 4);
      console.log("similarSearchRes", similarSearchRes);
      // 類似画像検索結果のkeyを用いてテンプレート情報を取得
      const similarTemplates =
        similarSearchRes.ocrapi_status.code !== 54960
          ? await Promise.all(
              similarSearchRes.result.map(async (res) => {
                return await getTemplateImage(res.similar_image_key);
              })
            )
          : [];
      console.log("similarTemplates", similarTemplates);
      setSimilarTemplates(similarTemplates);
      setSimilarSearchRes(similarSearchRes);
      setReady(true);
      setBackDropping(false);
    };

    // 類似画像検索を実施する
    const similarImageSearch = async (base64: string, ret_max: number) => {
      console.log("base64", base64);
      const similarImageSearchAPI = new SimilarImageSearchAPI("search");
      const jsonParam = {
        image_keys: ["image_key"],
        ret_max: ret_max,
      };
      const binaryParam = { image_key: base64 };
      const param = { param: jsonParam, binary_data: binaryParam };
      console.log("similarImageParam", param);
      similarImageSearchAPI.setParam(param);
      // token取得
      await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      // call
      const res = await similarImageSearchAPI.execPostRequest(idToken.__raw);
      const similarImageSearchRes = await res.json();
      console.log("similarImageSearchRes", similarImageSearchRes);
      return similarImageSearchRes;
    };

    // テンプレート情報取得
    const getTemplateImage = async (imageKey: string) => {
      const templateApiParam = {
        param: { template_id: imageKey },
      };
      const templateGetter = new TemplateAPI("get_template");
      // token取得
      await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      // call
      templateGetter.setParam(templateApiParam);
      const res = await templateGetter.execPostRequest(idToken.__raw);
      const getTemplateRes = await res.json();
      console.log("getTemplateRes", getTemplateRes);
      return getTemplateRes;
    };

    setBackDropping(true);
    try {
      convertFromFileToImageAndSearchSimilarImages();
    } catch {
      alert(ErrorMessages.pleaseTryAgain);
      setBackDropping(false);
    }
  }, [selected, setReady, getAccessTokenSilently, getIdTokenClaims]);

  return (
    <React.Fragment>
      <Backdrop className={style.backdrop} open={backDropping}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <BodyHeader />
      <ArithComment />
      <SelectFileBox setSelected={setSelected} />
      {selected ? <SelectedImg selected={selected} /> : null}
      {ready && similarTemplates && selected ? (
        <React.Fragment>
          <SuggestedTemplates
            similarTemplates={similarTemplates}
            similarSearchRes={similarSearchRes}
            arrayOfStandardized={arrayOfStandardized}
            selected={selected}
          />
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}

function BodyHeader() {
  const style = useStyles();
  return (
    <AppBar
      className={style.body_header}
      position="static"
      color="default"
      elevation={0}
    >
      <Toolbar className={style.toolbar}>
        <Typography variant="h6" className={style.title}>
          読取箇所：テンプレート新規登録
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

function ArithComment(props) {
  const style = useStyles();
  return (
    <Container className={style.arith_comment_container}>
      <img alt={"ai-arith"} src={arithIcon} className={style.arith_icon} />
      <p className={style.arith_comment}>
        まずは読み取りたい請求書や書類の画像をファイルから選択してください。
      </p>
    </Container>
  );
}

function SelectFileBox(props) {
  const style = useStyles();
  return (
    <FileInputComponent
      labelText={""}
      //parentStyle={} //スタイル
      imagePreview={false} //ファイルのプレビュー
      multiple={false} //複数ファイル選択
      callbackFunction={(file) => {
        //選択後のコールバック関数
        console.log(file);
        props.setSelected(new Array(file));
      }}
      accept="image/png,image/jpeg,image/tiff,application/pdf" //許可するファイルのtype
      buttonComponent={
        <Button
          color="primary"
          variant="contained"
          className={style.fileSelectButton}
        >
          <img
            alt="upload-icon"
            src={uploadIcon}
            className={style.uploadButtonImg}
          />
          ファイルを選択
        </Button>
      }
    />
  );
}

function SelectedImg(props) {
  const style = useStyles();
  console.log(props);
  return (
    <Container className={style.selectedImgContainer}>
      <img
        alt={"selected-img"}
        src={props.selected[0]["base64"]}
        className={style.selectedImg}
      />
    </Container>
  );
}

function SuggestedTemplates(props) {
  const [templateName, setTemplateName] = React.useState("");
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [backDropping, setBackDropping] = React.useState(false);
  const history = useHistory();

  const style = useStyles();
  return (
    <React.Fragment>
      <Backdrop className={style.backdrop} open={backDropping}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppBar
        style={{ boxShadow: "none" }}
        position="static"
        className={style.similarTmpContainerHeader}
      >
        <Toolbar className={style.similarTmpContainerToolbar}>
          <p>類似画像表示</p>
        </Toolbar>
      </AppBar>
      <Container className={style.similarTmpDescContainer}>
        <p className={style.similarImgDesc}>
          選択した画像に似ているテンプレートを表示しています。選択した画像がテンプレートに登録済みでないか確認して下さい。
          <br />
          未登録または新しい設定で登録したい場合は新しいテンプレートを作成してください。
        </p>
      </Container>
      <Container className={style.similarTmpDescContainer}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            {props.similarTemplates.length > 0 ? (
              props.similarTemplates.map((value, index) => (
                <Grid key={index} item>
                  <Typography className={style.similarImgTitles}>
                    類似順位：
                    {index + 1}位
                  </Typography>
                  <Typography className={style.similarImgTitles}>
                    類似度：
                    {(
                      props.similarSearchRes.result[index].similarity * 100
                    ).toFixed(0)}
                    %
                  </Typography>
                  <div title={value.name}>
                    <Typography noWrap className={style.similarImgTitles}>
                      テンプレート名：{value.name}
                    </Typography>
                  </div>
                  <Typography noWrap className={style.similarImgTitles}>
                    テンプレートID：{value.template_id}
                  </Typography>
                  <Paper className={style.similarImgPaper}>
                    <img
                      className={style.similarImg}
                      alt={String(index)}
                      src={"data:image;base64," + value.binary_data.img}
                    />
                  </Paper>
                  <Button
                    variant="contained"
                    className={style.similarImgEditButton}
                    onClick={() => {
                      console.log(value.template_id);
                      history.push(
                        "/template/edit?template_id=" + value.template_id
                      );
                    }}
                  >
                    編集する
                  </Button>
                </Grid>
              ))
            ) : (
              <Grid key={0} item>
                <p className={style.similarImgNotFound}>
                  登録されているテンプレートはありません。
                </p>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
      <TextField
        id="outlined-basic"
        label="登録テンプレート名"
        size="small"
        fullWidth
        disabled={!getPermissions().create.template}
        onChange={(event) => {
          setTemplateName(event.target.value);
        }}
        inputProps={{
          maxLength: TextLimits.MAX_TEXT_LENGTH,
        }}
      />
      <Button
        variant="contained"
        className={style.searchTemplateButton}
        onClick={() => {
          setBackDropping(true);
          // バイナリのキーが0, 1, 2... or imgなので数字でソートして一番目が1ページ目
          const binaryData =
            props.arrayOfStandardized[0].standardizedRes.binary_data;
          const image_keys = Object.keys(binaryData).sort((a, b) => {
            if (Number(a) > Number(b)) return 1;
            else return -1;
          });
          const firstPage = binaryData[image_keys[0]];
          registerTempalte(
            templateName,
            firstPage,
            getAccessTokenSilently,
            getIdTokenClaims,
            history,
            setBackDropping
          );
        }}
        disabled={
          !templateName ||
          props.selected == null ||
          !getPermissions().create.template
        }
      >
        <img
          src={newtemplate}
          className={style.newTemplateImage}
          alt="newTemplate"
        />
        新しいテンプレートを作成する
      </Button>
    </React.Fragment>
  );
}

const registerTempalte = async (
  templateName,
  base64,
  getAccessTokenSilently,
  getIdTokenClaims,
  history,
  setBackDropping
) => {
  const adjust_image_api = new AdjustImageAPI("register");
  const textDetectAPI = new TextDetectAPI("text_detect_for_bff");
  const findRectAPI = new FindRectAPI("find_rectangles");
  const template_api = new TemplateAPI("create_template");
  const similarImageRegisterAPI = new SimilarImageSearchAPI("register");
  const getListOfOcrModels = new ManageOcrModelAPI("get_list_of_models");

  let adjust_image_api_param = {
    param: { image_key: "image" },
    binary_data: { image: base64 },
  };
  adjust_image_api.setParam(adjust_image_api_param);

  const textdetect_findrect_api_param = {
    param: { image_key: "image" },
    binary_data: { image: base64 },
  };
  textDetectAPI.setParam(textdetect_findrect_api_param);
  findRectAPI.setParam(textdetect_findrect_api_param);

  const getListOfOcrModelsParam = { param: {} };
  getListOfOcrModels.setParam(getListOfOcrModelsParam);

  // 特徴量登録、文字列検出、枠線検出を同時に呼び出してすべてJSON化まで終わるの待つ
  await getAccessTokenSilently();
  const idToken = await getIdTokenClaims();
  const results = await Promise.all([
    adjust_image_api
      .execPostRequest(idToken.__raw)
      .then((response) => response.json()),
    textDetectAPI
      .execPostRequest(idToken.__raw)
      .then((response) => response.json()),
    findRectAPI
      .execPostRequest(idToken.__raw)
      .then((response) => response.json()),
    getListOfOcrModels
      .execPostRequest(idToken.__raw)
      .then((response) => response.json()),
  ]);
  const [
    adjustImageRes,
    textDetectRes,
    findRectRes,
    getListOfOcrModelsRes,
  ] = results;
  console.log("adjustImageRes", adjustImageRes);
  console.log("textDetectRes", textDetectRes);
  console.log("findRectRes", findRectRes);
  console.log("getListOfOcrModelsRes", getListOfOcrModelsRes);
  for (const res of results) {
    try {
      if (res.ocrapi_status.code % 1000 !== 0) {
        throw new Error("code is 0 or no ocrapi_status property");
      }
    } catch {
      alert(ErrorMessages.pleaseTryAgain);
      setBackDropping(false);
      return;
    }
  }

  const defaultModel = getListOfOcrModelsRes.result.sort(compareName)[0]
    .model_id;

  let defaultItems = {};
  let isOverLimitItemNum = false;
  const textAnnotations = textDetectRes.result.textAnnotations;
  for (const rect of textAnnotations) {
    if (Object.keys(defaultItems).length >= TemplateLimits.MAX_ITEM_NUM) {
      isOverLimitItemNum = true;
      break;
    }
    const points = rect.boundingPoly.verticles;
    const boundingRect = points2boundingRect(points);
    // console.log("boundingRect", boundingRect);
    defaultItems = addItem(
      defaultItems,
      undefined,
      undefined,
      undefined,
      defaultModel,
      boundingRect.x,
      boundingRect.y,
      boundingRect.w,
      boundingRect.h
    );
  }
  const rectangles = findRectRes.result.rectangles;
  for (const rect of rectangles) {
    if (Object.keys(defaultItems).length >= TemplateLimits.MAX_ITEM_NUM) {
      isOverLimitItemNum = true;
      break;
    }
    defaultItems = addItem(
      defaultItems,
      undefined,
      undefined,
      undefined,
      defaultModel,
      rect.x,
      rect.y,
      rect.w,
      rect.h
    );
  }
  if (isOverLimitItemNum) {
    alert("項目検出数が上限を超えたため省略されました。");
  }

  // console.log("defaultItems", defaultItems);
  const adjustImageTemplateId = adjustImageRes.result.template_id;
  const thumbnail = await makeThumbnail(base64);
  const template_api_param = {
    param: {
      name: templateName,
      description: "",
      items: Object.values(defaultItems),
      feature_value_id: adjustImageTemplateId,
    },
    binary_data: { img: base64, thumbnail: thumbnail },
  };

  console.log("template_api_param", template_api_param);

  // 帳票登録
  template_api.setParam(template_api_param);

  const response = await template_api.execPostRequest(idToken.__raw);
  const templateApiRes = await response.json();

  console.log("templateApiRes", templateApiRes);
  if (templateApiRes.code === 56200) {
    alert(ErrorMessages.limitOfNumOfTemplates);
    window.location.reload();
    return;
  }
  const id = templateApiRes.template_id;
  // 類似画像検索用登録
  const similarImageRegisterJsonParam = {
    image_keys: [id],
  };
  const similarImageRegisterBinaryParam = {
    [id]: base64,
  };
  const similarImageRegisterParam = {
    param: similarImageRegisterJsonParam,
    binary_data: similarImageRegisterBinaryParam,
  };
  console.log("similarImageRegisterParam", similarImageRegisterParam);
  similarImageRegisterAPI.setParam(similarImageRegisterParam);
  const responseRegister = await similarImageRegisterAPI.execPostRequest(
    idToken.__raw
  );
  const similarImageRegisterRes = await responseRegister.json();
  console.log("similarImageRegisterRes", similarImageRegisterRes);
  history.push("/template/edit?template_id=" + id);
};

const points2boundingRect = (points) => {
  const minX = Math.min.apply(
    Math,
    points.map((o) => {
      return o.x;
    })
  );
  const minY = Math.min.apply(
    Math,
    points.map((o) => {
      return o.y;
    })
  );
  const maxX = Math.max.apply(
    Math,
    points.map((o) => {
      return o.x;
    })
  );
  const maxY = Math.max.apply(
    Math,
    points.map((o) => {
      return o.y;
    })
  );
  return { x: minX, y: minY, w: maxX - minX, h: maxY - minY };
};

function randomId() {
  return Math.random().toString(32).substring(2);
}
const addItem = (
  items,
  id = undefined,
  no = undefined,
  name = "",
  model_id = undefined,
  x = 0,
  y = 0,
  w = 300,
  h = 300,
  rslt = "",
  white_list = ""
) => {
  console.log("addItem");
  const _new = typeof id === "string" ? false : true;
  const _id = _new ? randomId() : id;
  const _items = items;
  const _no = no === undefined ? Object.keys(items).length : no;
  _items[_id] = {
    no: _no,
    name: _new ? "" : name,
    model_id: model_id,
    rectangle: {
      x: x,
      y: y,
      w: w,
      h: h,
    },
    white_list: "",
    multiple_lines: false,
    list_id: "",
    corrects_address: false,
  };
  return _items;
};

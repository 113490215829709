import * as React from "react";

import {
  Button,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Snackbar,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";
import { CorrectByListAPI } from "../../Common/api";
import { ErrorMessages } from "../../Common/error";
import TextField from "@material-ui/core/TextField";
import { TextLimits } from "../../Common/limits";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { getPermissions } from "../../Common/permissions";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main_header: {
      flexGrow: 1,
      zIndex: 999,
    },
    router_link: {
      margin: "10px",
    },
    body_container: {
      maxWidth: "none",
      maxHeight: "none",
    },
    body_header: {
      flexGrow: 1,
      zIndex: 1,
      backgroundColor: "rgba(0,0,0,0)",
    },
    toolbar: {
      minHeight: 48,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    table_head: {
      backgroundColor: "#EEEEEE",
    },
    table_body: {
      fontSize: "0.7em",
    },
    table_container: {
      maxHeight: 400,
      minWidth: 650,
    },
    table_paper: {
      width: "100%",
    },
    checkbox: {
      width: 30,
      height: 0,
    },
    button_container: {
      margin: "5px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    list: {
      margin: 0,
      padding: 0,
      backgroundColor: "#DDDDDD",
    },
    successToast: {
      backgroundColor: "#389e0d",
    },
    errorToast: {
      backgroundColor: "#cf1322",
    },
  })
);

export default function DictionaryEdit(props) {
  let search = new URLSearchParams(props.location.search);
  const dictionaryName = search.get("newDictionary");
  const listID = search.get("list_id");

  return (
    <React.Fragment>
      <BodyHeader />
      <Buttons dictionaryName={dictionaryName} listID={listID} />
    </React.Fragment>
  );
}

function BodyHeader() {
  const style = useStyles();
  return (
    <AppBar
      className={style.body_header}
      position="static"
      color="default"
      elevation={0}
    >
      <Toolbar className={style.toolbar}>
        <Typography variant="subtitle1" className={style.title}>
          辞書新規登録
        </Typography>
        <Link className={style.router_link} to="/dictionary/list">
          辞書一覧へ戻る
        </Link>
      </Toolbar>
    </AppBar>
  );
}

function Buttons(props) {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const history = useHistory();
  const style = useStyles();
  const [dictionaryName, setDictionaryName] = React.useState("");
  const [dictionary, setDictionary] = React.useState([]);
  const [dictionaryText, setDictionaryText] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [toastStyle, setToastStyle] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const [listID, setListID] = React.useState(props.listID);
  console.log("listID", listID);

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const listCreater = new CorrectByListAPI("create_list");
  const listUpdater = new CorrectByListAPI("update_list");
  const saveDictionary = () => {
    setSaving(true);
    console.log("listID", listID);
    const api = listID == null ? listCreater : listUpdater;
    const param =
      listID == null
        ? {
            param: {
              list: { list_name: dictionaryName, list: dictionary },
            },
          }
        : {
            param: {
              list_id: listID,
              list: { list_name: dictionaryName, list: dictionary },
            },
          };
    console.log("createListParam", param);
    api.setParam(param);
    getAccessTokenSilently()
      .then(() => getIdTokenClaims())
      .then((idToken) => {
        api
          .execPostRequest(idToken.__raw)
          .then((response) => response.json())
          .then(
            (createListRes) => {
              console.log("createListRes", createListRes);
              if (createListRes.ocrapi_status.code === 0) {
                setToastStyle(style.successToast);
                setListID(createListRes.result.list_id);
                setMessage("保存成功");
              } else {
                setToastStyle(style.errorToast);
                setMessage(ErrorMessages.pleaseTryAgain);
              }
              setOpen(true);
              setSaving(false);
            },
            (error) => {
              console.log("error", error);
              setSaving(false);
              setToastStyle(style.errorToast);
              setMessage(ErrorMessages.pleaseTryAgain);
              setOpen(true);
              setSaving(false);
            }
          );
      });
  };

  React.useEffect(() => {
    if (listID == null) {
      setDictionaryName(props.dictionaryName);
      return;
    }
    const listGetter = new CorrectByListAPI("get_list");
    listGetter.setParam({ param: { list_id: listID } });
    getAccessTokenSilently()
      .then(() => getIdTokenClaims())
      .then((idToken) => {
        listGetter
          .execPostRequest(idToken.__raw)
          .then((response) => response.json())
          .then((listGetRes) => {
            console.log("listGetRes", listGetRes);
            setDictionaryText(listGetRes.result.list.list.join(" "));
            setDictionary(listGetRes.result.list.list);
            setDictionaryName(listGetRes.result.list.list_name);
          });
      });
    // eslint-disable-next-line
  }, [listID, props.dictionaryName]);

  return (
    <Container className={style.button_container}>
      <TextField
        className={style.button_container}
        id="outlined-basic"
        label="登録辞書名"
        variant="outlined"
        size="small"
        // defaultValue="default value"
        value={dictionaryName}
        fullWidth
        onChange={(event) => {
          setDictionaryName(event.target.value);
        }}
        disabled={!getPermissions().edit.dictionary}
        inputProps={{
          maxLength: TextLimits.MAX_TEXT_LENGTH,
        }}
      />
      <TextField
        className={style.button_container}
        id="outlined-basic"
        label="単語一覧"
        variant="outlined"
        size="small"
        fullWidth
        multiline
        // defaultValue={initDict}
        value={dictionaryText}
        onChange={(event) => {
          const split = event.target.value.split(/\s+/).filter((e) => {
            return e !== "";
          });
          console.log("setDict", split);
          setDictionary(Array.from(new Set(split)));
          setDictionaryText(event.target.value);
        }}
        disabled={!getPermissions().edit.dictionary}
        inputProps={{
          maxLength: TextLimits.MAX_DICT_INPUT_TEXT_LENGTH,
        }}
      />
      <Button
        className={style.button_container}
        variant="contained"
        color="primary"
        disabled={
          !getPermissions().edit.dictionary ||
          !dictionaryName ||
          props.disabled ||
          saving ||
          dictionary.length === 0
        }
        onClick={saveDictionary}
      >
        登録
      </Button>
      <Button
        className={style.button_container}
        variant="contained"
        color="primary"
        disabled={
          !getPermissions().list.dictionary || !dictionaryName || props.disabled
        }
        onClick={() => {
          history.push("/dictionary/list");
        }}
      >
        一覧へ戻る
      </Button>
      <List
        subheader={
          <ListSubheader disableSticky component="div">
            単語一覧(スペースや改行で区切ります)
          </ListSubheader>
        }
      >
        {dictionary.map((word, i) => (
          <ListItem className={style.list}>
            <ListItemText className={style.list} primary={i + 1 + "." + word} />
          </ListItem>
        ))}
      </List>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        ContentProps={{
          classes: {
            root: toastStyle,
          },
        }}
      />
    </Container>
  );
}

export const makeThumbnail = async (base64) => {
  const LONGER = 320;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const image = await loadImage("data:image;base64," + base64);

  let dstWidth, dstHeight;
  if (image.width > image.height) {
    dstWidth = LONGER;
    dstHeight = (image.height * LONGER) / image.width;
  } else {
    dstHeight = LONGER;
    dstWidth = (image.width * LONGER) / image.height;
  }
  canvas.width = dstWidth;
  canvas.height = dstHeight;
  ctx.drawImage(
    image,
    0,
    0,
    image.width,
    image.height,
    0,
    0,
    dstWidth,
    dstHeight
  );
  return canvas.toDataURL().split(",")[1];
};

export const loadImage = (src) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (e) => reject(e);
    img.src = src;
  });
};

export const createCoordinateForOCR = (rectangle) => {
  const x = Math.floor(rectangle.x);
  const y = Math.floor(rectangle.y);
  const w = Math.floor(rectangle.w);
  const h = Math.floor(rectangle.h);
  const coordinate = x + " " + y + " " + w + " " + h;
  return coordinate;
};

export const createItemForTemplateAPI = (item, imageScale) => {
  const tmp = {
    x: Math.max(
      0,
      Math.floor(
        item.rectangle.x
          ? item.rectangle.x / imageScale
          : item.rectangle.initialX
      )
    ),
    y: Math.max(
      0,
      Math.floor(
        item.rectangle.y
          ? item.rectangle.y / imageScale
          : item.rectangle.initialY
      )
    ),
    w: Math.floor(item.rectangle.width * item.rectangle.scaleX),
    h: Math.floor(item.rectangle.height * item.rectangle.scaleY),
  };
  // 枠が裏返ってるときの補正
  let type: number;
  if (item.rectangle.rotation === 0 || item.rectangle.rotation === undefined) {
    if (tmp.h > 0) {
      type = 0; // 普通
    } else {
      type = 1; // 上下が裏返っている
    }
  } else {
    if (tmp.h < 0) {
      type = 2; // 左右が裏返っている
    } else {
      type = 3; // 上下左右が裏返っている
    }
  }
  return {
    no: item.no,
    name: item.name,
    rectangle: {
      x: type === 2 || type === 3 ? tmp.x - tmp.w : tmp.x,
      y: type === 1 ? tmp.y + tmp.h : type === 3 ? tmp.y - tmp.h : tmp.y,
      w: Math.abs(tmp.w),
      h: Math.abs(tmp.h),
    },
    white_list: item.white_list,
    model_id: item.model_id,
    multiple_lines: item.multiple_lines,
    list_id: item.list_id,
    corrects_address: item.corrects_address,
  };
};

export const createItemForOCRAPIFromTemplateAPI = (itemForTemplate) => {
  let postprocess_ocr_item = [];
  if (itemForTemplate.corrects_address) {
    postprocess_ocr_item.push({
      redis_server_env_name: "REDIS_SERVER_URL_CORRECT_ADDRESS",
      queue_env_name: "QUEUE_CORRECT_ADDRESS",
      process: "correct_address",
      param: {},
    });
  } else if (itemForTemplate.list_id !== "") {
    postprocess_ocr_item.push({
      redis_server_env_name: "REDIS_SERVER_URL_CORRECT_BY_LIST",
      queue_env_name: "QUEUE_CORRECT_BY_LIST",
      process: "correct_by_list",
      param: {
        list_id: itemForTemplate.list_id,
      },
    });
  }

  const result = {
    coordinate: createCoordinateForOCR(itemForTemplate.rectangle),
    model_id: itemForTemplate.model_id,
    white_list: itemForTemplate.white_list,
    multiple_lines: itemForTemplate.multiple_lines,
  };
  if (postprocess_ocr_item.length !== 0) {
    result["postprocess_ocr_item"] = postprocess_ocr_item;
  }

  return result;
};

export const createItemForOCRAPI = (item, imageScale) => {
  const itemForTemplate = createItemForTemplateAPI(item, imageScale);
  return createItemForOCRAPIFromTemplateAPI(itemForTemplate);
};

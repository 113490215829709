import { ErrorMessages } from "./error";
import { makeThumbnail } from "./imageUtils";
class API {
  url: string = process.env.REACT_APP_API_URL;
  path: string;
  param: object = {};

  isTokenRequired = true;
  //token: string = "";

  setParam = (_param) => {
    this.param = _param;
  };

  execPostRequest = (token = undefined) => {
    let _headers = {};
    _headers["Content-Type"] = "application/json; charset=utf-8";
    if (this.isTokenRequired) {
      console.log("token:" + token);
      _headers["Authorization"] = `Bearer ${token}`;
    }

    return fetch(this.url + "/" + this.path, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, same-origin, *omit
      headers: _headers,
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(this.param), // 本文のデータ型は "Content-Type" ヘッダーと一致する必要があります
    });
  };

  execGetRequest = (token = undefined) => {
    let _headers = {};
    _headers["Content-Type"] = "application/json; charset=utf-8";
    if (this.isTokenRequired) {
      _headers["Authorization"] = `Bearer ${token}`;
    }

    return fetch(this.url + "/" + this.path, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, same-origin, *omit
      headers: _headers,
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      //body: JSON.stringify(this.param), // 本文のデータ型は "Content-Type" ヘッダーと一致する必要があります
    });
  };
}

export class FormatAIOcrAPI extends API {
  main_path: string = "format_aiocr";

  constructor(_sub_path: string) {
    super();
    this.path = this.main_path + "/" + _sub_path;
  }
}

export class TemplateAPI extends API {
  main_path: string = "templates";

  constructor(_sub_path: string) {
    super();
    this.path = this.main_path + "/" + _sub_path;
    this.param = { param: {} };
    this.isTokenRequired = true;
    //this.token = auth0Client.idToken;
  }
}

export class AdjustImageAPI extends API {
  main_path: string = "adjust_image_to_template";

  constructor(_sub_path: string) {
    super();
    this.path = this.main_path + "/" + _sub_path;
  }
}

export class OcrResultAPI extends API {
  main_path: string = "ocr_result";

  constructor(_sub_path: string) {
    super();
    this.path = this.main_path + "/" + _sub_path;
    this.param = { param: {} };
    this.isTokenRequired = true;
    //this.token = auth0Client.idToken;
  }
}

export class TextDetectAPI extends API {
  main_path: string = "textdetector";

  constructor(_sub_path: string) {
    super();
    this.path = this.main_path + "/" + _sub_path;
  }
}

export class FindRectAPI extends API {
  main_path: string = "findrectangles";

  constructor(_sub_path: string) {
    super();
    this.path = this.main_path + "/" + _sub_path;
  }
}

export class FirebaseCustomTokenAPI extends API {
  main_path: string = "firebase";

  constructor(token: string) {
    super();
    this.path = this.main_path;
    this.isTokenRequired = true;
    //this.token = token;
  }
}

export class SimilarImageSearchAPI extends API {
  main_path: string = "similar_image_search";

  constructor(_sub_path: string) {
    super();
    this.path = this.main_path + "/" + _sub_path;
  }
}

export class ManageOcrModelAPI extends API {
  main_path: string = "manage_ocr_model";

  constructor(_sub_path: string) {
    super();
    this.path = this.main_path + "/" + _sub_path;
  }
}

export class StandardizeImageAPI extends API {
  main_path: string = "standardize_image";

  constructor(_sub_path: string) {
    super();
    this.path = this.main_path + "/" + _sub_path;
  }
}

const standardizeimageAPI = new StandardizeImageAPI("standardize_image");
export const img2img = (base64: string) => {
  const standardizeimageJsonParam = {};
  const standardizeimageBinaryParam = { img: base64 };
  const standardizeimageParam = {
    param: standardizeimageJsonParam,
    binary_data: standardizeimageBinaryParam,
  };
  standardizeimageAPI.setParam(standardizeimageParam);
  return standardizeimageAPI;
};

const pdf2imageAPI = new StandardizeImageAPI("pdf2image");
export const pdf2img = (base64: string) => {
  const pdf2imgJsonParam = {};
  const pdf2imgBinaryParam = { pdf: base64 };
  const pdf2imgParam = {
    param: pdf2imgJsonParam,
    binary_data: pdf2imgBinaryParam,
  };
  pdf2imageAPI.setParam(pdf2imgParam);
  return pdf2imageAPI;
};

const tiff2imageAPI = new StandardizeImageAPI("tiff2images");
export const tiff2img = (base64: string) => {
  const tiff2imgJsonParam = {};
  const tiff2imgBinaryParam = { img: base64 };
  const tiff2imgParam = {
    param: tiff2imgJsonParam,
    binary_data: tiff2imgBinaryParam,
  };
  tiff2imageAPI.setParam(tiff2imgParam);
  return tiff2imageAPI;
};

export class CorrectByListAPI extends API {
  main_path: string = "correct_by_list";

  constructor(_sub_path: string) {
    super();
    this.path = this.main_path + "/" + _sub_path;
  }
}

export class OcrReceptionAPI extends API {
  main_path: string = "ocr_reception";

  constructor(_sub_path: string) {
    super();
    this.path = this.main_path + "/" + _sub_path;
  }
}

export class FilenameAndStandardized {
  constructor(readonly filename: string, readonly standardizedRes: object) {}
}

const requestAPI = new OcrReceptionAPI("request_ocr");

export const requestOCR = async (
  templateID: string,
  arrayOfstandardizeRes: Array<FilenameAndStandardized>,
  getAccessTokenSilently,
  getIdTokenClaims
) => {
  // token
  await getAccessTokenSilently();
  const idToken = await getIdTokenClaims();
  // set parameter
  const result = await Promise.all(
    arrayOfstandardizeRes.map(
      async (filenameAndStandardized: FilenameAndStandardized) => {
        const binary_data =
          filenameAndStandardized.standardizedRes["binary_data"];
        // バイナリのキーが0, 1, 2... or imgなので数字でソートして一番目が1ページ目
        const image_keys = Object.keys(binary_data).sort((a, b) => {
          if (Number(a) > Number(b)) return 1;
          else return -1;
        });
        // thumbnail追加
        const firstPage = binary_data[image_keys[0]];
        binary_data["thumbnail"] = await makeThumbnail(firstPage);
        const requestParam = {
          param: {
            name: filenameAndStandardized.filename,
            template_id: templateID,
            thumbnail_key: "thumbnail",
            image_keys: image_keys,
          },
          binary_data: binary_data,
        };
        console.log("requestParam", requestParam);
        requestAPI.setParam(requestParam);
        // call
        const res = await requestAPI.execPostRequest(idToken.__raw);
        const requestOcrRes = await res.json();
        console.log("requestOcrRes", requestOcrRes);
        return requestOcrRes;
      }
    )
  );
  console.log("OCR requests result", result);
  for (const res of result) {
    const ocrapi_status = res.ocrapi_status;
    if (ocrapi_status.code === 57200) {
      alert(ErrorMessages.limitOfNumOfOcrResult);
      window.location.reload();
      return;
    } else if (ocrapi_status.code !== 0) {
      alert(ErrorMessages.pleaseTryAgain);
      window.location.reload();
      return;
    }
  }
  alert("OCRを受け付けました");
  window.location.reload();
};

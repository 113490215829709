export class ErrorMessages {
  static readonly errorOccuered = "エラーが発生しました。";
  static readonly pleaseTryAgain =
    "エラーが発生しました。もう一度実行してください。";
  static readonly invalidFileSizeOver = "ファイルサイズ制限を超えています";
  static readonly invalidImageSizeOver = "画像サイズが異常です";
  static readonly invalidImageSizeUnderLimit =
    "画像サイズが制限を下回っているため使用できません";
  static readonly warningImageSizeOver =
    "画像サイズが制限を超えているためアップロード時にリサイズされます";
  static readonly limitOfNumOfOcrResult =
    "OCR結果登録数が上限に達しました。\n新規登録するには既存の結果を削除して下さい。";
  static readonly limitOfNumOfTemplates =
    "テンプレート登録数が上限に達しました。\n新規登録するには既存のテンプレートを削除して下さい。";
}

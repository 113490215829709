import * as React from "react";

import { Backdrop, Button, Container } from "@material-ui/core";
import {
  FilenameAndStandardized,
  SimilarImageSearchAPI,
  TemplateAPI,
  img2img,
  pdf2img,
  requestOCR,
  tiff2img,
} from "../../Common/api";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  checkFileSize,
  checkFileTypes,
  checkResolution,
} from "../../Common/limits";

import AppBar from "@material-ui/core/AppBar";
import { CircularProgress } from "@material-ui/core";
import { ErrorMessages } from "../../Common/error";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FileInputComponent from "react-file-input-previews-base64";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import SelectedFilesList from "./ocr-common";
import TemplateList from "../../Pages/Template/template-list";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import arithIcon from "../../Images/arith.png";
import { getPermissions } from "../../Common/permissions";
import uploadIcon from "../../Images/upload.png";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    router_link: {
      margin: "10px",
    },
    body_header: {
      flexGrow: 1,
      zIndex: 1,
      backgroundColor: "rgba(0,0,0,0)",
    },
    toolbar: {
      padding: 0,
      minHeight: 48,
      marginTop: 10,
      marginBottom: 10,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      color: "#663399",
    },
    table_head: {
      backgroundColor: "#EEEEEE",
    },
    table_body: {
      fontSize: "0.7em",
    },
    table_container: {
      maxHeight: 400,
      minWidth: 650,
    },
    table_paper: {
      width: "100%",
    },
    checkbox: {
      width: 30,
      height: 0,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    button_container: {
      margin: "10px",
    },
    arith_comment_container: {
      display: "flex",
      width: 450,
      padding: 0,
    },
    arith_icon: {
      width: 45.75,
      height: 54.75,
    },
    arith_comment: {
      height: 40,
      margin: "auto",
      marginLeft: 10,
      lineHeight: "40px",
      padding: "0 24px",
      backgroundColor: "#EFEFEF",
      borderRadius: "0px 25px 25px 25px",
      boxShadow: "3px 3px lightgrey",
      fontSize: "smaller",
    },
    fileSelectButton: {
      margin: "auto",
      display: "flex",
      width: "35%",
      padding: 10,
      backgroundColor: "#43B9F7",
      "&:hover": {
        backgroundColor: "#0093E3",
      },
    },
    uploadButtonImg: {
      width: 20,
      height: "auto",
      marginRight: 10,
    },
    fileInputComponent: {
      backgroundColor: "black",
    },
    similarTmpContainerHeader: {
      backgroundColor: "#663399",
      height: 50,
      padding: 0,
    },
    similarTmpContainerToolbar: {
      width: "100%",
      padding: 0,
      paddingLeft: 50,
      minHeight: 50,
      margin: "auto",
    },
    similarTmpDescContainer: {
      width: "100%",
      padding: 0,
      display: "flex",
    },
    selectedImgContainer: {
      width: 385,
      height: 385,
      padding: 0,
      marginTop: 15,
      marginBottom: 15,
      background: "rgba(102,51,153,0.1)",
      display: "flex",
    },
    selectedImg: {
      maxWidth: "100%",
      maxHeight: "100%",
      margin: "auto",
    },
    similarImgDesc: {
      width: "100%",
    },
    similarImgNotFound: {
      width: "100%",
      color: "red",
    },
    similarImgTitles: {
      width: 280,
    },
    searchTemplateButton: {
      margin: "auto",
      marginTop: 60,
      marginBottom: 25,
      display: "flex",
      width: "30%",
      padding: 10,
    },
    similarImgPaper: {
      height: 350,
      width: 280,
      display: "flex",
      background: "#EFEFEF",
      "&:hover": {
        background: "rgba(102,51,153,0.1)",
      },
    },
    similarImg: {
      maxWidth: "100%",
      maxHeight: "100%",
      margin: "auto",
    },
    similarImgOcrButton: {
      display: "flex",
      margin: "15px auto 0px",
      width: "75%",
      backgroundColor: "#43B9F7",
      "&:hover": {
        backgroundColor: "#0093E3",
      },
    },
  })
);

export default function OcrUpload() {
  const style = useStyles();
  const [selected, setSelected] = React.useState<Array<object>>(null);
  const [ready, setReady] = React.useState(false);
  const [backDropping, setBackDropping] = React.useState(false);
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [arrayOfStandardized, setArrayOfStandardized] = React.useState<
    Array<FilenameAndStandardized>
  >([]);
  const [similarTemplates, setSimilarTemplates] = React.useState(null);
  const [invalidSizeFileNames, setInvalidSizeFileNames] = React.useState([]);
  const [invalidSizeReasons, setInvalidSizeReasons] = React.useState([]);
  const [
    invalidResolutionFileNames,
    setInvalidResolutionFileNames,
  ] = React.useState([]);
  const [
    invalidResolutionReasons,
    setInvalidResolutionReasons,
  ] = React.useState([]);
  const [validFileNames, setValidFileNames] = React.useState([]);

  // 画像選択されたときに類似画像検索までするEffect
  React.useEffect(() => {
    setReady(false);
    setInvalidSizeFileNames([]);
    setInvalidResolutionFileNames([]);
    setValidFileNames([]);
    if (selected === null) {
      return;
    }
    // 選択されたファイルを一定のフォーマットのbase64に変換し、1つ目のファイルの1枚目と類似の画像検索まで実施する
    const convertFromFileToImageAndSearchSimilarImages = async () => {
      // checkFiletTypes
      let checkedFiles = checkFileTypes(selected);
      if (checkedFiles.ngFileNames.length > 0) {
        alert(
          "非対応ファイルを選択しないでください。\n" +
            checkedFiles.ngFileNames.join("\n")
        );
      }
      // fileSizeCheck
      checkedFiles = checkFileSize(checkedFiles.ok);
      // すべてのファイルを画像変換
      const _arrayOfStandardizedRes = await Promise.all(
        checkedFiles.ok.map(async (file) => {
          const fileBase64 = file["base64"];
          let [type, base64] = fileBase64.split(",");
          const isPdf = type === "data:application/pdf;base64";
          const isTiff = type === "data:image/tiff;base64";
          // ファイルの種類によってstandardize処理を選択
          const convertFromFileToImage = isPdf
            ? pdf2img
            : isTiff
            ? tiff2img
            : img2img;
          // token取得
          await getAccessTokenSilently();
          const idToken = await getIdTokenClaims();
          // call
          const res = await convertFromFileToImage(base64).execPostRequest(
            idToken.__raw
          );
          const standardizeRes: object = await res.json();
          const filename: string = file["name"];
          console.log("standardizeRes", standardizeRes);
          return new FilenameAndStandardized(filename, standardizeRes);
        })
      );

      // 画像サイズチェック
      const checkedArrayOfStandardizedRes = await checkResolution(
        _arrayOfStandardizedRes
      );

      setInvalidSizeFileNames(checkedFiles.ngFileNames);
      setInvalidSizeReasons(checkedFiles.ngFileReasons);
      setInvalidResolutionFileNames(checkedArrayOfStandardizedRes.ngFileNames);
      setInvalidResolutionReasons(checkedArrayOfStandardizedRes.ngFileReasons);
      setValidFileNames(checkedArrayOfStandardizedRes.okFileNames);
      if (checkedArrayOfStandardizedRes.ok.length === 0) {
        alert("非対応のファイルが選択されました。");
        setBackDropping(false);
        return;
      }
      setArrayOfStandardized(checkedArrayOfStandardizedRes.ok);
      // 1ページ目の画像で類似画像検索
      const firstRes: object =
        checkedArrayOfStandardizedRes.ok[0].standardizedRes;
      const firstResBinaryData: Object = firstRes["binary_data"];
      // バイナリのキーが0, 1, 2... or imgなので数字でソートして一番目が1ページ目
      const imageKeys = Object.keys(firstResBinaryData).sort((a, b) => {
        if (Number(a) > Number(b)) return 1;
        else return -1;
      });
      const firstPageBase64 = firstResBinaryData[imageKeys[0]];
      const similarSearchRes = await similarImageSearch(firstPageBase64, 4);
      console.log("similarSearchRes", similarSearchRes);
      // 類似画像検索結果のkeyを用いてテンプレート情報を取得
      const similarTemplates =
        similarSearchRes.ocrapi_status.code !== 54960
          ? await Promise.all(
              similarSearchRes.result.map(async (res) => {
                return await getTemplateImage(res.similar_image_key);
              })
            )
          : [];
      console.log("similarTemplates", similarTemplates);
      setSimilarTemplates(similarTemplates);
      setReady(true);
      setBackDropping(false);
    };

    // 類似画像検索を実施する
    const similarImageSearch = async (base64: string, ret_max: number) => {
      console.log("base64", base64);
      const similarImageSearchAPI = new SimilarImageSearchAPI("search");
      const jsonParam = {
        image_keys: ["image_key"],
        ret_max: ret_max,
      };
      const binaryParam = { image_key: base64 };
      const param = { param: jsonParam, binary_data: binaryParam };
      console.log("similarImageParam", param);
      similarImageSearchAPI.setParam(param);
      // token取得
      await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      // call
      const res = await similarImageSearchAPI.execPostRequest(idToken.__raw);
      const similarImageSearchRes = await res.json();
      console.log("similarImageSearchRes", similarImageSearchRes);
      return similarImageSearchRes;
    };

    // テンプレート情報取得
    const getTemplateImage = async (imageKey: string) => {
      const templateApiParam = {
        param: { template_id: imageKey },
      };
      const templateGetter = new TemplateAPI("get_template");
      // token取得
      await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      // call
      templateGetter.setParam(templateApiParam);
      const res = await templateGetter.execPostRequest(idToken.__raw);
      const getTemplateRes = await res.json();
      console.log("getTemplateRes", getTemplateRes);
      return getTemplateRes;
    };

    setBackDropping(true);
    try {
      convertFromFileToImageAndSearchSimilarImages();
    } catch {
      alert(ErrorMessages.pleaseTryAgain);
      setBackDropping(false);
    }
  }, [selected, setReady, getAccessTokenSilently, getIdTokenClaims]);

  return (
    <React.Fragment>
      <Backdrop className={style.backdrop} open={backDropping}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <BodyHeader />
      <ArithComment />
      <SelectFileBox setSelected={setSelected} />
      {selected ? <SelectedImg selected={selected} /> : null}
      {selected ? (
        <SelectedFilesList
          invalidResolutionFileNames={invalidResolutionFileNames}
          invalidResolutionReasons={invalidResolutionReasons}
          invalidSizeFileNames={invalidSizeFileNames}
          invalidSizeReasons={invalidSizeReasons}
          validFileNames={validFileNames}
        />
      ) : null}
      {ready && similarTemplates && selected ? (
        <React.Fragment>
          <SuggestedTemplates
            setBackDropping={setBackDropping}
            similarTemplates={similarTemplates}
            arrayOfStandardized={arrayOfStandardized}
            selected={selected}
          />
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}

function BodyHeader() {
  const style = useStyles();
  return (
    <AppBar
      className={style.body_header}
      position="static"
      color="default"
      elevation={0}
    >
      <Toolbar className={style.toolbar}>
        <Typography variant="h6" className={style.title}>
          OCR：新規OCR実行
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

function ArithComment(props) {
  const style = useStyles();
  return (
    <Container className={style.arith_comment_container}>
      <img alt={"ai-arith"} src={arithIcon} className={style.arith_icon} />
      <p className={style.arith_comment}>
        ファイルを選択すると類似度が高いテンプレートが表示されます
      </p>
    </Container>
  );
}

function SelectFileBox(props) {
  const style = useStyles();
  return (
    <FileInputComponent
      labelText={""}
      //parentStyle={} //スタイル
      imagePreview={false} //ファイルのプレビュー
      multiple={true} //複数ファイル選択
      callbackFunction={(files: Array<any>) => {
        //選択後のコールバック関数
        console.log(files);
        props.setSelected(files);
      }}
      accept="image/png,image/jpeg,image/tiff,application/pdf" //許可するファイルのtype
      buttonComponent={
        <Button
          color="primary"
          variant="contained"
          className={style.fileSelectButton}
        >
          <img
            alt="upload-icon"
            src={uploadIcon}
            className={style.uploadButtonImg}
          />
          ファイルを選択
        </Button>
      }
    />
  );
}

function SelectedImg(props) {
  const style = useStyles();
  console.log(props);
  return (
    <Container className={style.selectedImgContainer}>
      <img
        alt={"selected-img"}
        src={props.selected[0]["base64"]}
        className={style.selectedImg}
      />
    </Container>
  );
}

function SuggestedTemplates(props) {
  const [expanded, setExpanded] = React.useState(false);
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  const style = useStyles();
  return (
    <React.Fragment>
      <AppBar
        style={{ boxShadow: "none" }}
        position="static"
        className={style.similarTmpContainerHeader}
      >
        <Toolbar className={style.similarTmpContainerToolbar}>
          <p>類似画像表示</p>
        </Toolbar>
      </AppBar>
      <Container className={style.similarTmpDescContainer}>
        <p className={style.similarImgDesc}>
          選択した画像に似ているテンプレートを表示しています。使用するテンプレートを選択してください。
          <br />
          使用したいテンプレートが表示されていない場合は下のボタン「その他のテンプレートファイルを探す」から検索してください。
        </p>
      </Container>
      <Container className={style.similarTmpDescContainer}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            {props.similarTemplates.length > 0 ? (
              props.similarTemplates.map((value, index) => (
                <Grid key={index} item>
                  <div title={value.name}>
                    <Typography noWrap className={style.similarImgTitles}>
                      テンプレート名：{value.name}
                    </Typography>
                  </div>
                  <Paper className={style.similarImgPaper}>
                    <img
                      className={style.similarImg}
                      alt={String(index)}
                      src={"data:image;base64," + value.binary_data.img}
                    />
                  </Paper>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!getPermissions().execute.ocr}
                    className={style.similarImgOcrButton}
                    onClick={() => {
                      console.log(value.template_id);
                      console.log(props.arrayOfStandardized);
                      props.setBackDropping(true);
                      requestOCR(
                        value.template_id,
                        props.arrayOfStandardized,
                        getAccessTokenSilently,
                        getIdTokenClaims
                      );
                    }}
                  >
                    OCRをする
                  </Button>
                </Grid>
              ))
            ) : (
              <Grid key={0} item>
                <p className={style.similarImgNotFound}>
                  登録されているテンプレートはありません。
                </p>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
      <MoreButton setExpanded={setExpanded} />
      {expanded === true ? (
        <TemplateList
          isFromOcrUpload={true}
          requestOCR={requestOCR}
          standardized={props.arrayOfStandardized}
        />
      ) : null}
    </React.Fragment>
  );
}

function MoreButton(props) {
  const style = useStyles();
  return (
    <Button
      variant="contained"
      disabled={!getPermissions().list.template}
      className={style.searchTemplateButton}
      onClick={() => props.setExpanded(true)}
    >
      その他のテンプレートファイルを探す
      <ExpandMoreIcon />
    </Button>
  );
}

import { loadImage } from "./imageUtils";

export class InputLimits {
  static readonly MAX_FILE_SIZE = 20 * 1024 * 1024;
  static readonly MAX_BASE64_SIZE = 30 * 1024 * 1024;
  static readonly MAX_IMAGE_SIZE = 5000 * 7000;
  static readonly MIN_IMAGE_SIZE = 1920 * 1080;
  static readonly MIN_IMAGE_EDGE = 800;
}
export class Messages {
  static readonly INVALID_FILESIZE_DESCRIPTION =
    "ファイルサイズ異常：20MiBを超えるファイル";
  static readonly INVALID_RESOLUTION_DESCRIPTION =
    "画素数異常：1920×1080(=2,073,600)画素以下のファイル、一辺が800画素以下のファイル、または1辺1200pts以上のpdf";
}
export class TemplateLimits {
  static readonly MAX_ITEM_NUM = 400;
}
export class TextLimits {
  static readonly MAX_TEXT_LENGTH = 128;
  static readonly MAX_OCR_TEXT_LENGTH = 512;
  static readonly MAX_DICT_INPUT_TEXT_LENGTH = 4096;
  static readonly MAX_ALLOWED_CHARACTER_LENGTH = 4096;
}

class CheckedFiles {
  constructor(
    readonly ok: Array<any>,
    readonly okFileNames: Array<string>,
    readonly ng: Array<any>,
    readonly ngFileNames: Array<string>,
    readonly ngFileReasons: Array<string>
  ) {}
}

export const checkFileTypes = (files): CheckedFiles => {
  const allowFileTypes = [
    "data:image/png;base64",
    "data:image/jpeg;base64",
    "data:image/tiff;base64",
    "data:application/pdf;base64",
  ];
  return files.reduce((acum, file) => {
    if (allowFileTypes.includes(file.base64.split(",")[0])) {
      acum.ok.push(file);
      acum.okFileNames.push(file.name);
    } else {
      acum.ng.push(file);
      acum.ngFileNames.push(file.name);
      acum.ngFileReasons.push(file.base64.split(",")[0]);
    }
    return acum;
  }, new CheckedFiles([], [], [], [], []));
};

export const checkFileSize = (files): CheckedFiles => {
  return files.reduce((acum, file) => {
    if (file.file.size <= InputLimits.MAX_FILE_SIZE) {
      acum.ok.push(file);
      acum.okFileNames.push(file.name);
    } else {
      acum.ng.push(file);
      acum.ngFileNames.push(file.name);
      acum.ngFileReasons.push(
        (file.file.size / 1024 / 1024).toFixed(1) + "MiB"
      );
    }
    return acum;
  }, new CheckedFiles([], [], [], [], []));
};

export const checkResolution = async (
  arrayOfStandardizedRes
): Promise<CheckedFiles> => {
  const filteredInpuImages = await arrayOfStandardizedRes.reduce(
    async (pacum, value) => {
      const acum = await pacum;
      const images = [];
      // pdfチェック
      if (value.standardizedRes.ocrapi_status.code === 11200) {
        acum.ng.push(value);
        acum.ngFileNames.push(value.filename);
        acum.ngFileReasons.push(value.standardizedRes.ocrapi_status.err_reason);
        return acum;
      }
      // 画像のサイズをチェック
      for (const k of Object.keys(value.standardizedRes.binary_data)) {
        const base64 = value.standardizedRes.binary_data[k];
        const image = await loadImage("data:image;base64," + base64);
        images.push(image);
        // 最大画素数を満たしていなければ異常ファイルに追加
        if (image.height * image.width > InputLimits.MAX_IMAGE_SIZE) {
          acum.ng.push(value);
          acum.ngFileNames.push(value.filename);
          acum.ngFileReasons.push(
            `${image.height} x ${image.width} = ${(
              image.height * image.width
            ).toLocaleString()}px`
          );
          return acum;
        }
        // 最小画素数を満たしていなければ異常ファイルに追加
        if (
          image.height * image.width < InputLimits.MIN_IMAGE_SIZE ||
          Math.min(image.height, image.width) < InputLimits.MIN_IMAGE_EDGE
        ) {
          acum.ng.push(value);
          acum.ngFileNames.push(value.filename);
          acum.ngFileReasons.push(
            `${image.height} x ${image.width} = ${(
              image.height * image.width
            ).toLocaleString()}px`
          );
          return acum;
        }
      }
      // 全ページがOKなら正常ファイルに追加
      acum.ok.push(value);
      acum.okFileNames.push(value.filename);
      return acum;
    },
    Promise.resolve(new CheckedFiles([], [], [], [], []))
  );
  return filteredInpuImages;
};

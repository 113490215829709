import * as React from "react";

import { Button, CircularProgress, Container } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Backdrop from "@material-ui/core/Backdrop";
import { ErrorMessages } from "../../Common/error";
import FileInputComponent from "react-file-input-previews-base64";
import { Link } from "react-router-dom";
import { ManageOcrModelAPI } from "../../Common/api";
import TextField from "@material-ui/core/TextField";
import { TextLimits } from "../../Common/limits";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main_header: {
      flexGrow: 1,
      zIndex: 999,
    },
    router_link: {
      margin: "10px",
    },
    body_container: {
      maxWidth: "none",
      maxHeight: "none",
    },
    body_header: {
      flexGrow: 1,
      zIndex: 1,
      backgroundColor: "rgba(0,0,0,0)",
    },
    toolbar: {
      minHeight: 48,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    table_head: {
      backgroundColor: "#EEEEEE",
    },
    table_body: {
      fontSize: "0.7em",
    },
    table_container: {
      maxHeight: 400,
      minWidth: 650,
    },
    table_paper: {
      width: "100%",
    },
    checkbox: {
      width: 30,
      height: 0,
    },
    button_container: {
      margin: "10px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

export default function ModelUpload() {
  const style = useStyles({});
  const [modelFile, setModelFile] = React.useState(null);
  const [labelsFile, setLabelsFile] = React.useState(null);
  const [backDropping, setBackDropping] = React.useState(false);

  return (
    <React.Fragment>
      <BodyHeader />
      <Backdrop className={style.backdrop} open={backDropping}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <SelectFileBox
        setModelFile={setModelFile}
        setLabelsFile={setLabelsFile}
        setBackDropping={setBackDropping}
      />
      <Buttons modelFile={modelFile} labelsFile={labelsFile} />
    </React.Fragment>
  );
}

function BodyHeader() {
  const style = useStyles();
  return (
    <AppBar
      className={style.body_header}
      position="static"
      color="default"
      elevation={0}
    >
      <Toolbar className={style.toolbar}>
        <Typography variant="subtitle1" className={style.title}>
          帳票定義 - モデル新規登録
        </Typography>
        <Link className={style.router_link} to="/model/list">
          モデル一覧へ戻る
        </Link>
      </Toolbar>
    </AppBar>
  );
}

function SelectFileBox(props) {
  const style = useStyles();
  return (
    <>
      <FileInputComponent
        labelText="モデルファイル"
        parentStyle={style} //スタイル
        imagePreview={true} //ファイルのプレビュー
        multiple={false} //複数ファイル選択
        callbackFunction={(file) => {
          //選択後のコールバック関数
          console.log("FileInputComponent", file);
          console.log("base64size", file.base64.length);
          // over maximum file size
          props.setModelFile(file.base64);
        }}
        accept="*" //許可するファイルのtype
        buttonComponent={<button>ファイルを選択</button>}
      />
      <FileInputComponent
        labelText="ラベルファイル"
        parentStyle={style} //スタイル
        imagePreview={true} //ファイルのプレビュー
        multiple={false} //複数ファイル選択
        callbackFunction={(file) => {
          //選択後のコールバック関数
          console.log("FileInputComponent", file);
          console.log("base64size", file.base64.length);
          // over maximum file size
          props.setLabelsFile(file.base64);
        }}
        accept="*" //許可するファイルのtype
        buttonComponent={<button>ファイルを選択</button>}
      />
    </>
  );
}

function Buttons(props) {
  const style = useStyles();
  const [uploading, setUploading] = React.useState(false);
  const [modelName, setModelName] = React.useState("");
  const [modelDescription, setModelDescription] = React.useState("");

  let toBeRendered;
  if (uploading) {
    toBeRendered = (
      <Uploading
        modelFile={props.modelFile}
        labelsFile={props.labelsFile}
        modelName={modelName}
        modelDescription={modelDescription}
        setUploading={setUploading}
      />
    );
  } else {
    toBeRendered = (
      <Container className={style.button_container}>
        <TextField
          id="outlined-basic"
          label="モデル名"
          variant="outlined"
          size="small"
          fullWidth
          onChange={(event) => {
            setModelName(event.target.value);
          }}
          inputProps={{
            maxLength: TextLimits.MAX_TEXT_LENGTH,
          }}
        />
        <TextField
          id="outlined-basic"
          label="説明"
          variant="outlined"
          size="small"
          fullWidth
          onChange={(event) => {
            setModelDescription(event.target.value);
          }}
          inputProps={{
            maxLength: TextLimits.MAX_TEXT_LENGTH,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={!modelName || props.modelFile == null}
          onClick={() => {
            setUploading(true);
          }}
        >
          登録
        </Button>
        <Link className={style.router_link} to="/model/list">
          キャンセル
        </Link>
      </Container>
    );
  }
  return toBeRendered;
}

function Uploading(props) {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  React.useEffect(() => {
    const OcrModelUploader = new ManageOcrModelAPI("upload_model");
    const OcrModelUploadJsonParam = {
      name: props.modelName,
      description: props.modelDescription,
    };
    const OcrModelUploadBinaryParam = {
      model: props.modelFile.split(",")[1],
      labels: props.labelsFile.split(",")[1],
    };
    const OcrModelUploadParam = {
      param: OcrModelUploadJsonParam,
      binary_data: OcrModelUploadBinaryParam,
    };
    OcrModelUploader.setParam(OcrModelUploadParam);

    getAccessTokenSilently()
      .then(() => getIdTokenClaims())
      .then((idToken) => {
        Promise.all([
          OcrModelUploader.execPostRequest(idToken.__raw).then((response) =>
            response.json()
          ),
        ]).then(
          (results) => {
            const [getListOfOcrModelsRes] = results;
            console.log("getListOfOcrModelsRes", getListOfOcrModelsRes);
            for (const res of results) {
              try {
                if (res.ocrapi_status.code !== 0) {
                  throw new Error("code is 0 or no ocrapi_status property");
                }
              } catch {
                alert(ErrorMessages.pleaseTryAgain);
                return;
              }
            }
            props.setUploading(false);
          },
          (error) => {
            console.log("ERROR", error);
          }
        );
      });
    // eslint-disable-next-line
  }, []);
  return <CircularProgress />;
}

import jwt_decode from "jwt-decode";

const jwt_key: string = "http://ch-tg/claims/permissions";

const permissions = {
  add: {
    member: false,
  },
  change: {
    role: false,
  },
  create: {
    template: false,
    ocr_result: false,
    dictionary: false,
    role: false,
  },
  delete: {
    template: false,
    ocr_result: false,
    dictionary: false,
    member: false,
  },
  edit: {
    template: false,
    ocr_result: false,
    dictionary: false,
  },
  execute: {
    ocr: false,
  },
  f_del: {
    template: false,
    ocr_result: false,
    dictionary: false,
  },
  list: {
    template: false,
    ocr_result: false,
    dictionary: false,
  },
  read: {
    template: false,
    ocr_result: false,
    dictionary: false,
    member: false,
  },
};

export function setPermissions(jwt_token: string) {
  const _decoded = jwt_decode(jwt_token);
  const _permissions_list: [string] = _decoded[jwt_key];
  _permissions_list.map((permission_string) => {
    const permission_set = permission_string.split(":");
    permissions[permission_set[0]][permission_set[1]] = true;
    return 0;
  });

  //console.log(permissions);

  return;
}

export function getPermissions() {
  return permissions;
}

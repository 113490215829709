import * as React from "react";

import { CardContent, Chip } from "@material-ui/core";
import { Image as KonvaImage, Layer, Rect, Stage } from "react-konva";
import { Prompt, useHistory } from "react-router-dom";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
import { OcrResultAPI } from "../../Common/api";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { TextLimits } from "../../Common/limits";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { compareArea } from "../../Common/sortUtils";
import { getPermissions } from "../../Common/permissions";
// icons
import saveIcon from "../../Images/save.png";
import { useAuth0 } from "@auth0/auth0-react";
import useImage from "use-image";

const paperImageWidth = 750;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body_div: {
      outline: "none",
      margin: "auto",
    },
    stickyHeader: {
      padding: 0,
      paddingBottom: "10px",
      zIndex: 1,
      position: "sticky",
      top: 10,
    },
    body_header: {
      flexGrow: 1,
      zIndex: 1,
      backgroundColor: "rgba(0,0,0,0)",
    },
    toolbar: {
      padding: 0,
      minHeight: 48,
      marginTop: 10,
      marginBottom: 10,
    },
    title: {
      flexGrow: 1,
      color: "#002081",
    },
    paper_container: {
      padding: 0,
      marginTop: "10px",
      marginBottom: "10px",

      display: "flex",
    },
    image_paper: {
      padding: 0,
      width: paperImageWidth,
    },
    image_container: {
      maxHeight: "100%",
      maxWidth: "100%",
    },
    card_container: {
      marginLeft: "10px",
      paddingRight: 0,
      width: "450px",
      maxHeight: "calc(100vh - 126px)",
      overflow: "auto",
      position: "sticky",
    },
    card_root: {
      maxWidth: "100%",
      margin: "1px",
      border: "solid 1px gray",
    },
    selected_card: {
      maxWidth: "100%",
      margin: "1px",
      border: "solid 3px indianred",
    },
    cardHeader: {
      padding: "10px",
      paddingBottom: 0,
    },
    cardContent: {
      padding: "10px",
      "&:last-child": {
        paddingBottom: "10px",
      },
    },
    card_expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    card_expandOpen: {
      transform: "rotate(180deg)",
    },
    button_container: {
      marginTop: "10px",
      marginBottom: "10px",
      display: "flex",
      padding: 0,
      justifyContent: "space-between",
      "& > *": { paddingLeft: "20px", paddingRight: "20px" },
    },
    input_base_root: {
      padding: "3px",
      display: "grid",
      alignItems: "center",
      width: "100%",
    },
    input: {
      flex: 1,
      marginBottom: "8px",
    },
    result_chip: {
      width: "80%",
      height: "100%",
    },
    typography: { whiteSpace: "normal" },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    router_link: {
      margin: "10px",
    },
    inner_expand: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    },
    text_name: {
      width: "30%",
      marginRight: "5%",
    },
    text_description: {
      width: "65%",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    pageNavigator: {
      paddingLeft: 0,
      "& > *": { marginRight: "10px", backgroundColor: "white" },
    },
    disable_link: {
      pointerEvents: "none",
    },
    saveButton: {
      backgroundColor: "#36D0AA",
      "&:hover": {
        backgroundColor: "#00AF78",
      },
    },
    saveImage: {
      width: "22px",
      height: "23px",
      marginRight: "15px",
    },
  })
);

class Ids {
  static itemCardId(id: string): string {
    return "ItemCardId_" + id;
  }
  static rectId(id: string): string {
    return "RectId_" + id;
  }
  static modelSelectorId(id: string): string {
    return "ModelSelectorId_" + id;
  }
  static inputId(id: string): string {
    return "InputId_" + id;
  }
  static getCommonIdFromItemCartId = (id: string): string => {
    return id.substr(12);
  };
  static getCommonIdFromRectId = (id: string): string => {
    return id.substr(7);
  };
  static getCommonIdFromModelSelectorId = (id: string): string => {
    return id.substr(16);
  };
  static getCommonIdInputId = (id: string): string => {
    return id.substr(8);
  };
}

interface OcrItem {
  item_id: string | null;
  no: number;
  name: string;
  rectangle: {
    x: number;
    y: number;
    w: number;
    h: number;
    modified_x: number;
    modified_y: number;
  };
  adjust_list: string;
  pre_proccess: string;
  post_proccess: string;
  model: string;
}

//interface TemplateData {
//  name: string;
//  description: string;
//  image: string;
//  items: OcrItem[];
//}
function randomId() {
  return Math.random().toString(32).substring(2);
}

export default function OcrEdit(props) {
  const [isAltered, setAltered] = React.useState(false);
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const style = useStyles({});
  //const [template, setTemplate] = React.useState<TemplateData>();
  const [backDropping, setBackDropping] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [sending, setSending] = React.useState(false);
  const [overview, setOverview] = React.useState({
    name: "",
    desc: "",
    template_id: "",
  });
  const [imageScale, setImageScale] = React.useState(null);
  const [items, setItems] = React.useState({});
  const [selectedItemId, setSelectedItemId] = React.useState(null);
  //const [nextItemId, setNextItemId] = React.useState(0);
  const [deleteItems, setDeleteItems] = React.useState([]);
  const [image, setImage] = React.useState("");
  const [resultPageIDs, setResultPageIDs] = React.useState([]);
  const [buttonsHeight, setButtonsHeight] = React.useState(0);

  //const [templateData, SetTemplateData] = React.useState(null)

  let search = new URLSearchParams(props.location.search);
  const ocr_result_binder_id = search.get("ocr_binder_id");
  const page = search.get("page") ? parseInt(search.get("page")) : 0;

  React.useEffect(() => {
    setBackDropping(true);
    const getOcrBinder = new OcrResultAPI("get_ocr_result_binder");
    const getOcrPageResult = new OcrResultAPI("get_ocr_result");
    const getOcrBinderParam = {
      param: {
        ocr_result_binder_id: ocr_result_binder_id,
        gets_page_items: false,
      },
    };
    getOcrBinder.setParam(getOcrBinderParam);
    getAccessTokenSilently()
      .then(() => getIdTokenClaims())
      .then((idToken) => {
        getOcrBinder
          .execPostRequest(idToken.__raw)
          .then((response) => response.json())
          .then((binderRes) => {
            console.log("binderRes", binderRes);
            console.log("hello");
            setOverview({
              name: binderRes.name,
              desc: binderRes.description ?? "",
              template_id: binderRes.template_id,
            });
            const ocrResultID = binderRes.result_pages[page];
            setResultPageIDs(binderRes.result_pages);
            const getOcrPageResultParam = {
              param: {
                ocr_result_id: ocrResultID,
              },
            };
            console.log("getOcrPageResultParam", getOcrPageResultParam);
            getOcrPageResult.setParam(getOcrPageResultParam);
            getAccessTokenSilently()
              .then(() => getIdTokenClaims())
              .then((idToken) => {
                getOcrPageResult
                  .execPostRequest(idToken.__raw)
                  .then((response) => response.json())
                  .then(
                    (res) => {
                      //setTemplate(res);
                      console.log("get ocr then");
                      console.log(res);
                      let binary_data = res.binary_data;
                      let img = binary_data.img;
                      delete res.binary_data;
                      setImage("data:image/png;base64," + img);
                      const resItems = res.items.sort(compareArea);
                      for (let v of resItems) {
                        addItem(
                          v.item_id,
                          v.no,
                          v.name,
                          v.result,
                          //0,
                          v.rectangle.x, // / imageScale,
                          v.rectangle.y, // / imageScale,
                          v.rectangle.w, // / imageScale,
                          v.rectangle.h // / imageScale
                        );
                      }
                      // 別オブジェクトにする
                      setItems((items) => JSON.parse(JSON.stringify(items)));
                      setLoading(false);
                      setBackDropping(false);
                      setAltered(false);
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
              });
          });
      });
    // eslint-disable-next-line
  }, [getAccessTokenSilently, getIdTokenClaims, ocr_result_binder_id, page]);

  const setName = (value: string) => {
    let _overview = overview;
    _overview.name = value;
    setOverview(JSON.parse(JSON.stringify(_overview)));
    setAltered(true);
  };

  const setDesc = (value: string) => {
    let _overview = overview;
    _overview.desc = value;
    setOverview(JSON.parse(JSON.stringify(_overview)));
    setAltered(true);
  };

  const addItem = (
    id = "",
    no = 0,
    name = "",
    result = "",
    x = 0,
    y = 0,
    w = 300,
    h = 300
  ) => {
    console.log("addItem");
    const _new = typeof id === "string" ? false : true;
    const _id = _new ? randomId() : id;
    const _items = items;
    _items[_id] = {
      new: _new,
      item_id: _id,
      no: no,
      name: _new ? "" : name,
      result: result,
      rectangle: {
        initialX: x, // * imageScale,
        initialY: y, // * imageScale,
        x: null,
        y: null,
        width: w, //setImgLoading * imageScale,
        height: h, // * imageScale,
        scaleX: 1,
        scaleY: 1,
      },
    };
    setItems(_items);
    // setSelectedItemId(_id);
    //setNextItemId(no + 1);
  };

  const alterRect = (e: any) => {
    console.log("alterRect");
    const alterRectId = Ids.getCommonIdFromRectId(e.target.attrs.id);
    const _items = items;
    _items[alterRectId].rectangle.scaleX = e.target.attrs.scaleX;
    _items[alterRectId].rectangle.scaleY = e.target.attrs.scaleY;
    _items[alterRectId].rectangle.x = e.target.attrs.x;
    _items[alterRectId].rectangle.y = e.target.attrs.y;
    setItems(_items);
  };

  const alterTextInput = (commonId: string, value: string) => {
    console.log("alterTextInput");
    const _items = items;
    _items[commonId].result.ocr_result = value;
    setItems(JSON.parse(JSON.stringify(_items)));
    setAltered(true);
  };
  const alterModel = (commonId: string, value: string) => {
    console.log("alterModel");
    const _items = items;
    _items[commonId].model = value;
    setItems(_items);
  };

  const deleteItem = () => {
    let _items = items;
    if (!_items[selectedItemId].new) {
      setDeleteItems(deleteItems.concat([_items[selectedItemId].item_id]));
    }
    delete _items[selectedItemId];
    setItems(_items);
    setSelectedItemId(-1);
  };

  const selectItem = (id: string) => {
    setSelectedItemId(id);
  };

  const register = () => {
    console.log("~~~~~~~~~~~~~~~~ register start ~~~~~~~~~~~~~~~~");
    setSending(true);
    console.log("ocr_result_name: " + overview.name);
    console.log("ocr_result_description: " + overview.desc);
    console.log("ocr_result_template_id: " + overview.template_id);
    //console.log(imageScale);

    const itemIds = [];
    const updateItems = [];
    for (let id in items) {
      console.log("item_id: " + items[id].item_id);
      console.log("no: " + items[id].no);
      console.log(
        "x: " +
          String(
            items[id].rectangle.x
              ? items[id].rectangle.x / imageScale
              : items[id].rectangle.initialX
          )
      );
      console.log(
        "y: " +
          String(
            items[id].rectangle.y
              ? items[id].rectangle.y / imageScale
              : items[id].rectangle.initialY
          )
      );
      console.log("w: " + items[id].rectangle.width);
      console.log("h: " + items[id].rectangle.height);
      console.log("name: " + items[id].name);
      console.log("result: " + items[id].result);
      itemIds.push(id);
      updateItems.push({
        result: items[id].result,
      });
    }
    console.log("update_items");
    const itemsUpdater = new OcrResultAPI("update_items");
    const itemsUpdaterParam = {
      param: {
        ocr_result_binder_id: ocr_result_binder_id,
        ocr_result_id: resultPageIDs[page],
        item_ids: itemIds,
        items: updateItems,
      },
    };
    console.log("updateItemParam", itemsUpdaterParam);
    itemsUpdater.setParam(itemsUpdaterParam);
    getAccessTokenSilently()
      .then(() => getIdTokenClaims())
      .then((idToken) => {
        itemsUpdater
          .execPostRequest(idToken.__raw)
          .then((response) => response.json())
          .then(
            (res) => {
              console.log("updateItemRes", res);
            },
            (error) => {
              console.log(error);
            }
          );
      });

    //for (let item_id of deleteItems) {
    //  let api = new TemplateAPI("delete_item");
    //  api.addParam("template_id", template_id);
    //  api.addParam("item_id", item_id);
    //  api
    //    .execPostRequest()
    //    .then((response) => response.json())
    //    .then(
    //      (res) => {},
    //      (error) => {
    //        console.log(error);
    //      }
    //    );
    //}

    let ocr_result_api = new OcrResultAPI("update_ocr_result_binder");
    let ocr_result_api_param = {
      param: {
        ocr_result_binder_id: ocr_result_binder_id,
        name: overview.name,
        description: overview.desc,
      },
    };
    console.log("ocr_result_api_param", ocr_result_api_param);
    ocr_result_api.setParam(ocr_result_api_param);
    getAccessTokenSilently()
      .then(() => getIdTokenClaims())
      .then((idToken) => {
        ocr_result_api
          .execPostRequest(idToken.__raw)
          .then((response) => response.json())
          .then(
            (res) => {
              console.log("updateRes", res);
              setSending(false);
              setAltered(false);
            },
            (error) => {
              console.log(error);
            }
          );
      });

    console.log("~~~~~~~~~~~~~~~~ register end ~~~~~~~~~~~~~~~~");
  };

  return loading || sending ? (
    <div className={style.body_div} tabIndex={1}>
      <BodyHeader />
      <LinearProgress />
    </div>
  ) : (
    <div className={style.body_div} tabIndex={1}>
      <Prompt
        when={isAltered}
        message="変更を保存していません。変更を破棄して遷移しますか？"
      />
      <Backdrop className={style.backdrop} open={backDropping}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <BodyHeader />
      <OcrResultInfoBox
        ocr_result_name={overview.name}
        ocr_result_description={overview.desc}
        overview={overview}
        setName={setName}
        setDesc={setDesc}
      />
      <Container className={style.stickyHeader}>
        <Buttons
          overview={overview}
          setButtonsHeight={setButtonsHeight}
          funcAddItem={addItem}
          funcDeleteItem={deleteItem}
          funcRegister={register}
          page={page}
          resultPageIDs={resultPageIDs}
          ocr_result_binder_id={ocr_result_binder_id}
        />
      </Container>
      <Container className={style.paper_container}>
        <ImagePaper
          items={items}
          //items={template.items}
          selectedItemId={selectedItemId}
          funcSelectItem={selectItem}
          funcDeleteItem={deleteItem}
          funcAlterRect={alterRect}
          funcSetImageScale={setImageScale}
          imageData={image}
          imageScale={imageScale}
        />
        <ListContainer
          buttonsHeight={buttonsHeight}
          items={items}
          //items={template.items}
          selectedItemId={selectedItemId}
          funcSelectItem={selectItem}
          funcDeleteItem={deleteItem}
          funcAlterTextInput={alterTextInput}
          funcAlterModel={alterModel}
        />
      </Container>
    </div>
  );
}

function BodyHeader() {
  const style = useStyles();
  return (
    <AppBar
      className={style.body_header}
      position="static"
      color="default"
      elevation={0}
    >
      <Toolbar className={style.toolbar}>
        <Typography variant="h6" className={style.title}>
          OCR - OCR結果確認・編集
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

function OcrResultInfoBox(props) {
  const style = useStyles();
  return (
    <React.Fragment>
      <TextField
        className={style.text_name}
        id="nameOfThis"
        label="OCR結果名"
        variant="standard"
        size="small"
        value={props.overview.name}
        defaultValue={props.ocr_result_name}
        disabled={!getPermissions().edit.ocr_result}
        onChange={(e) => props.setName(e.target.value)}
        inputProps={{
          maxLength: TextLimits.MAX_TEXT_LENGTH,
        }}
      />
      <TextField
        className={style.text_description}
        id="descOfThis"
        label="OCR結果説明"
        variant="standard"
        size="small"
        value={props.overview.desc}
        defaultValue={props.ocr_result_description}
        disabled={!getPermissions().edit.ocr_result}
        onChange={(e) => props.setDesc(e.target.value)}
        inputProps={{
          maxLength: TextLimits.MAX_TEXT_LENGTH,
        }}
      />
    </React.Fragment>
  );
}
function Buttons(props) {
  const style = useStyles();
  const history = useHistory();
  const page = props.page;
  const pageCount = props.resultPageIDs.length;
  const hasPreviousPage = props.page > 0;
  const hasNextPage = page < pageCount - 1;
  const ref = React.useRef(null);
  React.useEffect(() => {
    props.setButtonsHeight(ref.current.clientHeight);
  }, [props]);
  return (
    <Container ref={ref} className={style.button_container}>
      <div className={style.pageNavigator}>
        <Button
          variant="contained"
          disabled={!getPermissions().read.ocr_result || !hasPreviousPage}
          onClick={() => {
            history.push(
              "/ocr/edit?ocr_binder_id=" +
                props.ocr_result_binder_id +
                "&page=" +
                (props.page - 1).toString()
            );
          }}
        >
          前のページ
        </Button>
        <Button
          variant="contained"
          disabled={!getPermissions().read.ocr_result || !hasNextPage}
          onClick={() => {
            history.push(
              "/ocr/edit?ocr_binder_id=" +
                props.ocr_result_binder_id +
                "&page=" +
                (props.page + 1).toString()
            );
          }}
        >
          次のページ
        </Button>
        <span>
          ({page + 1}/{pageCount})ページ
        </span>
      </div>
      <Button
        disabled={
          !getPermissions().edit.ocr_result || props.overview.name === ""
        }
        className={style.saveButton}
        variant="contained"
        color="primary"
        onClick={props.funcRegister}
      >
        <img
          alt="save"
          src={saveIcon}
          className={style.saveImage}
          style={!getPermissions().edit.ocr_result ? { opacity: 0.5 } : {}}
        />
        保存する
      </Button>
    </Container>
  );
}

function OcrResultImage(props) {
  const [image] = useImage(props.imageData);
  React.useEffect(() => {
    if (image) {
      let _scaleX = props.parentWidth / image.naturalWidth;
      let _scaleY = props.parentHeight / image.naturalHeight;
      let _scale = _scaleX < _scaleY ? _scaleX : _scaleY;
      props.funcSetImageScale(_scale);
    }
    // eslint-disable-next-line
  }, [image]);
  return (
    <KonvaImage
      image={image}
      scale={{ x: props.imageScale, y: props.imageScale }}
    />
  );
}

function ItemCard(props) {
  const style = useStyles();
  const card_style = props.isSelected ? style.selected_card : style.card_root;

  const handleCardMouseDown = () => {
    props.funcSelectItem(props.commonId);
  };
  const handleInputTextChange = (e: any) => {
    console.log("change");
    props.funcAlterTextInput(
      Ids.getCommonIdInputId(e.target.id),
      e.target.value
    );
  };
  const titleColor = props.item.name ? "primary" : "error";
  return (
    <Card
      className={card_style}
      onMouseDown={handleCardMouseDown}
      onFocus={handleCardMouseDown}
      id={Ids.itemCardId(props.commonId)}
    >
      <CardHeader
        className={style.cardHeader}
        title={props.no + "." + (props.item.name || "項目名未設定")}
        titleTypographyProps={{ variant: "subtitle1", color: titleColor }}
        disablespacing="true"
      ></CardHeader>
      <CardContent className={style.cardContent}>
        <Container className={style.input_base_root}>
          <TextField
            className={style.input}
            placeholder="OCR Result"
            id={Ids.inputId(props.commonId)}
            onChange={handleInputTextChange}
            defaultValue={props.item.result.ocr_result}
            value={props.item.result.ocr_result}
            multiline={true}
            disabled={!getPermissions().edit.ocr_result}
            inputProps={{
              maxLength: TextLimits.MAX_OCR_TEXT_LENGTH,
            }}
            onFocus={(event) => {
              event.target.select();
            }}
          />
          {props.item.result.status.code !== 0 ? (
            <Chip
              className={style.result_chip}
              label={
                <Typography variant="body2" className={style.typography}>
                  {props.item.result.status.err_reason}
                </Typography>
              }
              variant="outlined"
              size="small"
              color="secondary"
            />
          ) : null}
        </Container>
      </CardContent>
    </Card>
  );
}

function ImagePaper(props) {
  const style = useStyles();
  const [stageScale, setStageScale] = React.useState(1);
  const targetRef: React.MutableRefObject<HTMLInputElement> = React.useRef();
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

  // This sets the dimensions on the first render
  React.useLayoutEffect(() => {
    const imageForCheck = new Image();
    imageForCheck.onload = () => {
      console.log(
        "imageSize",
        imageForCheck.height + "x" + imageForCheck.width
      );
      if (targetRef) {
        setDimensions({
          width: paperImageWidth,
          height:
            (imageForCheck.height * paperImageWidth) / imageForCheck.width,
        });
      }
    };
    imageForCheck.src = props.imageData;
  }, [props.imageData]);

  const handleStageMouseDown = (e: any) => {
    if (e.target === e.target.getStage()) {
      props.funcSelectItem();
      return;
    }
    const clickedOnTransformer =
      e.target.getParent().className === "Transformer";
    if (clickedOnTransformer) {
      return;
    }

    const _id = e.target.attrs.id;
    if (_id) {
      props.funcSelectItem(Ids.getCommonIdFromRectId(_id));
    } else {
      props.funcSelectItem();
    }
  };

  const handleAlterRect = (e: any) => {
    props.funcAlterRect(e);
  };

  // ズームあとで入れなおすかも
  // eslint-disable-next-line
  const handleMouseWheel = (e: any) => {
    let newScale = stageScale - e.evt.deltaY * 0.01;
    if (newScale < 1) {
      newScale = 1;
    } else if (newScale > 2) {
      newScale = 2;
    }
    setStageScale(newScale);
  };

  //const handleDrag = (e: any) => {};

  return (
    <Container ref={targetRef} className={style.image_paper}>
      <Paper elevation={24}>
        <Stage
          width={dimensions.width}
          height={dimensions.height}
          onMouseDown={handleStageMouseDown}
          scale={{ x: stageScale, y: stageScale }}
          // onWheel={handleMouseWheel}
          draggable={false}
        >
          <Layer>
            <OcrResultImage
              parentWidth={dimensions.width}
              parentHeight={dimensions.height}
              funcSetImageScale={props.funcSetImageScale}
              imageData={props.imageData}
              imageScale={props.imageScale}
            />
          </Layer>
          <Layer>
            {props.imageScale
              ? Object.keys(props.items).map((id, i) => {
                  return (
                    <RectHandler
                      key={id}
                      commonId={id}
                      rectangle={props.items[id].rectangle}
                      isSelected={id === props.selectedItemId}
                      funcAlterRect={handleAlterRect}
                      imageScale={props.imageScale}
                    />
                  );
                })
              : null}
          </Layer>
        </Stage>
      </Paper>
    </Container>
  );
}

function ListContainer(props) {
  const style = useStyles();

  return (
    <Paper
      className={style.card_container}
      style={{
        top: props.buttonsHeight + 30,
      }}
      elevation={24}
    >
      {Object.entries(props.items)
        .sort(([, a], [, b]) => a["no"] - b["no"])
        .map(([id, item], i) => {
          return (
            <ItemCard
              key={id}
              id={Ids.itemCardId(id)}
              commonId={id}
              no={i + 1}
              item={props.items[id]}
              isSelected={id === props.selectedItemId}
              funcSelectItem={props.funcSelectItem}
              funcDeleteItem={props.funcDeleteItem}
              funcAlterTextInput={props.funcAlterTextInput}
              funcAlterModel={props.funcAlterModel}
            />
          );
        })}
    </Paper>
  );
}

////////////////////////////////////////////////////////////////////////////////////////////////
// Work
////////////////////////////////////////////////////////////////////////////////////////////////
function RectHandler(props) {
  //const style = useStyles();
  //const [isSelected, setisSelected] = React.useState(true);
  const shapeRef = React.useRef();
  const trRef: any = React.useRef();
  React.useEffect(() => {
    if (props.isSelected && trRef && trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [props.isSelected]);

  const notFocused = "#76FFD0";
  const inFocused = "#FF0000";

  const onClick = (e: any) => {
    const rectID: string = e.target.attrs.id;
    console.log(rectID);

    //setisSelected(true);

    const inputArea = document.getElementById(
      Ids.inputId(Ids.getCommonIdFromRectId(rectID))
    );
    if (inputArea !== null) {
      inputArea.scrollIntoView({ block: "nearest" });
    }
  };

  return (
    <React.Fragment>
      <Rect
        id={Ids.rectId(props.commonId)}
        ref={shapeRef}
        //name={String(Ids.rectIdofNumber(this.props.selfNum))}
        //x={this.state.rectStartingPoint.x + this.props.imgStartingPoint.x}
        //y={this.state.rectStartingPoint.y + this.props.imgStartingPoint.y}
        x={props.rectangle.x ?? props.rectangle.initialX * props.imageScale}
        y={props.rectangle.y ?? props.rectangle.initialY * props.imageScale}
        scaleX={props.rectangle.scaleX}
        scaleY={props.rectangle.scaleY}
        width={props.rectangle.width * props.imageScale}
        height={props.rectangle.height * props.imageScale}
        fill={props.isSelected ? inFocused : notFocused}
        opacity={0.3}
        //visible={this.props.visible}
        draggable={false}
        onClick={onClick}
        //onTransform={handleTransform}
        //onTransformStart={handleTransform}
        //onTransformEnd={handleAlterRect}
        //onDragEnd={handleAlterRect}
        strokeScaleEnabled={false}
        //onDblClick={this.onDblClick}
        //onContextMenu={this.contextMenu}
        //onMouseMove={this.onMouseMove}
        //onMouseEnter={this.onMouseEnter}
        //onMouseLeave={this.onMouseLeave}
      ></Rect>
    </React.Fragment>
  );
}

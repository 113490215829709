import * as React from "react";

import {
  AdjustImageAPI,
  SimilarImageSearchAPI,
  TemplateAPI,
} from "../../Common/api";
import { InputAdornment, LinearProgress } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ErrorMessages } from "../../Common/error";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import { TextLimits } from "../../Common/limits";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { compareUpdatedTime } from "../../Common/sortUtils";
import garbage from "../../Images/garbage.png";
import { getPermissions } from "../../Common/permissions";
// icons
import glass from "../../Images/glass.png";
import newtemplate from "../../Images/newtemplate.png";
import reload from "../../Images/reload.png";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body_div: {
      outline: "none",
      margin: "auto",
    },
    body_header: {
      flexGrow: 1,
      zIndex: 1,
      backgroundColor: "rgba(0,0,0,0)",
    },
    router_link: {
      margin: "10px",
    },
    toolbar: {
      padding: 0,
      minHeight: 48,
      marginTop: 10,
      marginBottom: 10,
    },
    title: {
      flexGrow: 1,
      color: "#002081",
    },
    searchBoxBar: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "40px",
    },
    searchBox: {
      width: "478px",
      marginRight: "150px",
    },
    glassImage: {
      width: "24px",
      height: "23px",
    },
    newTemplateImage: {
      width: "34px",
      height: "31px",
      marginRight: "15px",
    },
    reloadDeleteButton: {
      paddingLeft: "10px",
      paddingRight: "10px",
      minWidth: 0,
    },
    reloadImage: {
      width: "23px",
      height: "23px",
    },
    garbageImage: {
      width: "20",
      height: "23px",
    },
    table_body: {
      textAlign: "center",
      borderWidth: 0,
      borderRightWidth: "1px",
      borderBottomWidth: "1px",
      borderColor: "#D0D0D0",
      borderStyle: "solid",
      paddingTop: "20px",
      paddingBottom: "20px",
    },
    table: {
      borderWidth: 0,
      borderLeftWidth: "1px",
      borderTopWidth: "1px",
      borderColor: "#D0D0D0",
      borderStyle: "solid",
      marginBottom: "30px",
    },
    tableHeader: {
      backgroundColor: "#C4EDFF",
      paddingTop: "20px",
      paddingBottom: "20px",
      borderWidth: 0,
      borderRightWidth: "1px",
      borderBottomWidth: "1px",
      borderColor: "#D0D0D0",
      borderStyle: "solid",
      textAlign: "center",
    },
    textContainer: {
      display: "-webkit-box",
      whiteSpace: "normal",
      wordWrap: "break-word",
      WebkitLineClamp: 5,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    },
    checkbox: {
      padding: 0,
      borderRadius: 0,
      backgroundColor: "white",
    },
    ocrButton: {
      backgroundColor: "#43B9F7",
      "&:hover": {
        backgroundColor: "#0093E3",
      },
    },
    thumbNail: {
      borderWidth: 1,
      borderColor: "#d0d0d0",
      borderStyle: "solid",
      position: "relative",
      zIndex: 0,
      "&:hover": {
        transform: "scale(3)",
        transition: "0.2s",
        position: "relative",
        zIndex: 100,
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

export default function TemplateList(props) {
  const style = useStyles();

  const [checkedIDs, setCheckedIDs] = React.useState([]);
  const [checkBoxStatus, setCheckBoxStatus] = React.useState({});
  const [allChecked, setAllChecked] = React.useState(false);

  const [tempDict, setTempDict] = React.useState();

  const [backDropping, setBackDropping] = React.useState(false);

  const [reloadListTrigger, reloadList] = React.useState(false);

  const handleCheckboxChange = (event, id) => {
    if (id === "checkAll") {
      setAllChecked(event.target.checked);
      for (const key of Object.keys(checkBoxStatus)) {
        checkBoxStatus[key] = event.target.checked;
        setCheckBoxStatus(checkBoxStatus);
      }
    } else {
      checkBoxStatus[id] = event.target.checked;
      setCheckBoxStatus(checkBoxStatus);
      const allChecked = Object.values(checkBoxStatus).every((elm) => elm);
      setAllChecked(allChecked);
    }
    const _checkedIDs = [];
    for (const key of Object.keys(checkBoxStatus)) {
      if (checkBoxStatus[key]) _checkedIDs.push(key);
    }
    setCheckedIDs(_checkedIDs);
  };
  const [searchText, setSearchText] = React.useState("");
  const handleSearchBoxChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div className={style.body_div} tabIndex={1}>
      <Backdrop className={style.backdrop} open={backDropping}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <BodyHeader />
      <SearchBox
        handleSearchBoxChange={handleSearchBoxChange}
        checkedIDs={checkedIDs}
        tempDict={tempDict}
        reloadList={reloadList}
        setBackDropping={setBackDropping}
      />
      <TemplateListTable
        searchText={searchText}
        allChecked={allChecked}
        setCheckedIDs={setCheckedIDs}
        setAllChecked={setAllChecked}
        checkBoxStatus={checkBoxStatus}
        setCheckBoxStatus={setCheckBoxStatus}
        handleChange={handleCheckboxChange}
        setTempDict={setTempDict}
        reloadListTrigger={reloadListTrigger}
        setBackDropping={setBackDropping}
        isFromOcrUpload={props.isFromOcrUpload}
        requestOCR={props.requestOCR}
        standardized={props.standardized}
      />
    </div>
  );
}

function BodyHeader() {
  const style = useStyles();
  return (
    <AppBar
      className={style.body_header}
      position="static"
      color="default"
      elevation={0}
    >
      <Toolbar className={style.toolbar}>
        <Typography variant="h6" className={style.title}>
          読取箇所テンプレート一覧
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

function SearchBox(props) {
  const style = useStyles();
  const history = useHistory();
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  return (
    <div className={style.searchBoxBar}>
      <TextField
        id="outlined-basic"
        label="検索する"
        size="small"
        fullWidth
        autoComplete="off"
        onChange={props.handleSearchBoxChange}
        className={style.searchBox}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <img src={glass} className={style.glassImage} alt="search" />
            </InputAdornment>
          ),
        }}
        inputProps={{
          maxLength: TextLimits.MAX_TEXT_LENGTH,
        }}
      />
      <Button
        variant="contained"
        disabled={!getPermissions().create.template}
        onClick={() => history.push("/template/upload")}
      >
        <img
          src={newtemplate}
          className={style.newTemplateImage}
          alt="newTemplate"
          style={!getPermissions().create.template ? { opacity: 0.2 } : {}}
        />
        新しい読取箇所テンプレートを作成する
      </Button>
      <Button
        className={style.reloadDeleteButton}
        title="再読込"
        variant="contained"
        disabled={!getPermissions().list.template}
        onClick={() => {
          props.setBackDropping(true);
          props.reloadList((current_value: boolean) => !current_value);
        }}
      >
        <img
          src={reload}
          className={style.reloadImage}
          alt="reload"
          style={!getPermissions().list.template ? { opacity: 0.2 } : {}}
        />
      </Button>
      <Button
        className={style.reloadDeleteButton}
        title="削除する"
        variant="contained"
        disabled={!getPermissions().delete.template}
        onClick={() => {
          if (props.checkedIDs.length === 0) {
          } else if (window.confirm("選択したテンプレートを削除しますか？")) {
            props.setBackDropping(true);
            deleteTemplates(
              props.checkedIDs,
              props.tempDict,
              props.reloadList,
              getAccessTokenSilently,
              getIdTokenClaims
            );
          }
        }}
      >
        <img
          src={garbage}
          className={style.garbageImage}
          alt="delete"
          style={!getPermissions().delete.template ? { opacity: 0.2 } : {}}
        />
      </Button>
    </div>
  );
}

interface TemplateData {
  template_id: string;
  template_name: string;
  template_desc: string;
  template_updated_at: string;
  template_created_at: string;
}

function TemplateListTable(props) {
  const style = useStyles();
  const history = useHistory();
  const [rawRows, setRawRows] = React.useState(null);
  const [rows, setRows] = React.useState(null);
  const [thumbnails, setThumbnails] = React.useState(null);
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  const checkboxCol = { maxWidth: "48px", minWidth: "48px", padding: 0 };
  const nameCol = { maxWidth: "215px", minWidth: "215px" };
  const descriptionCol = { maxWidth: "215px", minWidth: "215px" };
  const updateDateCol = { maxWidth: "110px", minWidth: "110px" };
  const createDateCol = { maxWidth: "110px", minWidth: "110px" };
  const idCol = { maxWidth: "95px", minWidth: "95px" };
  const thumbnailCol = { maxWidth: "85px", minWidth: "85px", padding: "5px" };
  const editButtonCol = { maxWidth: "110px", minWidth: "110px" };
  const ocrButtonCol = { maxWidth: "110px", minWidth: "110px" };

  const setCheckBoxStatus = props.setCheckBoxStatus;
  const setTempDict = props.setTempDict;
  const setBackDropping = props.setBackDropping;
  const reloadListTrigger = props.reloadListTrigger;
  const searchText = props.searchText;
  const setCheckedIDs = props.setCheckedIDs;
  const setAllChecked = props.setAllChecked;

  React.useEffect(() => {
    const templateAPI = new TemplateAPI("get_list_of_template");
    templateAPI.setParam({ param: { gets_thumbnail: true } });

    getAccessTokenSilently()
      .then(() => getIdTokenClaims())
      .then((idToken) => {
        templateAPI
          .execPostRequest(idToken.__raw)
          .then((response) => response.json())
          .then(
            (res) => {
              console.log("list", res);
              let sortedRows = res.template_list.sort(
                compareUpdatedTime
              ) as Array<any>;
              setThumbnails(res.binary_data);
              setRawRows(sortedRows);
              setRows(sortedRows);
              const initialCheckBoxStatus = {};
              for (const row of sortedRows) {
                initialCheckBoxStatus[row.template_id] = false;
              }
              setCheckBoxStatus(initialCheckBoxStatus);
              setCheckedIDs([]);
              setAllChecked(false);
              let tempDict = {};
              for (const row of res.template_list) {
                tempDict[row.template_id] = row;
              }
              setTempDict(tempDict);
              console.log("then");
              console.log(res.template_list);
              setBackDropping(false);
            },
            (error) => {
              console.log(error);
            }
          );
      });
  }, [
    reloadListTrigger,
    getAccessTokenSilently,
    getIdTokenClaims,
    setCheckBoxStatus,
    setTempDict,
    setBackDropping,
    setAllChecked,
    setCheckedIDs,
  ]);

  React.useEffect(() => {
    if (rawRows && searchText !== "") {
      const searchWords = searchText.split(/\s+/);
      const hitRowIDs = [];

      // name, description, template_id
      // のいずれかにすべてのwordが含まれるかチェック
      for (const row of rawRows) {
        const hitWords = [];
        for (const word of searchWords) {
          const lowerWord = word.toLowerCase();
          if (
            row.name.toLowerCase().includes(lowerWord) ||
            (row.description &&
              row.description.toLowerCase().includes(lowerWord)) ||
            row.template_id.toLowerCase().includes(lowerWord)
          ) {
            hitWords.push(word);
          } else {
            break;
          }
        }
        if (searchWords.every((word) => hitWords.includes(word))) {
          hitRowIDs.push(row.template_id);
        }
      }
      const sortedRows = rawRows.filter((row) =>
        hitRowIDs.includes(row.template_id)
      );
      setRows(sortedRows);
      const initialCheckBoxStatus = {};
      for (const row of sortedRows) {
        initialCheckBoxStatus[row.template_id] = false;
      }
      setCheckBoxStatus(initialCheckBoxStatus);
      setCheckedIDs([]);
      setAllChecked(false);
    } else {
      if (rawRows) {
        setRows(rawRows);
        const initialCheckBoxStatus = {};
        for (const row of rawRows) {
          initialCheckBoxStatus[row.template_id] = false;
        }
        setCheckBoxStatus(initialCheckBoxStatus);
        setCheckedIDs([]);
        setAllChecked(false);
      }
    }
  }, [
    reloadListTrigger,
    rawRows,
    searchText,
    setCheckBoxStatus,
    setAllChecked,
    setCheckedIDs,
  ]);

  return rows === null ? (
    <React.Fragment>
      <LinearProgress />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Table
        className={style.table}
        size="small"
        aria-label="a dense table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell className={style.tableHeader} style={checkboxCol}>
              <Checkbox
                className={style.checkbox}
                size="small"
                disableRipple
                checked={props.allChecked}
                onChange={(event) => {
                  props.handleChange(event, "checkAll");
                }}
              />
            </TableCell>
            <TableCell className={style.tableHeader}>テンプレート名</TableCell>
            <TableCell className={style.tableHeader}>説明文</TableCell>
            <TableCell className={style.tableHeader}>更新日時</TableCell>
            <TableCell className={style.tableHeader}>作成日時</TableCell>
            <TableCell className={style.tableHeader}>ID</TableCell>
            <TableCell className={style.tableHeader}>画像</TableCell>
            <TableCell className={style.tableHeader}>編集する</TableCell>
            <TableCell className={style.tableHeader}>OCRする</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={row.template_id}
              id={row.template_id}
              style={
                index % 2
                  ? { backgroundColor: "#f2f2f2" }
                  : { backgroundColor: "white" }
              }
            >
              <TableCell className={style.table_body} style={checkboxCol}>
                <Checkbox
                  className={style.checkbox}
                  size="small"
                  disableRipple
                  checked={props.checkBoxStatus[row.template_id] === true}
                  onChange={(event) => {
                    props.handleChange(event, row.template_id);
                  }}
                />
              </TableCell>
              <TableCell className={style.table_body} style={nameCol}>
                {getPermissions().read.template ? (
                  <Link to={"/template/edit?template_id=" + row.template_id}>
                    <div className={style.textContainer} title={row.name}>
                      {row.name}
                    </div>
                  </Link>
                ) : (
                  <div className={style.textContainer} title={row.name}>
                    {row.name}
                  </div>
                )}
              </TableCell>
              <TableCell className={style.table_body} style={descriptionCol}>
                <div className={style.textContainer} title={row.description}>
                  {row.description}
                </div>
              </TableCell>
              <TableCell className={style.table_body} style={updateDateCol}>
                <div className={style.textContainer}>
                  {new Date(row.updated_at).toLocaleString()}
                </div>
              </TableCell>
              <TableCell className={style.table_body} style={createDateCol}>
                <div className={style.textContainer}>
                  {new Date(row.created_at).toLocaleString()}
                </div>
              </TableCell>
              <TableCell className={style.table_body} style={idCol}>
                <div className={style.textContainer}>{row.template_id}</div>
              </TableCell>
              <TableCell className={style.table_body} style={thumbnailCol}>
                <img
                  src={"data:image/png;base64," + thumbnails[row.template_id]}
                  width="100%"
                  className={style.thumbNail}
                  alt="thumbnail"
                />
              </TableCell>
              <TableCell className={style.table_body} style={editButtonCol}>
                <Button
                  variant="contained"
                  disabled={!getPermissions().read.template}
                  onClick={() => {
                    history.push(
                      "/template/edit?template_id=" + row.template_id
                    );
                  }}
                >
                  編集
                </Button>
              </TableCell>
              <TableCell className={style.table_body} style={ocrButtonCol}>
                <Button
                  className={style.ocrButton}
                  color="primary"
                  variant="contained"
                  disabled={!getPermissions().execute.ocr}
                  onClick={() => {
                    if (props.isFromOcrUpload) {
                      props.setBackDropping(true);
                      props.requestOCR(
                        row.template_id,
                        props.standardized,
                        getAccessTokenSilently,
                        getIdTokenClaims
                      );
                    } else {
                      history.push(
                        "/ocr/uploadfromtemplate?template_id=" + row.template_id
                      );
                    }
                  }}
                >
                  OCR
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

const deleteTemplates = (
  template_IDs,
  tempDict,
  reloadList,
  getAccessTokenSilently,
  getIdTokenClaims
) => {
  getAccessTokenSilently()
    .then(() => getIdTokenClaims())
    .then((idToken) => {
      let promises = [];
      template_IDs.map((template_id) => {
        const del_feature_value_id = tempDict[template_id].feature_value_id;
        const adjustImageAPI = new AdjustImageAPI("delete");
        const templateAPI = new TemplateAPI("delete_template");
        const similarImageDeleteAPI = new SimilarImageSearchAPI("delete");

        const adjustImageAPI_param = {
          param: { template_id: del_feature_value_id },
          binary_data: {},
        };

        const templateAPI_param = {
          param: {
            template_id: template_id,
          },
          binary_data: {},
        };

        const similarImageDeleteParam = {
          param: { image_keys: [template_id] },
          binary_data: {},
        };

        adjustImageAPI.setParam(adjustImageAPI_param);
        templateAPI.setParam(templateAPI_param);
        similarImageDeleteAPI.setParam(similarImageDeleteParam);

        promises.push(
          adjustImageAPI
            .execPostRequest(idToken.__raw)
            .then((response) => response.json())
        );
        promises.push(
          templateAPI
            .execPostRequest(idToken.__raw)
            .then((response) => response.json())
        );
        promises.push(
          similarImageDeleteAPI
            .execPostRequest(idToken.__raw)
            .then((response) => response.json())
        );
        return [];
      });

      Promise.all(promises).then(
        (results) => {
          console.log("delete");
          console.log(results);
          reloadList((current_value: boolean) => !current_value);
        },
        (error) => {
          console.log("ERROR", error);
          alert(ErrorMessages.pleaseTryAgain);
          reloadList((current_value: boolean) => !current_value);
        }
      );
    });
};
